import { mergeStyles } from '@fluentui/react/';
import React from 'react';
import { wrapper } from '../../auth/styles/activityIndicatorContainer.styles';
import ListSuccessMessage from '../../common/layout/ListSuccessMessage';
import ReportsPanel from '../panel/ReportsPanel';
import { padding } from '../styles/style';
import ReportsList from './ReportsList';

export interface ReportProps { }

const Reports: React.FC<ReportProps> = () => {
    return (
        <div className={wrapper}>
            <div className={padding}>
                <ListSuccessMessage />
            </div>
            <ReportsList />
            <ReportsPanel />
        </div>
    );
}

export default Reports;