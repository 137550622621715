import * as React from 'react';
import { Depths } from "@fluentui/react";
import {
    Stack,
    IStackTokens,
    Text,
    ITextStyles,
    FontIcon,
    mergeStyles,
    getTheme,
    mergeStyleSets,
} from '@fluentui/react';
import { FontSizes } from '@fluentui/react';
import { NavLink } from 'react-router-dom';
import { NavigationPath } from '../config/routing/NavigationPath';
import {myTheme} from "../client/styles/layout/theme";
// import StatisticTiles from '../features/statistics/components/StatisticTiles';
// import DashboardLineCharts from '../features/statistics/components/DashboardLineCharts';

export class Home extends React.Component<{}, {}> {
    public render(): JSX.Element {
        const descriptionTextStyles: ITextStyles = {
            root: {
                color: '#323130',
                fontSize: FontSizes.size12,
                textAlign: 'center'
            },
        };
        const theme = getTheme();
        const iconClass = mergeStyles({
            fontSize: 32,
            margin: '20px auto 14px auto',
        });
        const space = mergeStyles({
            marginLeft: 44
        });
        const link = mergeStyles({
            textDecoration: 'none'
        });
        const classNames = mergeStyleSets({
            iconS: [{ color: myTheme.palette.themeDark }, iconClass],
        });
        const tileS = mergeStyles({
            cursor: 'pointer',
            backgroundColor: '#fff',
            width: '200px',
            height: '100px',
            border: "1px solid rgba(204,204,204,.8)",
            boxShadow: Depths.depth4,
            selectors: {
                ":hover": {
                    border: "1px solid rgba(204,204,204,.8)",
                    boxShadow: Depths.depth8,
                },
            },
        });        
        const sectionStackTokens: IStackTokens = { childrenGap: 40 };

        return (
            <Stack className={space} tokens={sectionStackTokens}>
                <Stack horizontal tokens={sectionStackTokens}>
                    <NavLink to={NavigationPath.INACTIVE_CLIENTS} className={link}>
                        <Stack className={tileS}>
                            <FontIcon iconName="AddGroup" className={classNames.iconS} />
                            <Text variant="small" styles={descriptionTextStyles}>
                                Użytkownicy do weryfikacji
                            </Text>
                        </Stack>
                    </NavLink>
                    <NavLink to={NavigationPath.REPORTS_WAITING} className={link}>
                        <Stack className={tileS}>
                            <FontIcon iconName="FabricNewFolder" className={classNames.iconS} />
                            <Text variant="small" styles={descriptionTextStyles}>
                                Raporty do weryfikacji
                            </Text>
                        </Stack>
                    </NavLink>
                    <NavLink to={NavigationPath.YEARS_MANAGEMENT} className={link}>
                        <Stack className={tileS}>
                            <FontIcon iconName="DateTime" className={classNames.iconS} />
                            <Text variant="small" styles={descriptionTextStyles}>
                                Zarządzanie latami
                            </Text>
                        </Stack>
                    </NavLink>
                </Stack>

                {/*<StatisticTiles />*/}

                {/*<DashboardLineCharts />*/}
            </Stack>
        );
    }
}
export default Home