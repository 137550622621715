import React from "react";
import { mergeStyles } from "@fluentui/react/";

import Price from "./edditOrder/Price";

import { Text, ITextStyles } from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";
import { FontWeights } from "@fluentui/react/";
import PriceInput from "./PriceInput";
const wrapper = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
});

const mainWrapper = mergeStyles({
  paddingTop: 20,
  paddingBottom: 15
})
const divider = mergeStyles({
  width: 250,
});

export interface PricesProps {
  value: number;
  setPrice? : any;
  disabled? : boolean
}
const headerS: ITextStyles = {
  root: {
    display: "block",
    marginBottom: 15,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};

const Prices: React.FC<PricesProps> = ({ value, setPrice, disabled }) => {
  return (
    <div className={mainWrapper} >
    <Text styles={headerS}>Szacowany koszt zamówienia</Text>
    <div className={wrapper}>
      
      <div className={divider}>
        {/* <Price name="netto" label="Cena netto" disabled={true} value={value.toString()}/> */}
        {
          disabled ? 
          <Price
          name="netPrice"
          label="Wartość netto"
          value={value ?  Number(value) : 0.0 }
          disabled={true}
        />
        :
        <PriceInput 
          value1={value ? value.toString() : ""}
          name="netPrice"
          label="Wartość netto"
          disabled={false}
          setPrice={setPrice}
          />
        }

      </div>
      <div className={divider}>
        <Price
          name="grossPrice"
          label="Wartość brutto (VAT: 8%)"
          value={((value ? Number(value) : 0.0) * 1.08)}
          disabled={true}
        />
      </div>
    </div>
    </div>
  );
};

export default Prices;
