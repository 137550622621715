import api from "../../../config/axios/configAxios";
import {IDriver, IYear} from "../models/index";

export const getYears = async () =>
  api.get(`/api/services/app/Year/GetAll?IsArchived=false`);

export const getYearsClients = async (id: number) =>
    api.get(`/api/services/app/SpecialYear/GetUser?UserId=${id}`);

export const putYearsClients = async (data: IYear) =>
    api.post(`/api/services/app/SpecialYear/Set`, data);

export const addYear = async (data: IDriver) =>
  api.post(`/api/services/app/Year/Create`, data);

export const editDriver = async (data: IDriver, driverId: string) =>
  api.put(`/api/Admin/Users/drivers/${driverId}`, data);

export const markAsActive = async (id: number) =>
    api.put(`/api/services/app/Year/Update`, {id: id, isActive: true})

export const markAsInActive = async (id: number) =>
    api.put(`/api/services/app/Year/Update`, {id: id, isActive: false})

export const deleteYears = async (id: number) =>
    api.delete(`/api/services/app/Year/Delete?Id=${id}`)
