import { IQuantity, Quantity } from "../../../../common/models/quantity.model";

export const generateAccepted = (quantity: IQuantity, actions: any) => {
  const realizationBtn = {
    key: "check out",
    text: "Realizuj",
    iconProps: { iconName: "BusSolid" },
    onClick: actions.pushToRealization,
  };

  switch (quantity) {
    case Quantity.NONE:
      return [
        {
          key: "download",
          text: "Pobierz raport roczny",
          iconProps: { iconName: "ReportDocument" },
          onClick: actions.openYearlyPanelToSee,
        },
      ]
    case Quantity.ONE:
      return [
        {
          key: "add",
          text: "Zobacz",
          iconProps: { iconName: "textDocument" },
          onClick: actions.openPanelToSee,
        },
        {
          key: "download",
          text: "Pobierz",
          iconProps: { iconName: "ReportDocument" },
          onClick: actions.download,
        },
      ];

    case Quantity.MANY:
      return [
        {
          key: "download",
          text: "Pobierz",
          iconProps: { iconName: "ReportDocument" },
          onClick: actions.downloadMany,
        },
      ];
  }
};
