export const generateItems = (data: any) => {
    const items: any[] = [];

    data.forEach((data: any) => {
        items.push({
            id: data.id,
            key: data.id,
            storeName: data.storeName,
            //receptionPlaceName: data.receptionPlaceName,
            containersAmount: data.containersAmount,
            //storeType: data.storeType,
        });
    });

    // items.sort((a, b) => {
    //     return new Date(b.documentDate).getTime() - new Date(a.documentDate).getTime();
    // });

    return [...items];
};