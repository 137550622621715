import { ITextStyles } from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";
import { FontWeights, mergeStyles } from "@fluentui/react/";

export const headerS: ITextStyles = {
  root: {
    marginTop: 5,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};

export const wrapper = mergeStyles({
  marginTop: 20
})