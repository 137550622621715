import { Console } from "console";
import { IQuantity } from "../../../../../common/models/quantity.model";
import { Quantity } from "../../../../../common/models/quantity.model";
import { OrderTypes } from "../../../../../orders/models/OrderTypes.enum";

export const generatePickUpPointsCommands = (
  quantity: IQuantity,
  actions: any,
  orderTypes: any,
  isActive: boolean
) => {

  const itemsArr = orderTypes.map(({ key, text }: any) => {
    let icon = { iconProps: {} };

    if (text === OrderTypes.VEHICLE) {
      icon = { iconProps: { iconName: "Car" } };
    } else if (text === OrderTypes.CONTAINER) {
      icon = { iconProps: { iconName: "Inbox" } };
    } else if (text === OrderTypes.WEIGHT) {
      icon = { iconProps: { iconName: "Weights" } };
    }

    return {
      key: key,
      id: key,
      text: text,
      iconProps: icon.iconProps,
      onClick: actions.verifyPickuPoint,
    };
  })

  if (quantity === Quantity.NONE) {
    return [
      {
        key: "add",
        text: "Dodaj",
        iconProps: { iconName: "add" },
        name: "add",
        onClick: actions.toggle,
      },
    ];
  } else {
    if (isActive) {
      return [
        {
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          name: "add",
          onClick: actions.toggle,
        },
        
      ];
    } else {
      return [
        {
          key: "verify",
          text: "Zweryfikuj",
          iconProps: { iconName: "send" },
          name: "verify",
          subMenuProps: {
              items: [...itemsArr],
          },
      }
      ];
    }
  }
};
