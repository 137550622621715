import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setOrderDetails } from "../../../config/redux/client/clientActions";
import { getOrderDetails } from "../../../connectors/orders/connectors";
import { useEffectAfterMount } from "../../common/hooks/useEffectAfterMount";

export const useClientData = () => {
  const dispatch = useDispatch();
  const { orderPanelOpen, selectedItem } = useSelector(
    (state: RootStateOrAny) => state.client
  );

  const fetchOrderDetails = async () => {
    const res = await getOrderDetails(selectedItem.key);
    dispatch(setOrderDetails(res.data.result));
  };

  useEffectAfterMount(() => {
    if (orderPanelOpen && selectedItem && selectedItem.key) {
      fetchOrderDetails();
    }

    if (!orderPanelOpen) {
      dispatch(setOrderDetails(""));
    }
  }, [orderPanelOpen]);
};
