import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import {
  DetailsListLayoutMode,
  IColumn,
  Selection,
  SelectionMode,
} from "@fluentui/react//lib/DetailsList";
import { MarqueeSelection } from "@fluentui/react//lib/MarqueeSelection";
import { ShimmeredDetailsList, Stack } from "@fluentui/react/";
import { IRenderProps } from "../../orders/providers/Pagination.types";
import { toggleClientsPanel } from "../../../config/redux/clients/panel/clientPanelActions";
import {
  setActiveStoreDoc,
  setStoreDocs,
} from "../../../config/redux/storeDocs/storeDocsActions";
import {
  sort,
  toggleFiltration,
} from "../../../config/redux/filters/filterActions";
import { storeColumns } from "../columns/storeColumns";
import Pagination from "../providers/Pagination";
import {
  footer,
  listContainerS,
  listS,
} from "../../clients/components/clientsList.styles";
import {
  IDispatchProps,
  IProps,
  IStateProps,
  IStoreListState,
} from "../models/StoreListTypes";

class StoreList extends React.Component<IProps, IStoreListState> {
  private _selection: Selection;
  private _columns: IColumn[];
  private _generatedColumns: IColumn[];
  private _onItemInvoked: (item?: any, index?: number, e?: Event) => void;
  private _onColumnHeaderClick: (
    ev?: React.MouseEvent<HTMLElement>,
    column?: IColumn
  ) => void;
  protected _footerRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        // const items = this._getSelectedKeys();
        // console.log(items);
        // const ids = items.map((item: any) => {
        //     return {
        //         id: item.id,
        //         documentStatus: item.documentStatus,
        //         fileExists: item.fileExists,
        //         blobFileName: item.blobFileName
        //     };
        // });
        // const data = { selectedStoreDocs: [...ids] };
        //this.props.dispatch(setStoreDocs(data));
      },
    });

    this._footerRef = React.createRef();

    this._generatedColumns = storeColumns;

    this._onItemInvoked = (item: any) => {
      // console.log(item);
      // this.props.dispatch(setActiveStoreDoc({ activeStoreDocs: item }));
      // props.dispatch(toggleClientsPanel(true));
    };

    this._columns = [...this._generatedColumns];

    this._onColumnHeaderClick = (item: any, column: any) => {
      //if (column.key === "receptionPlacesOrderTypes") return;

      const newColumns: IColumn[] = this._columns.slice();

      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });

      props.dispatch(toggleFiltration({ filter: true }));
      props.dispatch(sort({ column: currColumn }));

      this.setState({
        _cols: newColumns,
      });
    };

    this.state = {
      items: [],
      loading: false,
      _cols: this._columns,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    //dispatch(setStoreDocs({selectedStoreDocs: []}));
  }

  componentDidUpdate() {
    if (this.props.reload) {
      this._selection.selectToKey("", true);
    }

    // if (!this.props.selectedStoreDocs[0]) {
    //     this._selection.selectToKey("", true);
    // }
  }

  public render(): JSX.Element {
    return (
      <>
        <Pagination
          footerRef={this._footerRef}
          render={({ data, loading }: IRenderProps) => (
            <>
              <Stack styles={listContainerS}>
                <MarqueeSelection isEnabled={false} selection={this._selection}>
                  <ShimmeredDetailsList
                    items={data}
                    columns={storeColumns}
                    setKey="items"
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={this._selection}
                    selectionMode={SelectionMode.none}
                    selectionPreservedOnEmptyClick={true}
                    onItemInvoked={this._onItemInvoked}
                    usePageCache={true}
                    styles={listS}
                    onColumnHeaderClick={this._onColumnHeaderClick}
                    enableShimmer={loading}
                  />
                </MarqueeSelection>
              </Stack>
              <div className={footer} ref={this._footerRef} />
            </>
          )}
        />
      </>
    );
  }

  private _getSelectedKeys = () => {
    const keys = this._selection.getSelection();
    return keys;
  };
}

const mapStateToProps = (state: RootStateOrAny) => state.store;

export default connect<IStateProps, IDispatchProps>(mapStateToProps)(StoreList);
