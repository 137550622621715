import React from 'react';


 
const Dashboard: React.FC = () => {
    return (
        <div></div>
      );
}
 
export default Dashboard;