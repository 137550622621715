import {
  setListMessage,
} from "./../../../config/redux/alerts/alertsActions";
import { FormikValues } from "formik";
import { useMutation, useQueryCache } from "react-query";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  setSelectedPrice,
  togglePricesPanel,
} from "../../../config/redux/pricing/pricingActions";
import { editPricing } from "../../../connectors/pricing/connectors";
import { IResetForm } from "../../common/models/resetForm.type";
import { parsePrices } from "../actions/pricesUtils";
import { PricingMessages } from "../models/pricingMessages.enum";
import { useMemo, useState } from "react";

export const useEditPrice = () => {
  const [loading, setIsLoading] = useState(false);
  const cache = useQueryCache();
  const dispatch = useDispatch();
  const { selectedPrice } = useSelector(
    (state: RootStateOrAny) => state.pricing
  );

  const [edit, { isSuccess: editSucces, error: editErr }] = useMutation(
    editPricing,
    {
      onSuccess: () => cache.invalidateQueries("prices"),
      onError: (err) => {
        throw new Error();
      },
    }
  );

  const initValues = useMemo(
    () => ({
      price: selectedPrice.priceValue,
    }),
    []
  );

  const editPrice = async (values: FormikValues, { resetForm }: IResetForm) => {
    setIsLoading(true);
    const prices = parsePrices(values);

    const data = selectedPrice.postalCodePrices
      ? {
          orderTypeItemId: selectedPrice.id,
          postalCodePrices: true,
          prices: prices,
          price: 1,
        }
      : {
          orderTypeItemId: selectedPrice.id,
          postalCodePrices: false,
          price: values.price,
        };

    try {
      await edit(data);
      setIsLoading(false);
      resetForm();
      whileSucces(PricingMessages.EDITED);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const cancelForm = () => {
    dispatch(setSelectedPrice(""));
    dispatch(togglePricesPanel(false));
  };

  const whileSucces = (message: string) => {
    dispatch(setSelectedPrice(""));
    dispatch(togglePricesPanel(false));
    dispatch(setListMessage("Operacja przebiegła pomyślnie"))
  };

  return {
    editPrice,
    cancelForm,
    loading,
    selectedPrice,
    initValues,
  };
};