import React, { useState } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react/";

import OrderTabs from "./OrderTabs";
import { RenderNavigation } from "./CustomNavigation";
import {
  addOrderComment,
  setActiveOrder,
  setSelectedOrders,
} from "../../../config/redux/orders/ordersActions";
import { OrderTabsNames } from "../models/OrderTabs.enum";
import NewOrderForm from "./forms/NewOrderForm"
import { clearAlerts } from "../../../config/redux/alerts/alertsActions";
import { useOrderData } from "../hooks/useOrderData";
import { createNewOrder, setActiveOrderTab, setOrderEditing, toggleOrderPanel } from "../../../config/redux/orders/ordersPanel/orderPanelActions";
import { useCompanyData } from "../hooks/useCompanyData";
import { useNewOrderFrom } from "../hooks/useNewOrderForm";

const panelS = {
  content: {
    padding: 20,
  },
  main: {
    height: "100%",
  },
};

const OrderPanel: React.FC = () => {
  const dispatch = useDispatch();
  // const {  selectedOrders } = useSelector(
  //   (state: RootStateOrAny) => state.orders
  // );
  const { addNewOrder } = useSelector(
    (state: RootStateOrAny) => state.orderPanel
  );
  
  const {orderPanelOpen} = useOrderData()

  const dissmisPanel = (ev: any) => {
    dispatch(setActiveOrder({}));
    dispatch(setSelectedOrders({ selectedOrders: [] }));
    dispatch(setActiveOrderTab(OrderTabsNames.DETAILS));
    dispatch(clearAlerts())
    dispatch(addOrderComment(false));
    dispatch(toggleOrderPanel(false));
    dispatch(setOrderEditing(false));
    dispatch(createNewOrder(false))
    localStorage.removeItem("receptionPlace");
    localStorage.removeItem("orderTypeItemId");
  };

  const { cancelForm, handleSubmit, isLoading } = useNewOrderFrom();
  const [company, setCompany] = useState({ key: "" });
  const [department, setDepartment] = useState({ key: "" });
  const [reception, setReception] = useState<any>();
  const [orderTypeItemId, setOrderTypeItemId] = useState<any>();

  const {
    departments,
    receptionPlaces,
    orderType,
    orderTypeItems,
  } = useCompanyData(company, department, reception);

  return (
    <Panel
      isOpen={orderPanelOpen}
      closeButtonAriaLabel="Close"
      type={3}
      onDismiss={dissmisPanel}
      onOuterClick={() => {}}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      onRenderNavigation={RenderNavigation}
      styles={panelS}
      isFooterAtBottom={true}
      //onRenderFooterContent={addNewOrder ? onRenderFooterContent : () => {return <></>}}
    >
      <>
        {addNewOrder ?
        <NewOrderForm 
          departments={departments}
          receptionPlaces={receptionPlaces}
          orderType={orderType}
          orderTypeItems={orderTypeItems}
          company={company}
          department={department}
          setCompany={setCompany}
          setDepartment={setDepartment}
          cancelForm={cancelForm}
          handleSubmit={handleSubmit}
          isLoading={isLoading}

          setReception={setReception}
          reception={reception}
          orderTypeItemId={orderTypeItemId}
          setOrderTypeItemId={setOrderTypeItemId}
        /> 
        : <OrderTabs />}
      </>
    </Panel>
  );
};

export default OrderPanel;