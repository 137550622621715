import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toggleReportsPanel } from "./../../../config/redux/reports/reportsAction";
import { Quantity } from "./../../../features/common/models/quantity.model";

export const useReportsCommandBar = () => {
    const dispatch = useDispatch();
    const { selectedItem } = useSelector((state: RootStateOrAny) => state.reports);

    const quantity = selectedItem[0] ? Quantity.ONE : Quantity.NONE;

    const openForm = () => {
        dispatch(toggleReportsPanel({ open: true }));
    };

    const generateItems = () => {
        switch (quantity) {
            case Quantity.ONE:
                return [
                    {
                        key: "prepare",
                        text: "Przygotuj",
                        iconProps: { iconName: "reportdocument" },
                        name: "prepare",
                        onClick: openForm,
                    },
                ];
            default:
                return [];
        }
    };

    const items = generateItems()

    return {
        items
    }
};