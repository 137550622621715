import React from "react";
import { mergeStyles } from "@fluentui/react/";

import OrderDetailTxt from "./OrderDetailTxt";

const divider = mergeStyles({
  width: 300,
});

const margin = mergeStyles({
  // marginRight: 80
  width: 150,
});

const basicInfoContainer = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
});

export interface OrderBasicInfoProps {
  activeOrder?: any;
}

const OrderBasicInfo: React.FC<OrderBasicInfoProps> = ({ activeOrder }) => {
  return (
    <div className={basicInfoContainer}>
      <div className={margin}>
        <OrderDetailTxt
          title="Numer Zamówienia"
          value={activeOrder? activeOrder.orderInfo ? activeOrder.orderInfo.number : ".": ""}
        />
      </div>
      <div className={divider}>
        <OrderDetailTxt
          title="Nazwa klienta"
          value={activeOrder? activeOrder.orderInfo ? activeOrder.orderInfo.company : ".": ""}
        />
      </div>
    </div>
  );
};

export default OrderBasicInfo;
