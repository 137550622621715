import {useDispatch} from "react-redux"

import { togglePricesPanel, setSelectedPrice } from "../../../config/redux/pricing/pricingActions"

export const usePricing = () => {
    const dispatch = useDispatch()

    const setPanelOpen = () => {
        dispatch(togglePricesPanel(true))
    }

    const setPriceOnSelection = (arr: Array<string>) => {
        dispatch(setSelectedPrice(arr[0]))
    }

    return {
        setPanelOpen,
        setPriceOnSelection
    }
}