import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  setSelectedListItem,
  reloadOrders as reloadClientOrders,
} from "../../../../config/redux/client/clientActions";
import { getOrders } from "../../../../connectors/orders/connectors";
import { useList } from "../../../../features/common/hooks/useList";
import { useOrdersList } from "../../../hooks/useOrderList";
import OrdersList from "./OrdersList";

const OrdersListContainer: React.FC = () => {
  //const data = [{ number: "2/10/2020", date: "2020-10-28", receptionPlace: "Szara 12a 09-564" , type: "Kontener", status:"Nowe" }];
  const [data, setData] = useState<any[]>([]);
  const { reloadOrders } = useSelector((state: RootStateOrAny) => state.client);

  const asd = async () => {
    var ords = await getOrders();
    var data: any[] = [];
    ords.data.result.items.map((ord: any) =>
      data.push({
        key: ord.id,
        id: ord.id,
        year: ord.year,
        yearString: ord.yearString,
        status: ord.status,
        creatorUserId: ord.creatorUserId,
        creatorNip: ord.creatorNip,
        bdo: ord.bdo,
        creationTime: ord.creationTime,
        fields: ord.fields,
        company: ord.company,
      })
    );
    setData(data);
  };

  const dispatch = useDispatch();
  const { selectedItem, setItemOnSelection, setPanelOpen } = useOrdersList();
  const { handleInvoke, selection, items } = useList(
    data,
    setPanelOpen,
    setItemOnSelection
  );

  useEffect(() => {
    if (!selectedItem) {
      selection.selectToKey("", true);
    }
  }, [selectedItem]);

  useEffect(() => {
    asd();
    return () => {
      dispatch(setSelectedListItem(""));
    };
  }, []);

  useEffect(() => {
    // debugger;
    if (reloadOrders === true) {
      asd();
      dispatch(reloadClientOrders(false));
    }
  }, [reloadOrders]);

  return (
    <OrdersList
      handleInvoke={handleInvoke}
      selection={selection}
      items={items}
    />
  );
};

export default OrdersListContainer;
