import {VehiclesActions} from "./vehiclesActions.enum"
import {IVehicle, IReload, IOpen, IAddVehicle} from "./vehiclesActions.types"

export const setSelectedVehicles = (data: Array<IVehicle>) => {
  return {
    type: VehiclesActions.SET_SELECTED_VEHICLES,
    payload: data,
  };
};

export const setActiveVehicle = (data: IVehicle) => {
  return {
    type: VehiclesActions.SET_ACTIVE_VEHICLE,
    payload: data,
  };
};

export const reloadVehicles = (data: IReload) => {
  return {
    type: VehiclesActions.RELOAD_VEHICLES,
    payload: data
  }
}

export const toggleVehiclePanel = (data: IOpen) => {
  return {
    type: VehiclesActions.TOGGLE_VEHICLE_PANEL,
    payload: data.open
  }
}

export const addNewVehicle = (data: IAddVehicle) => {
  return {
    type: VehiclesActions.ADD_VEHICLE,
    payload: data
  }
}
