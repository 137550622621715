import { FontWeights, IButtonStyles, mergeStyles, mergeStyleSets } from "@fluentui/react";
import {myTheme} from "./theme";

const theme = myTheme;
export const styles = mergeStyleSets({
  buttonArea: {
    cursor: "pointer"
  },
  callout: {
    maxWidth: 300,
  },
  header: {
    padding: "18px 24px 12px",
  },
  title: [
    theme.fonts.xLarge,
    {
      margin: 0,
      fontWeight: FontWeights.semibold,
    },
  ],
  subtitle: [
    theme.fonts.medium,
    {
      fontWeight: FontWeights.semilight,
    },
  ],
  inner: {
    height: "50%",
    padding: "0 24px 20px",
  },
  subtext: [
    theme.fonts.small,
    {
      margin: 0,
      fontWeight: FontWeights.semilight,
    },
  ],
  link: [
    theme.fonts.medium,
    {
      color: theme.palette.neutralPrimary,
    },
  ],
});


export const btnS: IButtonStyles = {
    root:{
        marginTop: 10
    }
}


export const btnsContainer = mergeStyles({
    display: 'flex',
    flexDirection: "column"
})

export const subtitleContainer = mergeStyles({
    marginBottom: 20
})

