import * as React from "react";
import {
  CommandBar,
} from "@fluentui/react/";
import {useOrderTypeCommandBar} from "../../hooks/settings/useOrderTypesCommandBar"
import { comandBarS} from "../../styles/commandBar.styles"


 
const OrderTypesCommandBar: React.SFC= () => {
    const {items} = useOrderTypeCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default OrderTypesCommandBar;