import { OperatorsActions } from "./operatorsActions.enum";
import { OperatorsAction } from "./operatorsActions.types";

const initState = {
  operatorsPanelOpen: false,
  selectedOperator: "",
};

export const operatorsReducer = (
  state = initState,
  action: OperatorsAction
) => {
  const { type, payload } = action;

  switch (type) {
    case OperatorsActions.TOGGLE_OPERATORS_PANEL:
      return { ...state, operatorsPanelOpen: payload };
    case OperatorsActions.SET_SELECTED_OPERATOR:
      return { ...state, selectedOperator: payload };
    default:
      return state;
  }
};
