import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { setReportDetails } from "../../../config/redux/reports/reportsAction";
import { getReportInfo } from "../../../connectors/reports/connectors";
import { useEffectAfterMount } from "../../common/hooks/useEffectAfterMount";

export const useReportData = () => {

    const dispatch = useDispatch();
    const { selectedItem, reportsPanelOpen } = useSelector(
        (state: RootStateOrAny) => state.reports
    );

    const fetchReportDetails = async () => {
        const res = await getReportInfo(selectedItem[0].year);
        dispatch(setReportDetails(res.data));
    };

    useEffectAfterMount(() => {
        if (reportsPanelOpen && selectedItem[0]) {
            fetchReportDetails();
        }
        if (!reportsPanelOpen) {
            dispatch(setReportDetails(""));
        }
    }, [reportsPanelOpen])
}