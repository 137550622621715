import React from "react";
import {Formik, Form} from "formik";
import {Stack} from "@fluentui/react";

import PanelHeader from "../../../features/common/layout/PanelHeader";
import NewFormField from "../../../features/common/layout/form/NewFormField";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import MessageContainer from "../../../features/auth/components/register/forms/MessageContainer";
import {useDispatch} from "react-redux";
import {toggleSelfEditPanel} from "../../../config/redux/client/clientActions";
import {useSelfEditForm} from "../../../features/Admins/hooks/useSelfEditForm";
import {margin} from "../../../features/clients/styles/panelForm.styles";

const SelfEditForm: React.FC = () => {
    const dispatch = useDispatch();

    const {
        handleSubmit,
        cancelForm,
        initValues,
        isLoading,
    } = useSelfEditForm();

    return (
        <Formik initialValues={initValues} onSubmit={handleSubmit}>
            {({handleSubmit}) => (
                <Form translate="yes" onSubmit={handleSubmit}>
                    <Stack>
                        <PanelHeader text={"Edytuj profil"}/>
                        <NewFormField name="firstName" label="Imię" disabled={true}/>
                        <NewFormField name="lastName" label="Nazwisko" disabled={true}/>
                        <NewFormField name="email" label="Email" disabled={true}/>
                        <NewFormField name="phone" label="Telefon" disabled={true}/>
                        <NewFormField name="company" label="Nazwa firmy" disabled={true}/>
                        <NewFormField name="address" label="Adres firmy" disabled={true}/>
                        <NewFormField name="nip" label="NIP" disabled={true}/>
                        <NewFormField name={"bdoNumber"} label={"Numer BDO"} min={'9'} max={'9'}/>
                        <div style={{marginTop: "30px"}}></div>
                        <NewFormBtns
                            submitBtnText={"Zapisz"}
                            cancelForm={cancelForm}
                            disabled={false}
                        />
                    </Stack>
                    <div className={margin}>
                        {isLoading ? <ActivityIndicator/> : <MessageContainer/>}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SelfEditForm;
