import { setListMessage, clearListMessages } from './../../../../config/redux/alerts/alertsActions';
import { useEffect, useState } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { checkQuantity } from "../../actions/checkQuantity";
import { generateItems } from "../../actions/generateCommands/generateUnactivePickuUpPoints";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { setListErr } from "../../../../config/redux/alerts/alertsActions";
import { setActivatedUsers, setSelectedUnactiveUsers } from '../../../../config/redux/unactiveusers/unactiveusersActions';
import { activatePickUpPoint, activateUser } from '../../../../connectors/clients/connectors';
import { getOrderTypes } from '../../../../connectors/orderTypes/connectors';
import { toggleReceptionPlacePanel } from '../../../../config/redux/client/clientActions';

export const useUnactivePickupPointsCommandBar = () => {
    const dispatch = useDispatch();
    const { selectedUnactiveUsers, activatedUsers } = useSelector(
        (state: RootStateOrAny) => state.unactiveusers
    );

    const { data } = useQuery("orderTypes", getOrderTypes);
    const prepareOrderTypes = () => {
        var orderTypesMap = data ? data.data.data : []
        var orderTypesa = Array<any>();
        orderTypesMap.map((x: any) => {
            var order = {
                key: x.id,
                text: x.name
            }
            orderTypesa.push(order)
        })
        return orderTypesa;
    };

    const orderTypes = prepareOrderTypes()

    const verifyPickuPoint = async (e: any) => {
        try {
            let temp = []
            temp = Object.assign({}, activatedUsers);;
            temp[selectedUnactiveUsers[0].id] = true
            const res =  await activatePickUpPoint(selectedUnactiveUsers[0].id, e.currentTarget.id);
            dispatch(setActivatedUsers(temp))
            dispatch(setListMessage("Operacja przebiegła pomyślnie"))
            dispatch(setSelectedUnactiveUsers(""))
        } catch (err) {
            dispatch(setListErr("Wystąpił błąd podczas weryfikacji punktu odbioru"))
        }
    };


    const edit = () => {
        dispatch(toggleReceptionPlacePanel({isOpen: true, formActive: true}));
    }

    const actions = {
        verifyPickuPoint,
        edit
    }

    const quantity = checkQuantity(selectedUnactiveUsers.length);

    const items = generateItems(quantity, actions, selectedUnactiveUsers[0], activatedUsers, orderTypes)

    return {
        items,
        orderTypes
    };
};
