import { setSelectedOrderTypes } from './../../../config/redux/orderTypes/orderTypesActions';
import { useState } from "react";
import { addOrderTypeItem, editOrderTypeItem } from "./../../../connectors/orderTypes/connectors/index";
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { FormikValues } from "formik";
import {
  clear,
  setActiveorderTypeItem,
  setEditing,
} from "../../../config/redux/orderTypes/orderTypesActions";
import { IResetForm } from "../../common/models/resetForm.type";
import { useMutation, useQueryCache } from "react-query";
import { setListMessage } from "../../../config/redux/alerts/alertsActions";

export const useOrderTypeForm = () => {
  const [loading, setIsLoading] = useState(false);
  const {
    selectedOrderTypes,
    activeOrderType,
  } = useSelector((state: RootStateOrAny) => state.orderTypes);
  const cache = useQueryCache();
  const dispatch = useDispatch();

  const initValues = {
      typeName: selectedOrderTypes[0] ? selectedOrderTypes[0].name : "",
      capacity: selectedOrderTypes[0] ? selectedOrderTypes[0].value : 0,
  };
  

  const [editMutate] = useMutation(editOrderTypeItem, {
    onSuccess: () => {
      cache.invalidateQueries("orderTypes");
    },
    onError: (err) => {
      throw new Error();
    },
  });

  
  const [addMutate] = useMutation(addOrderTypeItem, {
    onSuccess: () => {
      cache.invalidateQueries("orderTypes");
    },
    onError: (err) => {
      console.error(err);
      throw new Error();
    },
  });

  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true);
   
    
    try {
      if(selectedOrderTypes[0]){
        //const convertedString = values.capacity.replace(/,/g, '.')
        const data = {
          orderTypeId: selectedOrderTypes[0].orderTypeId,
          itemId: selectedOrderTypes[0].id,
          orderType: {
            name: values.typeName,
            value:  values.capacity
          },
        };

        await editMutate(data)
      }else{
        const data= {
          orderType:{
            name: values.typeName,
            value: values.capacity,
          },
          id: activeOrderType
        }
        await addMutate(data)
      }
      setIsLoading(false);
      dispatch(setListMessage("operacja przebiegła pomyślnie"))
      dispatch(setEditing({editing: false}))
      dispatch(setActiveorderTypeItem({}));
      resetForm();
    } catch (err) {
      setIsLoading(false);
    }
  };

  const cancelForm = () => {
    dispatch(clear());
    dispatch(setSelectedOrderTypes(""))
  };

  return {
    handleSubmit,
    initValues,
    cancelForm,
    selectedOrderTypes,
    loading
  };
};