import React, { useState } from "react";
import { Form, Formik } from "formik";

import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import ActivityIndicator from "../../../common/layout/ActivityIndicator";
import FormDropDown from "../../../common/layout/form/FormDropDown";
import ClientsDropDown from "./newOrder/ClientsDropDown";
import CommentInput from "./CommentInput";
import OrderDetail from "./edditOrder/OrderDetailTxt";
import OrderParams from "./newOrder/OrderParams";
import { useNewOrderFrom } from "../../hooks/useNewOrderForm";
import { divider } from "../../styles/newOrderForm.styles";
import { newOrderValidation, newOrderValidationShort } from "../../actions/validationSchemas";

import FormDropDownReception from "../../../common/layout/form/FormDropDownReception";
import PricesShow from "./PricesShow";
import { Stack } from "@fluentui/react/";
import FormHeader from "../../../clients/components/edditionPanel/FormHeader";
import NewFormField from "../../../common/layout/form/NewFormField";
import {getValidYears} from "../../../../connectors/orders/connectors";

export interface OrderFormProps {
    departments: any;
    receptionPlaces: any;
    orderType: any;
    orderTypeItems: any;
    company: any;
    department: any;
    setCompany: any;
    setDepartment: any;
    cancelForm: any;
    handleSubmit: any;
    isLoading: any;

    setReception: any;
    reception: number;
    setOrderTypeItemId: any;
    orderTypeItemId: number
}


const NewOrderForm: React.FC<OrderFormProps> = ({ departments, receptionPlaces, orderType, orderTypeItems, company, department, setCompany,
    setDepartment, cancelForm, handleSubmit, isLoading,
    setReception, reception, setOrderTypeItemId, orderTypeItemId
}) => {
    const [price, setPrice] = useState<any>(null);
    const {
        changePrice
    } = useNewOrderFrom();

    if (company.data && company.data.multiDepartment === false) {
        if (departments[0]) {
            setDepartment(departments[0])
        }
    }

  const [years, setYears] = useState<any[]>([]);

  const getYear = async () => {
    const res =await getValidYears();
    const options = res.data.result.items.map((option: any) => {
      return {
        key: option,
        text: option,
        // disabled: !option.isActive
      };
    })
    setYears(options);
  };

    // if (receptionPlaces.length == 1 && receptionPlaces[0] && !!receptionPlaces[0].key) {
    //     setReception(receptionPlaces[0].key);
    // }
    return (
        <Formik
            initialValues={{
                quantity: "1",
                reception: { reception },
                orderTypeItemId: { orderTypeItemId }
            }}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={company.data && company.data.multiDepartment === false ? newOrderValidationShort : newOrderValidation}
        >
            {({ handleSubmit, setFieldValue, resetForm, values }) => (
                <Form id='new-order-form' translate="yes" onSubmit={handleSubmit}>
                    <Stack>
                      <FormHeader text={"Dodaj raport"} />

                      {/*<Dropdown  name={"year"} options={years} label={"Rok"}/>*/}
                      <FormDropDown name={"year"} headerText={"Rok"} options={years}/>
                      <NewFormField type={"number"} name={"firstPKWiU"} label={"22.11.11.0"} placeholder={"0"} value={"0"}/>
                      <NewFormField type={"number"} name={"secondPKWiU"} label={"22.11.12.0"} placeholder={"0"} value={"0"}/>
                      <NewFormField type={"number"} name={"thirdPKWiU"} label={"22.11.13.0"} placeholder={"0"} value={"0"}/>
                      <NewFormField type={"number"} name={"fourthPKWiU"} label={"22.11.14.0"} placeholder={"0"} value={"0"}/>
                      <NewFormField type={"number"} name={"fifthPKWiU"} label={"22.11.20.0"} placeholder={"0"} value={"0"}/>
                      <NewFormField type={"number"} name={"sixthPKWiU"} label={"22.11.53.0"} placeholder={"0"} value={"0"}/>

                        <NewFormBtns
                            positionAbsolute={true}
                            submitBtnText="Zapisz"
                            cancelForm={cancelForm}
                            disabled={isLoading}
                            disabledOnlySubmitButton={!price} 
                        />

                        <div>{isLoading && <ActivityIndicator />}</div>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default NewOrderForm;