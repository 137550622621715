import React from 'react';
import { wrapper } from '../../auth/styles/activityIndicatorContainer.styles';
import ListSuccessMessage from '../../common/layout/ListSuccessMessage';
import { padding } from '../../reports/styles/style';
import InternalStoreList from './InternalStoreList';

export interface StoreProps { }

const InternalStore: React.FC<StoreProps> = () => {
    return (
        <div className={wrapper}>
            <div className={padding}>
                <ListSuccessMessage />
            </div>
            <InternalStoreList />
        </div>
    );
}

export default InternalStore;