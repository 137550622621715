import React from "react";
import { Formik, Form, FormikErrors, FormikValues } from "formik";
import { useReportForm } from "../hooks/useReportForm";
import FormDropDown from "../../common/layout/form/FormDropDown";
import NewFormField from "../../common/layout/form/NewFormField";
import { divider } from "../../auth/styles/fieldsContainer.styles";
import { RootStateOrAny, useSelector } from "react-redux";
import NewFormBtns from "../../common/layout/form/NewFormBtns";
import NewFormCheckbox from "../../common/layout/form/NewFormCheckbox";
import { IReportParameter } from "../model/report.types";
import ReportReadOnlyDetails from "./ReportReadOnlyDetails";
import { reportParameterType } from "../model/reportParameterType.enum";
import ClientsDropDown from "../../orders/components/forms/newOrder/ClientsDropDown";
import VehiclesDropDown from "./VehiclesDropDown";
import { Icon, IPivotItemProps, Label, Pivot, PivotItem } from "@fluentui/react/";
import { littleWrapper, mainWrapper, paddingTopStyle, wrapper } from "../styles/style";
import { IReportColumn } from "../interfaces/IReportColumn";
import ActivityIndicator from "../../common/layout/ActivityIndicator";

const ReportForm: React.FC = () => {
    const {
        loading,
        cancelForm,
        handleSubmit,
        initValues,
        orderTypes,
        operators,
    } = useReportForm()

    const { reportDetails } = useSelector(
        (state: RootStateOrAny) => state.reports
    );

    const columnsToSort = reportDetails.reportColumns ? reportDetails.reportColumns.map((reportColumn: IReportColumn) => {
        return {
            key: reportColumn.name,
            text: reportColumn.translation
        }
    }) : [];

    var columnsToGroup = reportDetails.reportColumns ? reportDetails.reportColumns.filter((col: IReportColumn) => col.canBeGrouped == true) : []

    const columnsToGroupPrepared = columnsToGroup.map((reportColumn: IReportColumn) => {
        return {
            key: reportColumn.name,
            text: reportColumn.translation
        }
    })

    const isColumnsToSort = columnsToSort.length > 0 ? true : false;

    const reportColumns = reportDetails.reportColumns ? reportDetails.reportColumns.map((reportColumn: IReportColumn) => checkcolumn(reportColumn)) : [];

    const IsError = (obj: FormikErrors<FormikValues>) => Object.values(obj).some(v => v !== null && typeof v !== "undefined")

    function _customRenderer(
        link?: IPivotItemProps,
        defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
    ): JSX.Element | null {
        if (!link || !defaultRenderer) {
            return null;
        }

        return (
            <span style={{ flex: '0 0 100%' }}>
                {defaultRenderer({ ...link, itemIcon: undefined })}
                <Icon iconName={link.itemIcon} style={{ color: 'red', marginLeft: '3px' }} />
            </span>
        );
    }

    return (
        loading ? <ActivityIndicator text="Generowanie raportu" /> :
            <div >
                <ReportReadOnlyDetails />
                <Formik
                    validate={(values) => {
                        const errors: any = {}
                        reportDetails.reportParameters.map((reportParameter: IReportParameter) => {
                            if (reportParameter.isRequired == true) {
                                if (values[reportParameter.name] == undefined) {
                                    errors[(reportParameter.name)] = "To pole jest wymagane do pobrania raportu"
                                }
                            }
                        })
                        return errors;
                    }}
                    enableReinitialize={true}
                    initialValues={initValues}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, setFieldValue, errors }) => (
                        <Form onSubmit={handleSubmit}>
                            <Pivot >
                                <PivotItem headerText="Ustawienia podstawowe" itemIcon={IsError(errors) == true ? "AlertSolid" : undefined} onRenderItemLink={_customRenderer}>
                                    {
                                        reportDetails.reportParameters ?
                                            reportDetails.reportParameters.map((reportParameter: IReportParameter) => switchInputType(reportParameter, orderTypes, operators, setFieldValue))
                                            : <></>}
                                    {isColumnsToSort ?
                                        <div>
                                            <FormDropDown
                                                name={"isDesc"}
                                                headerText={"Sortowanie"}
                                                placeholder="Malejąco"
                                                options={[{ key: true, text: "Malejąco" },
                                                { key: false, text: "Rosnąco" }]}
                                                required={false}
                                            />
                                            <FormDropDown
                                                name={"orderBy"}
                                                headerText={"Sortuj po kolumnie"}
                                                options={columnsToSort}
                                                required={false}
                                            />
                                        </div>
                                        : <></>}
                                    <br></br>
                                </PivotItem>
                                <PivotItem headerText="Ustawienia zaawansowane">
                                    {reportColumns.length > 0 ?
                                        <>
                                            <div className={mainWrapper}>
                                                <Label >Zaznacz kolumny, które maja się pojawić w raporcie</Label>
                                                {reportColumns}
                                            </div>
                                            {columnsToGroup.length > 0 ?
                                                <div className={mainWrapper}>
                                                    <Label >Zaznacz kolumny, po których ma być grupowanie</Label>
                                                    <FormDropDown
                                                        name={"FirstGroupBy"}
                                                        headerText={"Grupuj po kolumnie I"}
                                                        options={columnsToGroupPrepared}
                                                        required={false}
                                                    />
                                                    {columnsToGroup.length > 1 ?
                                                        <FormDropDown
                                                            name={"SecondGroupBy"}
                                                            headerText={"Grupuj po kolumnie II"}
                                                            options={columnsToGroupPrepared}
                                                            required={false}
                                                        />
                                                        : <></>}
                                                </div>
                                                : <></>}
                                        </>
                                        :
                                        <div className={mainWrapper}>
                                            <Label >Brak ustawień zaawansowanych dla tego raportu</Label>
                                        </div>
                                    }
                                </PivotItem>
                            </Pivot >
                            <NewFormBtns
                                submitBtnText="Pobierz"
                                cancelForm={cancelForm}
                                disabled={loading}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
    )
};

const checkcolumn = (reportColumn: IReportColumn) => {
    return <div className={littleWrapper}>
        <NewFormCheckbox name={reportColumn.name} label={reportColumn.translation} />
    </div>
}

const switchInputType = (reportParameter: IReportParameter, orderTypes: any, operators: any, setFieldValue: any) => {
    var type = "text"
    switch (reportParameter.type) {
        case reportParameterType.Numerical:
            type = "number"
        case reportParameterType.Date:
            type = "date"
        case reportParameterType.Numerical:
        case reportParameterType.Date:
        case reportParameterType.String:
            return <div className={divider}>
                <NewFormField
                    name={reportParameter.name}
                    label={reportParameter.label}
                    type={type}
                    required={reportParameter.isRequired} />
            </div>
        case reportParameterType.Companies:
            return <div className={paddingTopStyle}>
                <ClientsDropDown
                    name={reportParameter.name}
                    label={reportParameter.label}
                    setFieldValue={setFieldValue}
                    resetForm={() => { }}
                    value={""}
                    required={reportParameter.isRequired}
                />
            </div>
        case reportParameterType.RecykleOperators:
            return <FormDropDown
                name={reportParameter.name}
                headerText={reportParameter.label}
                options={operators}
                required={reportParameter.isRequired}
            />
        case reportParameterType.OrderTypes:
            return <FormDropDown
                name={reportParameter.name}
                headerText={reportParameter.label}
                options={orderTypes ? orderTypes : []}
                required={reportParameter.isRequired}
            />
        case reportParameterType.Vehicles:
            return <div className={paddingTopStyle}>
                <VehiclesDropDown
                    value={""}
                    label={reportParameter.label}
                    name={reportParameter.name}
                    setFieldValue={setFieldValue} />
            </div>
        case reportParameterType.Year: {
            return <FormDropDown
                key={2022}
                name={reportParameter.name}
                headerText={reportParameter.label}
                options={[{ key: 2022, text: 2022 }]}
                required={reportParameter.isRequired}
            />
        }
        default:
            return <> </>;
    }
}

export default ReportForm;