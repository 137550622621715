import * as React from "react";
import NewFormField from "../../../../../common/layout/form/NewFormField";

import {
    container,
    divider,
    fieldContainer,
} from "../../../../styles/fieldsContainer.styles";

interface ICompanyFieldsProps {
    requireCompanyData?: boolean;
}  

const CompanyFields: React.FC<ICompanyFieldsProps> = ({requireCompanyData}) => {
    return (
        <div className={container}>
            <NewFormField name="name" label="Nazwa firmy" required={requireCompanyData} />
            <div className={divider}>
                <div className={fieldContainer}>
                    <NewFormField name="taxNumber" label="NIP" required={requireCompanyData} />
                </div>
                <div className={fieldContainer}>
                    <NewFormField name="bdo" label="BDO" required={false} />
                </div>
            </div>
            <div className={fieldContainer}>
                <NewFormField name="regon" label="REGON" required={requireCompanyData} />
            </div>
        </div>
    );
};

export default CompanyFields;