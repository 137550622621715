import React, { useEffect } from "react";
import { useField } from "formik";
import { TextField } from "@fluentui/react/";

import { textfieldS } from "../../styles/formField.styles";

export interface NewFormFieldProps {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  min?: string;
  max?: string;
}

const NewFormField: React.FC<NewFormFieldProps> = ({
  type,
  name,
  label,
  placeholder,
  disabled,
  required = true,
  value, 
  min = "",
  max = "",
}) => {
  const [field, { error, touched }, { setValue }] = useField(name);
  const url = window.location.href.split('/');
  const route = window.location.href.split('/')[url.length - 1];
  const temp = (route === 'inactive');
  useEffect(() => {
    if (value && temp) {
      setValue(value);
    }
  }, []);

  return (
    <TextField
      type={type ? type : "text"}
      label={label}
      placeholder={placeholder}
      errorMessage={touched ? error : ""}
      required={required}
      disabled={disabled}
      {...field}
      styles={textfieldS}
      min={min}
      max={max}
    />
  );
};

export default NewFormField;
