import {Form, Formik} from "formik";
import {mergeStyles, Stack} from "@fluentui/react";
import * as React from "react";
import {useEffect, useState} from "react";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import ErrorMessage from "../../../features/common/layout/form/ErrorMessage";
import FormHeader from "../../../features/clients/components/edditionPanel/FormHeader";
import FormDropDownReception from "../../../features/common/layout/form/FormDropDownReception";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import CommentInput from "../../../features/orders/components/forms/CommentInput";
import OrderParams from "../../../features/orders/components/forms/newOrder/OrderParams";
import OrderDetail from "../../../features/orders/components/forms/newOrder/OrderType";
import PricesShow from "../../../features/orders/components/forms/PricesShow";
import {useNewOrderFrom} from "../../../features/orders/hooks/useNewOrderForm";
import {divider} from "../../../features/orders/styles/newOrderForm.styles";
import {useOrderForm} from "../../hooks/useOrderForm";
import NewFormField from "../../../features/common/layout/form/NewFormField";
import {Dropdown} from "@fluentui/react//lib/Dropdown";
import {getValidYears} from "../../../connectors/orders/connectors";
import {RootStateOrAny, useSelector} from "react-redux";
import {number} from "yup";
import FormDropDown from "../../../features/common/layout/form/FormDropDown";

const margin = mergeStyles({
    marginTop: 15,
});

const marginB = mergeStyles({
    marginBottom: 15
})

export interface NewOrderClientFormProps {
}

const NewOrderClientForm: React.FC<NewOrderClientFormProps> = () => {
    var companyId = sessionStorage.getItem("companyId");
    var departmentId = sessionStorage.getItem("departmentId");

    const [price, setPrice] = useState<any>(null);
    // const [brutto, setBrutto] = useState<any>(null);
    const [reception, setReception] = useState<number>(0);
    const [orderTypeItemId, setOrderTypeItemId] = useState<any>();
  const {selectedItem, formActive, editOrder, orderPanelOpen} = useSelector((state: RootStateOrAny) => state.client)

  useEffect(() => {
    if (orderPanelOpen === true) {
      getYear();
    } else {
      setYears([]);
    }
  }, [orderPanelOpen]);

    const {
        handleSubmit,
        cancelForm,
        initValues,
        isLoading,
    } = useOrderForm(companyId ? companyId : "", departmentId ? departmentId : "", reception ? reception : {});

    const {
        changePrice
    } = useNewOrderFrom();

    const [years, setYears] = useState<any[]>([]);

    const getYear = async () => {
      const res =await getValidYears();
      const options = res.data.result.items.map((option: any) => {
        return {
          key: option,
          text: option,
          // disabled: !option.isActive
        };
    })
      setYears(options);
    };

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({handleSubmit, setFieldValue, values}) => (
                <Form translate="yes" onSubmit={handleSubmit}>
                    <Stack verticalAlign="space-between" verticalFill>
                        <FormHeader text={"Dodaj raport"} />

                        <FormDropDown name={"year"} headerText={"Rok"} options={years}/>
                        <NewFormField type={"number"} name={"firstPKWiU"} label={"22.11.11.0 [kg]"} placeholder={"0"} value={"0"} min={'0'}/>
                        <NewFormField type={"number"} name={"secondPKWiU"} label={"22.11.12. [kg]"} placeholder={"0"} value={"0"} min={'0'}/>
                        <NewFormField type={"number"} name={"thirdPKWiU"} label={"22.11.13.0 [kg]"} placeholder={"0"} value={"0"} min={'0'}/>
                        <NewFormField type={"number"} name={"fourthPKWiU"} label={"22.11.14.0 [kg]"} placeholder={"0"} value={"0"} min={'0'}/>
                        <NewFormField type={"number"} name={"fifthPKWiU"} label={"22.11.20.0 [kg]"} placeholder={"0"} value={"0"} min={'0'}/>
                        <NewFormField type={"number"} name={"sixthPKWiU"} label={"22.11.53.0 [kg]"} placeholder={"0"} value={"0"} min={'0'}/>

                        <NewFormBtns
                            submitBtnText={"Dodaj"}
                            cancelForm={cancelForm}
                            disabled={isLoading}
                            // disabledOnlySubmitButton={!price}
                        />
                        <div className={margin}>
                            {isLoading ? <ActivityIndicator/> : <ErrorMessage/>}
                        </div>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default NewOrderClientForm;