import { StoreActions } from "./storeAction.enums";
import { IStoreAction } from "./storeAction.types";

const initState = {
    selectedItem: [],
    activeStoreItem: {},
    storeItemDetails: {},
    storeItemsPanelOpen: false,
    reloadStoreItemList: false,
    magazineType: undefined
}

export const storeReducer = (state = initState, action: IStoreAction) => {
    const { type, payload } = action;

    switch (type) {
        case StoreActions.SET_SELECTED_STOREITEM:
            return { ...state, selectedItem: payload };
        case StoreActions.SET_STOREITEM_DETAILS:
            return { ...state, reportDetails: payload }
        case StoreActions.TOGGLE_STOREITEM_PANEL:
            return { ...state, reportsPanelOpen: payload.open }
        case StoreActions.RELOAD_STOREITEMS:
            return { ...state, reloadReportList: payload.reload }
        case StoreActions.SET_MAGAZINETYPE:
            return { ...state, magazineType: payload}
        case StoreActions.SET_STOREITEM:
            return { ...state, activeReport: payload }
        case StoreActions.CLEAR:
            return { ...initState }
        default:
            return state;
    }
}