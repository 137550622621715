import * as React from "react";
import {useParams} from "react-router-dom";
import {mergeStyles} from "@fluentui/react/";
import {myTheme} from "../client/styles/layout/theme";
import {useEffect} from "react";
import {activateAccount} from "../connectors/activate";

const Activate: React.FC<any> = (props: any) => {
    let {token} = useParams();
    console.log("token:", token);

    useEffect(() => {
        token ? activateAccount(token) : console.log("Error -> No token provided");
    })

    const containerS = mergeStyles({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
    })

    const hS = mergeStyles({
        color: myTheme.palette.themePrimary,
    });

    return (
        <div className={containerS}>
            <h1 className={hS}>Konto zostało aktywowane</h1>
            <p>Użytkownik został powiadomiony o aktywacji jego konta, i może teraz zalogować się do serwisu</p>
        </div>
    )
}

export default Activate;