export const parseCheckboxesValues = (values: any) => {
    const orderTypeItems: Array<any> = [];
  
    Object.entries(values).forEach((value: any) => {
      if (typeof value[1] === "boolean") {
        orderTypeItems.push({
          orderTypeItemId: value[0],
          isActive: value[1],
        });
      }
    });
  
    return orderTypeItems;
  };
  
export const parseOrderTypes = (data: Array<any>) => {
    const values: Array<{ name: string; isActive: boolean }> = [];
  
    data.forEach((item: any) => {
      values.push({ name: item.orderTypeItemId, isActive: item.isActive });
    });
  
    return values;
  };
  
export const generateFields = (valuesArr: Array<any>) => {
    const values = valuesArr.reduce(function (result, item) {
      const [name, active] = Object.keys(item);
      const id = item[name];
      const isActive = item[active];
  
      result[id] = isActive;
      return result;
    }, {});
  
    return values;
  };
  
export const generateNamesArr = (data: Array<any>) => {
    const values: Array<{ name: string }> = [];
  
    data.forEach((orderType: any) => {
      orderType.items.forEach((item: any) => {
        values.push({ name: item.id });
      });
    });
  
    return values;
  };