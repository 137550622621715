import * as React from "react";
import {Route, Redirect} from "react-router-dom";
import {mergeStyles} from "@fluentui/react";
import {NavigationPath} from "./NavigationPath";
import {authorize, Roles} from "./actions/authService";
import SelfEditPanel from "../../features/Admins/components/panel/SelfEditPanel";

const main = mergeStyles({
    marginTop: "96px",
    marginLeft: "230px",
    width: "calc(100% - 230px)",
});

export const AdminLayoutRoute = ({
                                     component: Component,
                                     layout: Layout,
                                     commandBar: CommandBar,
                                     sideNav: SideNav,
                                     ...rest
                                 }: any) => {
    const {token, role, expired, isActivated} = authorize();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (token && role === Roles.ADMIN && !expired) {
                    if (!isActivated) {
                        return (
                            <Redirect to={{pathname: NavigationPath.INACTIVE_ACCOUNT}}/>
                        );
                    } else {
                        return (
                            <>
                                <Layout/>
                                {CommandBar && <CommandBar/>}
                                <div className={main}>
                                    {SideNav && <SideNav/>}
                                    <Component {...props} />
                                    <SelfEditPanel/>
                                </div>
                            </>
                        );
                    }
                } else if (window.location.pathname === NavigationPath.ACTIVATE_ACCOUNT) {
                    return <Redirect to={{pathname: NavigationPath.ACTIVATE_ACCOUNT}}/>
                } else if (token && !expired) {
                    return <Redirect to={{pathname: NavigationPath.REPORTS}}/>;
                } else {
                    return <Redirect to={{pathname: NavigationPath.UNAVAILABLE}}/>;
                }

            }}
        />
    );
};
