import * as yup from "yup";

export const validationSchemaCompany = yup.object().shape({
  email: yup
    .string()
    .email("Podaj poprawny email")
    .required("Żeby się zarejestrować musisz podać email")
    .max(50),
  // phoneNumber: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać numer telefonu")
  //   .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
  //   .min(9, "niepoprawny numer telefonu")
  //   .max(9, "niepoprawny numer telefonu"),
  name: yup.string().required("podaj nazwę firmy"),
  city: yup.string().required("Żeby się zarejestrować musisz podać Miasto"),
  street: yup.string().required("Żeby się zarejestrować musisz podać ulice"),
  postalCode: yup
    .string()
    .required("podaj kod pocztowy")
    .matches(/^[0-9, -]+$/, "podaj poprawny kod pocztowy")
    .min(6, "podaj poprawny kod pocztowy")
    .max(6, "podaj poprawny kod pocztowy"),
  bdo: yup
    .string()
    .matches(/^[0-9]+$/, "podaj poprawny numer bdo"),
  taxNumber: yup
    .string()
    .required("Żeby się zarejestrować musisz podać nip")
    .matches(/^[0-9]+$/, "wprowadź numer NIP bez kresek")
    .min(10, "niepoprawny nip")
    .max(10, "wprowadź numer NIP bez kresek"),
  regon: yup
    .string()
    .required("Żeby się zarejestrować musisz podać regon")
    .matches(/^[0-9]+$/, "wprowadź numer regon bez kresek")
    .min(9, "podaj poprawny numer regon")
    .max(14, "podaj poprawny numer regon"),
  // accept: yup
  //   .boolean()
  //   .oneOf([true], "W celu rejestracji musiz zakceptować regulamin"),
  multiDepartment: yup.boolean().oneOf([true, false]),
});

export const validationSchemaCompanyNotRequired = yup.object().shape({
  email: yup
    .string()
    .email("Podaj poprawny email")
    .required("Żeby się zarejestrować musisz podać email")
    .max(50),
  // phoneNumber: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać numer telefonu")
  //   .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
  //   .min(9, "niepoprawny numer telefonu")
  //   .max(9, "niepoprawny numer telefonu"),
  name: yup.string(),
  city: yup.string().required("Żeby się zarejestrować musisz podać Miasto"),
  street: yup.string().required("Żeby się zarejestrować musisz podać ulice"),
  postalCode: yup
    .string()
    .required("podaj kod pocztowy")
    .matches(/^[0-9, -]+$/, "podaj poprawny kod pocztowy")
    .min(6, "podaj poprawny kod pocztowy")
    .max(6, "podaj poprawny kod pocztowy"),
  bdo: yup
    .string()
    .matches(/^[0-9]+$/, "podaj poprawny numer bdo"),
  taxNumber: yup
    .string()
    .matches(/^[0-9]+$/, "wprowadź numer NIP bez kresek")
    .min(10, "niepoprawny nip")
    .max(10, "wprowadź numer NIP bez kresek"),
  regon: yup
    .string()
    .matches(/^[0-9]+$/, "wprowadź numer regon bez kresek")
    .min(9, "podaj poprawny numer regon")
    .max(14, "podaj poprawny numer regon"),
  // accept: yup
  //   .boolean()
  //   .oneOf([true], "W celu rejestracji musiz zakceptować regulamin"),
  multiDepartment: yup.boolean().oneOf([true, false]),
});

export const validationSchemaCompanyBasicInfo = yup.object().shape({
  postalCode: yup
    .string()
    .required("Podaj kod pocztowy")
    .matches(/^[0-9, -]+$/, "Podaj poprawny kod pocztowy")
    .min(6, "Podaj poprawny kod pocztowy")
    .max(6, "Podaj poprawny kod pocztowy"),
    city: yup
    .string()
    .required("Podaj miejscowość"),
  street: yup.string().required("Podaj ulicę i numer"),
});



export const validationSchemaDepartment = yup.object().shape({
  email: yup
    .string()
    .email("Podaj poprawny email")
    .required("Żeby się zarejestrować musisz podać email")
    .max(50),
  // phoneNumber: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać numer telefonu")
  //   .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
  //   .min(9, "niepoprawny numer telefonu")
  //   .max(9, "niepoprawny numer telefonu"),
  city: yup
    .string()
    .required("Żeby się zarejestrować musisz podać miejscowość"),
  street: yup.string().required("Żeby się zarejestrować musisz podać ulice"),
  postalCode: yup
    .string()
    .required("Żeby się zarejestrować podaj kod pocztowy")
    .matches(/^[0-9, -]+$/, "podaj poprawny kod pocztowy")
    .min(6, "podaj poprawny kod pocztowy")
    .max(6, "podaj poprawny kod pocztowy"),
  accept: yup
    .boolean()
    .oneOf([true], "W celu rejestracji musiz zakceptować regulamin"),
});

export const validationSchemaUser = yup.object().shape({
  email: yup
    .string()
    .email("Podaj poprawny email")
    .required("Żeby się zarejestrować musisz podać email")
    .max(50),
  phoneNumber: yup
    .string()
    .required("Żeby się zarejestrować musisz podać numer telefonu")
    .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
    .min(9, "niepoprawny numer telefonu")
    .max(9, "niepoprawny numer telefonu"),
  department: yup
    .string()
    .required("Żeby się zarejestrować musisz wybrać oddział"),
});

export const validationSchemaUserShort = yup.object().shape({
  email: yup
    .string()
    .email("Podaj poprawny email")
    .required("Podaj adres email")
    .max(50),
  // phoneNumber: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać numer telefonu")
  //   .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
  //   .min(9, "niepoprawny numer telefonu")
  //   .max(9, "niepoprawny numer telefonu"),
});

export const validationSchemaNip = yup.object().shape({
  nip: yup
    .string()
    .required("Żeby się zarejestrować musisz podać nip")
    .matches(/^[0-9]+$/, "podaj poprawny nip")
    .min(10, "niepoprawny nip")
    .max(10, "niepoprawny nip"),
});

export const validationSchemaNipNotRequired = yup.object().shape({
  nip: yup
    .string()
    .matches(/^[0-9]+$/, "podaj poprawny nip")
    .min(10, "niepoprawny nip")
    .max(10, "niepoprawny nip"),
});
