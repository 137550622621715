import * as React from 'react';
import {useSelector, RootStateOrAny} from "react-redux"
import { ITextStyles, Text, mergeStyles } from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";

const headerContainer = mergeStyles({
    marginBottom: "35px",
    display: "flex",
    flexDirection: "column",
  });
  
  export const headerS: ITextStyles = {
    root: {
      fontSize: FontSizes.size24,
      marginBottom: 20
    },
  };
 
const AlredyRegisteredHeader: React.SFC = () => {
    const {company} = useSelector((state: RootStateOrAny) => state.register)
    return ( 
        <div className={headerContainer}>
            <Text styles={headerS}>
              Firma o podanym numerze NIP już istnieje
            </Text>
            <Text>{company.data.name}</Text>
        </div>
     );
}
 
export default AlredyRegisteredHeader;