import React from 'react';
import {
    CommandBar,
  } from "@fluentui/react/";

import { useOrderPanelCommandBar } from '../../../hooks/orders/useOrderPanelCommandBar';

const commandBarS = {
    root: {
      width: 500,
    },
  };

const OrderPanelCommandBar: React.FC = () => {
    const {items} = useOrderPanelCommandBar()

    return ( 
        <CommandBar items={items} styles={commandBarS}/>
     );
}
 
export default OrderPanelCommandBar;
