export enum reportParameterType {
    Numerical = 0,
    String = 1,
    Date = 2,
    Companies = 3,
    DriversList = 4,
    OrderTypes = 5,
    Vehicles = 6,
    RecykleOperators = 7,
    Year = 9
}