import * as React from "react";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import { useStoreMap } from "../hooks/useStoreMap";
import StoreMapMain from "./StoreMapMain";

export const StoreMap: React.FC = () => {

    const {
        receptionPlacePoints,
        containerPoints,
        containerPointsLoading,
        receptionPlacePointsLoading,
        internalStorePoints,
        internalStorePointsPointsLoading
    } = useStoreMap();

    return (containerPointsLoading == false && receptionPlacePointsLoading == false && internalStorePointsPointsLoading == false) ?
        <StoreMapMain
            receptionPlacePoints={receptionPlacePoints}
            containerPoints={containerPoints}
            internalStorePoints={internalStorePoints}
        />
        :
        <ActivityIndicator text="Ładowanie mapy" />
}