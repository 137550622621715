import * as React from "react";
import { Stack, Image, FontIcon } from "@fluentui/react";
import UserIcon from "./UserIcon";
import { NavLink } from "react-router-dom";
import { NavigationPath } from "../../../config/routing/NavigationPath";
import { burgerS, container, containerS, link, textS } from "../../styles/layout/header.styles";
import { useDispatch } from "react-redux";
import { toggleClientNav } from "../../../config/redux/client/clientActions";
import {myTheme} from "../../styles/layout/theme";




export interface HeaderProps {}


const Header: React.FC<HeaderProps> = () => {
  const dispatch = useDispatch()

  const openNav = () => {
    dispatch(toggleClientNav(true))
  }

  return (
    <Stack
      styles={containerS}
      verticalAlign="center"
      horizontal
      horizontalAlign="space-between"
      theme={myTheme}
    >
      <div className={container}>
        <FontIcon iconName='AlignJustify' className={burgerS} onClick={openNav}/>
        <Image src="/logo_32x32.png" />
        <NavLink to={NavigationPath.REPORTS} className={link}>
          <p className={textS}>ROSPRO</p>
        </NavLink>
      </div>
      <UserIcon />
    </Stack>
  );
};

export default Header;
