import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { getDocuments } from "../../../connectors/store/connectors/index";
import {
  reloadData,
  addNewStoreDoc,
  setApprove,
  setCancel,
} from "../../../config/redux/storeDocs/storeDocsActions";
import { toggleFiltration } from "../../../config/redux/filters/filterActions";
import { IPaginationProps, IPaginationState } from "./pagination.types";
import { generateItems } from "../actions/generateStoreDocs";
import { AxiosResponse } from "axios";

class Pagination extends React.Component<IPaginationProps, IPaginationState> {
  private _data: any = [];

  footerRef = React.createRef<HTMLDivElement>();

  state = {
    data: [],
    limit: 35,
    total: 0,
    page: 1,
    fruit: "",
    loading: false,
  };

  componentDidMount() {
    if (this.props.footerRef.current !== null) {
      this._observer.observe(this.props.footerRef.current);
    }

    this._setdocuments();
  }

  componentDidUpdate(prevProps: IPaginationProps) {
    const {
      reload,
      searchPhrase,
      filter,
      addStoreDoc,
      column,
      dispatch,
      approve,
      cancel,
    } = this.props;
    if (reload) {
      this._reload();
      this._removeItems();
    }

    if (filter) {
      this._reload();
      if (column) {
        this._filterdocuments(
          searchPhrase,
          column.key,
          column.isSortedDescending
        );
      } else {
        this._filterdocuments(searchPhrase);
      }

      dispatch(toggleFiltration({ filter: false }));
    }

    if (addStoreDoc) {
      this._setdocuments(1);
      dispatch(addNewStoreDoc(false));
      this._reload();
    }

    if (approve) {
      this._setdocuments(1);
      dispatch(setApprove(false));
      this._reload();
    }

    if (cancel) {
      this._setdocuments(1);
      dispatch(setCancel(false));
      this._reload();
    }
  }

  componentWillUnmount() {
    if (this.props.footerRef.current !== null) {
      this._observer.unobserve(this.props.footerRef.current);
    }
  }

  private _handleObserver = (entry: any, observer: any) => {
    if (this.state.data.length < this.state.total) {
      this._setdocuments();
    }
  };

  private _observer = new IntersectionObserver(this._handleObserver, {
    root: null,
    rootMargin: "200px",
    threshold: 1,
  });

  private _reload = async () => {
    this.props.dispatch(reloadData({ reload: false }));
  };

  private _removeItems = () => {
    const documents = _.remove(this.state.data, (document: any) => {
      if (!this.props.selectedStoreDocs.includes(document.id)) {
        return document;
      }
    });

    this.setState({ data: [...documents] });
  };

  private _filterdocuments = async (
    searchPhrase: string,
    orderBy: undefined | string = undefined,
    desc: undefined | boolean = false
  ) => {
    const { limit } = this.state;
    //const params = getParams(this.props.location.pathname);
    var res: AxiosResponse<any>;
    if (orderBy !== undefined) {
      res = await getDocuments({
        page: 1,
        limit,
        OrderBy: orderBy,
        Desc: desc,
        allFields: searchPhrase,
      });
    } else {
      res = await getDocuments({ page: 1, limit, allFields: searchPhrase });
    }

    const documents = generateItems(res.data.result);

    this.setState((prevState: IPaginationState) => {
      return {
        data: [...documents],
        page: 1,
        total: res.data.total,
      };
    });
  };

  private _setdocuments = async (newPage?: number) => {
    const { limit, page } = this.state;
    const p = newPage ? newPage : page;
    // this.setState({loading: true})
    const res = await getDocuments({
      page: p,
      limit,
      Desc: true,
      OrderBy: "DocumentDate",
    });
    const documents = generateItems(res.data.result);
    this.setState((prevState: IPaginationState) => {
      return {
        data: newPage ? [...documents] : [...prevState.data, ...documents],
        page: newPage ? newPage : prevState.page + 1,
        total: res.data.total,
        // loading: false
      };
    });
  };

  public render() {
    const renderProps = {
      data: this.state.data,
      loading: this.state.loading,
    };

    return this.props.render(renderProps);
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    reload: state.storeDocs.reload,
    addStoreDoc: state.storeDocs.addStoreDoc,
    searchPhrase: state.filtration.searchPhrase,
    selectedStoreDocs: state.storeDocs.selectedStoreDocs,
    filter: state.filtration.filter,
    column: state.filtration.column,
    approve: state.storeDocs.approve,
    cancel: state.storeDocs.cancel,
  };
};

export default connect(mapStateToProps)(withRouter(Pagination));
