import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IDetailsListStyles,
  SelectionMode,
} from "@fluentui/react//lib/DetailsList";
import { MarqueeSelection } from "@fluentui/react//lib/MarqueeSelection";
import { Stack, getTheme } from "@fluentui/react/";
import _ from "lodash";

import {
  IProps,
  IStateProps,
  IDispatchProps,
  IListProps,
} from "../basicListTypes";

const listS: Partial<IDetailsListStyles> = {
  contentWrapper: {
    selectors: {
      "div[data-automationid=DetailsRowCell]": {
        cursor: "pointer",
      },
      "div[data-automationid=DetailsRowCheck]": {
        cursor: "pointer",
      },
    },
  },
};

export interface IBasciListState {
  items: Array<any>;
}

export class BasicList extends React.Component<IProps, IBasciListState> {
  private _selection: Selection;
  private _onItemInvoked: (item?: any, index?: number, e?: Event) => void;

  constructor(props: IProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        const items = this._getSelectedItems();

        props.dispatch(this.props.setSelectedItems([...items]));
      },
    });

    this._onItemInvoked = (item: any) => {
      // if (sessionStorage.getItem("role") === "CLIENT_ADMIN") {
      const { setActiveItem, togglePanel, dispatch } = this.props;

      dispatch(setActiveItem(item));
      dispatch(togglePanel({ open: true }));
      // }
      
    };

    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    this.setData();
  }

  componentDidUpdate(prevProps: any) {
    const {
      selectedItems,
      removeItems,
      listEdited,
      reloadItems,
    } = this.props;

    if (reloadItems) {
      this._selection.selectToKey("", true);
      this._reload();
    }

    if (removeItems) {
      this._removeItems();
      this._selection.selectToKey("", true);
      this._reload();
    }

    if (
      prevProps.panelOpen &&
      selectedItems
      // activeItem.hasOwnProperty("id")
    ) {
      this._selection.selectToKey("", true);
    }

    if (listEdited) {
      this.editList();
    }
  }

  componentWillUnmount(){
    this.props.dispatch(this.props.setSelectedItems([]))
  }

  public render(): JSX.Element {
    const { items } = this.state;

    return (
      <Stack >
        {/* <MarqueeSelection isEnabled={!this.props.panelOpen && sessionStorage.getItem("role") === "CLIENT_ADMIN"} selection={sessionStorage.getItem("role") === "CLIENT_ADMIN" ? this._selection : new Selection()}> */}
        <MarqueeSelection isEnabled={!this.props.panelOpen} selection={this._selection}>
          <DetailsList
            items={items}
            columns={this.props.columns}
            selection={this._selection}
            // selectionMode={sessionStorage.getItem("role") === "CLIENT_ADMIN"  ? SelectionMode.single : SelectionMode.none}
            selectionMode={SelectionMode.single}
            onItemInvoked={this._onItemInvoked}
            setKey="items"
            layoutMode={DetailsListLayoutMode.justified}
            // styles={sessionStorage.getItem("role") === "CLIENT_ADMIN"  ? listS : undefined}
            styles={listS}
            onRenderRow={this.props.onRenderRow}
          />
        </MarqueeSelection>
      </Stack>
    );
  }

  private editList = async () => {
    await this.setData();
    this._selection.selectToKey("", true);
    this.props.dispatch(this.props.editList({ edit: false }));
  };

  private _removeItems = () => {
    const items = _.remove(this.state.items, (item: any) => {
      if(this.props.selectedItems && this.props.selectedItems[0] && this.props.selectedItems[0].id !== item.id) {
        return item
      }
    });
    this.setState({ items });
  };

  private setData = async () => {
    const res = await this.props.getData();
    const items = this.props.parseItems(res.data.result);

    this.setState({ items });
  };

  private _reload = async () => {
    await this.setData();
    const {dispatch, reload} = this.props
    dispatch(reload({ reload: false }));
  };

  private _getSelectedItems = () => {
    const items = this._selection.getSelection();
    return items;
  };
}

const mapStateToProps = (state: RootStateOrAny) => {
  return {};
};

export default connect<IStateProps, IDispatchProps, IListProps>(mapStateToProps)(BasicList);