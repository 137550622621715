import { IQuantity, Quantity } from "../../../common/models/quantity.model";

interface IActions {
  openPanelToAdd: () => void;
  openPanelToEdit: () => void;
  deleteOperator: () => void
}

export const generateItems = (quantity: IQuantity, actions: IActions) => {
  switch (quantity) {
    case Quantity.NONE:
      return [
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.openPanelToAdd,
        },
      ];
    case Quantity.ONE:
      return [
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.openPanelToAdd,
        },
        {
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          name: "edit",
          onClick: actions.openPanelToEdit,
        },
        {
          key: "delete",
          text: "Usuń",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.deleteOperator,
        },
      ];
    default:
      return [];
  }
};
