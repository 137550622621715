import React, { useEffect } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel, IPanelStyles } from "@fluentui/react/";

import NipForm from "../../auth/components/register/forms/NipForm";
import { Breakpoints } from "../../common/styles/utils/Breakpoints";
import {clearCompanyData} from "../../../config/redux/register/registerActions"
import { addNewClient, toggleAddComapnyPanel } from "../../../config/redux/clients/clientsActions";
import FormsContainer from "../../auth/components/register/forms/FormsContainer";
import { clearAlerts } from "../../../config/redux/alerts/alertsActions";

const PanelS: Partial<IPanelStyles> = {
  header: {
    marginTop: "25px",
    marginLeft: "20px",
    selectors: {
      [`@media ${Breakpoints.LARGE}`]: {
        marginLeft: "0",
      },
    },
  },

  navigation: {
    position: "absolute",
    top: "20px",
    right: "10px",
    zIndex: 3,
    selectors: {
      [`@media ${Breakpoints.LARGE}`]: {
        top: "20px",
        right: "20px",
      },
    },
  },

  content: {
    selectors: {
      [`@media (min-width: 414px)`]: {
        padding: "30px",
      },
    },
  },
};

const AddCompanyPanel: React.SFC = () => {
  const dispatch = useDispatch();
  const {
    complete,
    error,
    company: { success },
  } = useSelector((state: RootStateOrAny) => state.register);
  const { toggleClientsPanel } = useSelector((state: RootStateOrAny) => state.clients);

  const dismissPanel = () => {
    dispatch(toggleAddComapnyPanel({ isOpen: false }));
    dispatch(clearCompanyData())
    dispatch(clearAlerts())
  };

  useEffect(() => {
    if (complete) {
      dispatch(addNewClient(true))
      setTimeout(() => {
        toggleClientsPanel  && dismissPanel();
        dispatch(clearCompanyData());
      }, 1);
    }
  }, [complete]);

  useEffect(() => {
    if(!complete && !success){
      toggleClientsPanel && dismissPanel()
    }
  }, [success])

  return (
    <Panel
      headerText="Formularz rejestracji"
      isOpen={toggleClientsPanel}
      closeButtonAriaLabel="Close"
      onDismiss={dismissPanel}
      type={3}
      isLightDismiss={false}
      styles={PanelS}
    >
      {error || !success ? <NipForm /> : <FormsContainer/>}
    </Panel>
  );
};

export default AddCompanyPanel;
