import React, { useEffect } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react/";

import {toggleVehiclePanel} from "../../../config/redux/vehicles/vehiclesActions"
import VehicleForm from "./VehicleForm"
import { getOrderTypes } from '../../../connectors/orderTypes/connectors/index';
import {setOrderTypes} from "../../../config/redux/orderTypes/orderTypesActions"
import {panelS} from "../../common/styles/panelStyles"


const VehiclePanel: React.FC = () => {
  const dispatch = useDispatch();
  const {open} = useSelector((state: RootStateOrAny) => state.vehicles)

  const dissmisPanel = () => {
    dispatch(toggleVehiclePanel({open: false}))
  };

  useEffect(() => {
    get()
  },[])

  const get = async () => {
    const res = await getOrderTypes()
    dispatch(setOrderTypes(res.data.result))
  }

  return (
    <Panel
      headerText="Pojazd"
      isOpen={open}
      closeButtonAriaLabel="Close"
      type={3}
      onDismiss={dissmisPanel}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      styles={panelS}
    >
        <VehicleForm/>
    </Panel>
  );
};

export default VehiclePanel;
