import {
  INavStyleProps,
  INavStyles,
  IPanelStyles,
  IScrollablePaneStyles,
  getTheme
} from "@fluentui/react";

import { Breakpoints } from "../../../features/common/styles/utils/Breakpoints";

const theme = getTheme()

export const containerS: Partial<IScrollablePaneStyles> = {
  root: {
    width: "230px",
    height: "calc(100vh - 50px)",
    position: "fixed",
    top: 50,
    left: 0,
    boxSizing: "border-box",
  },
};

export const hiddenNav: Partial<IScrollablePaneStyles> = {
  root: {
    width: "230px",
    height: "calc(100vh - 50px)",
    position: "fixed",
    top: 50,
    left: 0,
    boxSizing: "border-box",
    borderRight: `2px solid ${theme.palette.neutralLighter}`,
    display: "none",
    selectors: {
      [`@media ${Breakpoints.LARGE}`]: {
        display: "block",
      },
    },
  },
};

export const navS = (props: INavStyleProps): Partial<INavStyles> => ({
  chevronButton: {
    borderBottom: "none",
  },

  chevronIcon: [
    {
      transform: "rotate(-90deg)",
    },
    props.isExpanded && { transform: " rotate(0)" },
  ],

  link: {
    paddingLeft: "35px",
  },
});

export const panelS: Partial<IPanelStyles> = {
  header: {
    marginBottom: "30px",
    marginTop: "25px",
    marginLeft: "20px",
    selectors: {
      [`@media ${Breakpoints.LARGE}`]: {
        marginLeft: "0",
      },
    },
  },

  navigation: {
    position: "absolute",
    top: "20px",
    right: "10px",
    zIndex: 3,
    selectors: {
      [`@media ${Breakpoints.LARGE}`]: {
        top: "20px",
        right: "20px",
      },
    },
  },

  content: {
    selectors: {
      [`@media (min-width: 375px)`]: {
        padding: "30px",
      },
    },
  },
};
