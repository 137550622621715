import React from "react";
import { mergeStyles } from "@fluentui/react/";
import PricesListContainer from "./List/PricesListContainer";
import PricingPanel from "./panel/PricingPanel";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";

const wrapper = mergeStyles({
  width: "95%",
  margin: "0px auto"
});

const padding = mergeStyles({
  paddingTop: 15
})

const Pricing: React.FC = () => {
  return (
    <div className={wrapper}>
      <div className={padding}>
        <ListSuccessMessage/>
      </div>
      <PricesListContainer />
      <PricingPanel/>
    </div>
  );
};

export default Pricing;
