import { useEffect, useRef } from "react";

export const useEffectAfterMount = (effect: any, dependencies: Array<any>) => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (effect){
      return effect();
    } 
  }, dependencies);
};
