import { toggleSelfEditPanel } from './../../../config/redux/admins/adminsActions';
import { useDispatch } from 'react-redux';
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {updateBdo, userInfo} from "../../../connectors/auth/connectors";
import {FormikValues} from "formik";
import {IResetForm} from "../../common/models/resetForm.type";

export const useSelfEditForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const cancelForm = () => {
        dispatch(toggleSelfEditPanel(false));
    }

    const res = useQuery("userinfo", userInfo);
    const user = res.data.data.result.user

    const initValues = {
        firstName: user.name,
        lastName: user.surname,
        email: user.emailAddress,
        phone: user.phoneNumber,
        bdoNumber: user.bdo ? user.bdo : "",
        nip: user.nip ? user.nip : "",
        company: user.company.companyInfo.name ? user.company.companyInfo.name : "",
        address: user.company.companyInfo.companyAddress.normalized ? user.company.companyInfo.companyAddress.normalized: "",
    }

    const handleSubmit = (values: FormikValues, {resetForm}: IResetForm) => {
        setIsLoading(true);
        const data = {
            id: user.id,
            bdo: values.bdoNumber,
        }
        try {
            updateBdo(data);
            setIsLoading(false);
            dispatch(toggleSelfEditPanel(false));
        }catch (err) {
            setIsLoading(false);
        }
    }

    return{
        initValues,
        handleSubmit,
        cancelForm,
        isLoading,
    }
}