import { ClientActions } from './clientActions.enum';
import { IReportPanel, IToggleReceptionPlacePanel } from './clientActions.types';


export const toggleSelfEditPanel = (isOpen: boolean) => {
    return {
        type: ClientActions.TOGGLE_SELF_EDIT_PANEL,
        payload: isOpen
    }
}

export const toggleClientNav = (isOpen: boolean) => {
    return{
        type: ClientActions.TOGGLE_NAV,
        payload: isOpen
    }
}

export const toggleReceptionPlacePanel = (data: IToggleReceptionPlacePanel) => {
    return{
        type: ClientActions.TOGGLE_RECEPTIONPLACE_PANEL,
        payload: data
    }
}

export const setSelectedListItem = (data: any) => {
    return{
        type: ClientActions.SET_SELECTED_ITEM,
        payload: data
    }
}

export const toggleOrderPanel = (data: IToggleReceptionPlacePanel) => {
    return{
        type: ClientActions.TOGGLE_ORDER_PANEL,
        payload: data
    }
}

export const setOrderDetails = (data: any) => {
    return{
        type: ClientActions.SET_ORDER_DETAILS,
        payload: data
    }
}

export const reloadOrders = (data: boolean) => {
    return {
      type: ClientActions.RELOAD_ORDERS,
      payload: data
    }
}

export const toggleOrdersReportPanel = (data: IReportPanel) => {
    return{
        type: ClientActions.TOGGLE_ORDERS_REPORT_PANEL,
        payload: data
    }
}