import { StoreMapActions } from "./storeMap.enum"

export const setShowContainerPoints = (data: boolean) => {
  return {
    type: StoreMapActions.SHOW_CONTAINER_POINTS,
    payload: data,
  };
};

export const setShowReceptionPlacePoints = (data: boolean) => {
  return {
    type: StoreMapActions.SHOW_RECEPTIONPLACE_POINTS,
    payload: data
  }
}

export const setShowInternalStorePoints = (data: boolean) => {
  return {
    type: StoreMapActions.SHOW_INTERNALSTORE_POINTS,
    payload: data
  }
}