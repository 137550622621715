import api from "../../../config/axios/configAxios";

export const getReports = async () =>
    api.get(`/api/services/app/Report/GetAll`);

export const getReportInfo = async(year: number) =>{
  let result = await api.get('api/services/app/Report/GetByYear?year='+year);
  return result;
}

    export const GetReportFile = async (name: string, reportId: number, query: string) => {
        var res = await api.get("api/services/app/Report/GetPdf?id="+reportId+"&"+query, {responseType: 'blob', }
        ).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name+'.xlsx');
          document.body.appendChild(link);
          link.click();
        }).catch(err => {
          throw err;
        })
      };