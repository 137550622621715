import * as React from "react";
import {Route, Redirect} from "react-router-dom";
import {mergeStyles} from "@fluentui/react";
import {NavigationPath} from "./NavigationPath";
import {authorize} from "./actions/authService";
import ClientLayout from "../../client/components/layout/ClientLayout";
import {Breakpoints} from "../../features/common/styles/utils/Breakpoints";

const main = mergeStyles({
    marginTop: "96px",

    selectors: {
        [`@media ${Breakpoints.LARGE}`]: {
            marginLeft: "230px",
            width: "calc(100% - 230px)",
        },
    },
});

export const LayoutRoute = ({
                                component: Component,
                                layout: Layout,
                                commandBar: CommandBar,
                                ...rest
                            }: any) => {
    const {isActivated} = authorize();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isActivated) {
                    return (
                        <Redirect to={{pathname: NavigationPath.INACTIVE_ACCOUNT}}/>
                    );
                } else if (window.location.pathname.includes(NavigationPath.ACTIVATE_ACCOUNT)) {
                    return <Redirect to={{pathname: NavigationPath.ACTIVATE_ACCOUNT}}/>
                } else {
                    return <>
                        <ClientLayout/>
                        {CommandBar && <CommandBar/>}
                        <div className={main}>
                            <Component {...props} />
                        </div>
                    </>;
                }
            }}
        />
    );
};