import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react/";
import { toggleAddStoreDocPanel } from "../../../config/redux/storeDocs/storeDocsActions";
import { panelS } from "../../common/styles/panelStyles";
import StoreDocsAddForm from "../components/StoreDocsAddForm";
import StoreDocsShowForm from "../components/StoreDocsShowForm";

const StoreDocsPanel: React.FC = () => {
    const dispatch = useDispatch();
    const { toggleStoreDocsPanel, toggleStoreDocsPanelToAdd } = useSelector(
        (state: RootStateOrAny) => state.storeDocs
    );

    const dismissPanel = () => {
        dispatch(toggleAddStoreDocPanel({ isOpen: false, add: false }));
    };

    return (
        <Panel
            isOpen={toggleStoreDocsPanel}
            closeButtonAriaLabel="Close"
            headerText={toggleStoreDocsPanelToAdd ? "Dodaj dokument" : "Szczegóły dokumentu"}
            onDismiss={dismissPanel}
            type={3}
            styles={panelS}
            isHiddenOnDismiss={false}
        >
            {toggleStoreDocsPanelToAdd ?
                <StoreDocsAddForm /> :
                <StoreDocsShowForm />
            }
        </Panel>
    );
};

export default StoreDocsPanel;