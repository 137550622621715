import { OrderTabsNames } from "./../models/OrderTabs.enum";
import { useEffectAfterMount } from "./../../common/hooks/useEffectAfterMount";
import { useDispatch } from "react-redux";
import { RootStateOrAny, useSelector } from "react-redux";

import {
  getOrderComments,
  getOrderDetails,
  getOrderHistory,
} from "./../../../connectors/orders/connectors/index";
import {
  addNewComment,
  setOrderComments,
  setOrderDetails,
  setOrderHistory,
} from "./../../../config/redux/orders/ordersPanel/orderPanelActions";

export const useOrderData = () => {
  const dispatch = useDispatch();
  const {
    yearlyReportPanelOpen,
    orderPanelOpen,
    SMSPanelOpen,
    activeTab,
    orderHistory,
    orderComments,
    newComment,
  } = useSelector((state: RootStateOrAny) => state.orderPanel);
  const { selectedOrders } = useSelector(
    (state: RootStateOrAny) => state.orders
  );

  const fetchOrderDetails = async () => {
    const res = await getOrderDetails(selectedOrders[0].id);
    dispatch(setOrderDetails(res.data.result));
  };

  const fetchOrderHistory = async () => {
    const res = await getOrderHistory(selectedOrders[0].id);
    dispatch(setOrderHistory(res.data.result));
  };

  const fetchOrderComments = async () => {
    const res = await getOrderComments(selectedOrders[0].id);
    dispatch(setOrderComments(res.data.result));
  };

  useEffectAfterMount(() => {
    if (orderPanelOpen && selectedOrders[0]) {
      fetchOrderDetails();
    }

    if (!orderPanelOpen) {
      dispatch(setOrderDetails(""));
    }
  }, [orderPanelOpen]);

  useEffectAfterMount(() => {
    if (orderPanelOpen) {
      if (activeTab === OrderTabsNames.COMMENTS && !orderComments) {
        fetchOrderComments();
      }

      if (activeTab === OrderTabsNames.HISTORY && !orderHistory) {
        fetchOrderHistory();
      }
    }

    if (!orderPanelOpen) {
      dispatch(setOrderHistory(""));
      dispatch(setOrderComments(""));
    }
  }, [orderPanelOpen, activeTab]);

  useEffectAfterMount(() => {
    fetchOrderComments();
    dispatch(addNewComment(false));
  }, [newComment]);

  return {
    orderPanelOpen,
    yearlyReportPanelOpen,
    SMSPanelOpen,
  };
};
