import * as React from "react";
import {
  CommandBar,
} from "@fluentui/react/";

import {useAdminsCommandBar} from "../../hooks/settings/useAdminsCommandBar"
import { comandBarS} from "../../styles/commandBar.styles"
 
const AdminsCommandBar: React.FC = () => {
    const {items} = useAdminsCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default AdminsCommandBar;


