import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import { withRouter } from "react-router-dom";
import { getStoreItems } from "../../../connectors/store/connectors/index";
import { reloadData } from "../../../config/redux/storeDocs/storeDocsActions";
import { toggleFiltration } from "../../../config/redux/filters/filterActions"
import { IPaginationProps, IPaginationState } from "./pagination.types";
import { generateItems } from "../actions/generateStore"

class Pagination extends React.Component<IPaginationProps, IPaginationState> {

    footerRef = React.createRef<HTMLDivElement>();

    state = {
        data: [],
        limit: 30,
        total: 0,
        page: 1,
        fruit: "",
        loading: false,
    };

    componentDidMount() {
        if (this.props.footerRef.current !== null) {
            this._observer.observe(this.props.footerRef.current);
        }

        this._setStoreItems();
    }

    componentDidUpdate(prevProps: IPaginationProps) {
        const { reload, searchPhrase, filter, column, dispatch } = this.props
        if (reload) {
            this._reload();
            this._removeItems();
        }

        if (filter) {
            this._reload();
            if (column) {
                    this._filterStoreItems(searchPhrase, column.key, column.isSortedDescending)
            }
            else {
                    this._filterStoreItems(searchPhrase)
            }
            dispatch(toggleFiltration({ filter: false }))
        }
    }

    componentWillUnmount() {
        if (this.props.footerRef.current !== null) {
            this._observer.unobserve(this.props.footerRef.current);
        }
    }

    private _handleObserver = (entry: any, observer: any) => {
        if (this.state.data.length < this.state.total) {
            this._setStoreItems();
        }
    };

    private _observer = new IntersectionObserver(this._handleObserver, {
        root: null,
        rootMargin: "200px",
        threshold: 1,
    });

    private _reload = async () => {
        this.props.dispatch(reloadData({ reload: false }));
    };

    private _removeItems = () => {
    };

    private _filterStoreItems = async (searchPhrase: string, orderBy: undefined | string = undefined, desc: undefined | boolean = false) => {
        const { limit } = this.state;
        //const params = getParams(this.props.location.pathname);

        let obj: any = {
            page: 1,
            limit,
            allFields: searchPhrase
        }

        if (orderBy != undefined) {
            obj.OrderBy = orderBy
        }

        if (desc != undefined) {
            obj.Desc = desc
        }

        obj.StoreTypeEnum = 1;

        var storeItems = await getStoreItems(obj);

        const documents = generateItems(storeItems.data.data);

        this.setState((prevState: IPaginationState) => {
            return {
                data: [...documents],
                page: 1,
                total: storeItems.data.total,
            };
        });
    }

    private _setStoreItems = async (newPage?: number) => {
        const { limit, page } = this.state;
        const p = newPage ? newPage : page;
        // this.setState({loading: true})
        const res = await getStoreItems({ page: p, limit, Desc: true,  StoreTypeEnum: 1 });
        const documents = generateItems(res.data.result);
        this.setState((prevState: IPaginationState) => {
            return {
                data: newPage ? [...documents] : [...prevState.data, ...documents],
                page: newPage ? newPage : prevState.page + 1,
                total: res.data.total,
            };
        });
    };

    public render() {
        const renderProps = {
            data: this.state.data,
            loading: this.state.loading
        };

        return this.props.render(renderProps);
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        reload: state.store.reload,
        searchPhrase: state.filtration.searchPhrase,
        selectedStoreDocs: state.storeDocs.selectedStoreDocs,
        filter: state.filtration.filter,
        column: state.filtration.column,
    };
};

export default connect(mapStateToProps)(withRouter(Pagination));