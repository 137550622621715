export enum OrderPanelActions {
    SET_ORDER_DETAILS = "setOrderDetails",
    TOGGLE_ORDER_PANEL = "toggleOrderPanel",
    TOGGLE_YEARLY_REPORT_PANEL = "toggleYearlyReportPanel",
    TOGGLE_SYNC_ORDER_PANEL = "toggleSyncOrderPanel",
    TOGGLE_SMS_PANEL = "toggleSMSPanel",
    SET_ORDER_COMMENTS = "setOrderComments",
    SET_ORDER_HISTORY = "setOrderHistory",
    SET_ACTIVE_TAB = "setActiveOrderTab",
    ADD_NEW_COMMENT = "addNewComment",
    SET_ORDER_EDITING = "setOrderEditing",
    ADD_NEW_ORDER = "addNewOrder"
  }