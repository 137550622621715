export const parseOrders = (orders: Array<any>) => {
  return orders.map((order: any) => {
    const data = {
      id: order.id,
      year: order.year,
      yearString: order.yearString,
      status: order.status,
      creatorUserId: order.creatorUserId,
      creatorNip: order.creatorNip,
      creatorUser: order.creatorUser,
      bdo: order.bdo,
      creationTime: order.creationTime,
      fields: order.fields,
      company: order.company,
    };

    return data;
  });
};
