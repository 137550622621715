export const postalCodes = [
  "0_-___",
  "1_-___",
  "2_-___",
  "3_-___",
  "4_-___",
  "5_-___",
  "6_-___",
  "7_-___",
  "8_-___",
  "9_-___",
];
