import * as React from "react";
import { AzureMap, AzureMapDataSourceProvider, AzureMapLayerProvider, AzureMapPopup, AzureMapsProvider, IAzureMapOptions } from "react-azure-maps";
import { AuthenticationType, data } from 'azure-maps-control'
import { useState } from "react";
import { PointList } from "./PointList";
import { wrapper } from "../../auth/styles/activityIndicatorContainer.styles";
import { IContainerPoint } from "../intefaces/IContainerPoint";
import { ContainerPopupHtml } from "./ContainerPopupHtml";
import { IReceptionPlacePoint } from "../intefaces/IReceptionPlacePoint";
import { RootStateOrAny, useSelector } from "react-redux";
import { PointTypeEnum } from "../enums/PointTypeEnum";
import { ReceptionPlacesPopupHtml } from "./ReceptionPlacePopupHtml";
import { IInteralStorePoint } from "../intefaces/IInternalStorePoint";
import { InteralStorePopupHtml } from "./InternalStorePopupHtml";

const { REACT_APP_AZURE_MAP_KEY } = (window as any)._env_;

const option: IAzureMapOptions = {
    authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: REACT_APP_AZURE_MAP_KEY
    },
    center: [19.4803, 52.0693],
    zoom: 5.7,
    //view: 'Auto',
}
export interface StoreMapMainProps {
    receptionPlacePoints: IReceptionPlacePoint[];
    containerPoints: IContainerPoint[];
    internalStorePoints: IInteralStorePoint[];
}

export const StoreMapMain: React.FC<StoreMapMainProps> = ({ receptionPlacePoints, containerPoints, internalStorePoints }) => {

    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [popupPosition, setPopupPosition] = useState<data.Position>(new data.Position(
        0,
        0));
    const [html, setHtml] = useState<any>(<></>);
    const [pointType, setPointType] = useState<Number>(0);

    const { showReceptionPlacePoints, showContainerPoints, showInternalStorePoints } = useSelector((state: RootStateOrAny) => state.storeMap);

    const createDataPosition = (longitude: number, latitude: number) => {
        return new data.Position(
            longitude,
            latitude)
    }

    React.useEffect(() => {
        if (showReceptionPlacePoints == false && pointType == PointTypeEnum.RECEPTION_PLACE)
            setPopupVisible(false);
        if (showContainerPoints == false && pointType == PointTypeEnum.CONTAINER)
            setPopupVisible(false);
        if(showInternalStorePoints == false && pointType == PointTypeEnum.INTERNAL_STORE)
            setPopupVisible(false);
    }, [showReceptionPlacePoints, showContainerPoints, showInternalStorePoints])

    function getCoordinates(e: any) {
        if (e.shapes && e.shapes.length > 0) {
            const prop = e.shapes[0];

            if (prop.properties.y == PointTypeEnum.RECEPTION_PLACE) {
                var receptionPlacePoint: IReceptionPlacePoint | undefined = receptionPlacePoints.find(x => x.id == prop.id);
                if (receptionPlacePoint == undefined)
                    return;
                setPointType(PointTypeEnum.RECEPTION_PLACE);
                setPopupVisible(true);
                setPopupPosition(createDataPosition(receptionPlacePoint.longitude, receptionPlacePoint.latitude));
                setHtml(<ReceptionPlacesPopupHtml receptionPlacePoint={receptionPlacePoint} />)
            }
            else if (prop.properties.y == PointTypeEnum.CONTAINER) {
                var containnerData: IContainerPoint | undefined = containerPoints.find(x => x.id == prop.id);
                if (containnerData == undefined)
                    return;
                setPointType(PointTypeEnum.CONTAINER);
                setPopupVisible(true);
                setHtml(<ContainerPopupHtml containnerData={containnerData} />)
                setPopupPosition(createDataPosition(
                    containnerData.longitude,
                    containnerData.latitude)
                );
            }
            else if (prop.properties.y == PointTypeEnum.INTERNAL_STORE) {
                var interalStoreData: IInteralStorePoint | undefined = internalStorePoints.find(x => x.id == prop.id);
                if (interalStoreData == undefined)
                    return;
                setPointType(PointTypeEnum.INTERNAL_STORE);
                setPopupVisible(true);
                setHtml(<InteralStorePopupHtml interalStorePopupHtmlProps={interalStoreData} />);
                setPopupPosition(createDataPosition(
                    interalStoreData.longitude,
                    interalStoreData.latitude)
                );
            }
        }
    }

    const memoizedContainerPoints = React.useMemo(
        () => (
            <PointList points={containerPoints} />
        ),
        [containerPoints],
    );

    const memoizedReceptionPlacePoints = React.useMemo(
        () => (
            <PointList points={receptionPlacePoints} />
        ),
        [receptionPlacePoints],
    );

    const memomizedInternalStorePoints = React.useMemo(
        () => (
            <PointList points={internalStorePoints} />
        ),
        [internalStorePoints]
    )

    const preparePopupOptions = (pointPosition: any) => {
        var options = {
            position: pointPosition,
            pixelOffset: [0, -5]
        }
        return options;
    }

    const memoizedMapPopup = React.useMemo(
        () => (
            <AzureMapPopup
                isVisible={popupVisible}
                options={preparePopupOptions(popupPosition)}
                popupContent={html}
            />
        ),
        [popupVisible, popupPosition, html],
    );

    return (
        <div className={wrapper}>
            <div style={{
                height: '84vh',
                paddingRight: '3vh',
                paddingLeft: '3vh',
                paddingTop: '3vh'
            }}>
                <AzureMapsProvider>
                    <AzureMap options={option}>
                        <AzureMapDataSourceProvider id={'DataSource Provider'}>
                            <AzureMapLayerProvider
                                options={{
                                    radius: 5,
                                    strokeColor: "#0066CC",
                                    strokeWidth: 6,
                                    color: "#0066CC",
                                    visible: showReceptionPlacePoints
                                }}
                                id={'shape AzureMapLayerProvider'}
                                type={'BubbleLayer'}
                                events={{
                                    click: getCoordinates,
                                }}
                            />
                            {memoizedReceptionPlacePoints}
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider id={'DataSource Provider1'}>
                            <AzureMapLayerProvider
                                options={{
                                    radius: 5,
                                    strokeColor: "#257F09",
                                    strokeWidth: 6,
                                    color: "#257F09",
                                    visible: showContainerPoints
                                }}
                                id={'shape AzureMapLayerProvider1'}
                                type={'BubbleLayer'}
                                events={{
                                    click: getCoordinates,
                                }}
                            />
                            {memoizedContainerPoints}
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider id={'DataSource Provider2'}>
                            <AzureMapLayerProvider
                                options={{
                                    radius: 11,
                                    color: "#4f1509",
                                    strokeWidth: 0,
                                    visible: showInternalStorePoints
                                }}
                                id={'shape AzureMapLayerProvider2'}
                                type={'BubbleLayer'}
                                events={{
                                    click: getCoordinates,
                                }}
                            />
                            {memomizedInternalStorePoints}
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider id={'DataSource Provider12'}>
                            <AzureMapLayerProvider
                                id={'shape AzureMapLayerProvider11'}
                                type={"SymbolLayer"} />
                            {memoizedMapPopup}
                        </AzureMapDataSourceProvider>
                    </AzureMap>
                </AzureMapsProvider>
            </div>
        </div>
    );
}

export default StoreMapMain

//https://azure.github.io/react-azure-maps/index.html
//https://docs.microsoft.com/en-us/azure/azure-maps/how-to-manage-authentication
