import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import NewFormField from '../../common/layout/form/NewFormField';
import FormDropDown from '../../common/layout/form/FormDropDown';
import ClientsDropDown from '../../orders/components/forms/newOrder/ClientsDropDown';
import { margin } from '../../clients/styles/panelForm.styles';
import ActivityIndicator from '../../common/layout/ActivityIndicator';
import { useCancelFrom } from '../../clients/hooks/useCancelForm';
import ErrorMessage from '../../common/layout/form/ErrorMessage';
import FormDropDownReception from '../../common/layout/form/FormDropDownReception';
import NewFormBtns from '../../common/layout/form/NewFormBtns';
import { useCompanyData } from '../../orders/hooks/useCompanyData';
import { DocumentTypeEnum } from '../models/DocumentTypeEnums';
import FormDropDownInternalStore from '../../common/layout/form/FormDropDownInternalStore';
import { getInternalStoreItems } from '../../../connectors/store/connectors';
import { useStoreDocsAddFile } from '../hooks/useStoreDocsAddFile';

const StoreDocsAddForm = () => {
    const {
        arrangementFileHandler,
        handleSubmit,
        isLoading
    } = useStoreDocsAddFile();

    const [documentType, setDocumentType] = useState<any>({ key: -1 });
    const [company, setCompany] = useState<any>({ key: "" });
    const [department, setDepartment] = useState<any>({ key: -1 });
    const [reception, setReception] = useState<any>();
    const [internalStore, setInternalStore] = useState<any>();
    const [internalStoreOptions, setInternalStoreOptions] = useState<any[]>([]);

    const {
        departments,
        receptionPlaces,
    } = useCompanyData(company, department, reception);

    const cancel = useCancelFrom();

    if (company.data && company.data.multiDepartment === false) {
        if (departments[0] && (department.key === 0 || department.key !== departments[0]['key'])) {
            setDepartment(departments[0]);
        }
    }

    useEffect(() => {
        (async () => {
            const res = await getInternalStoreItems();
            const options = res.data.result.map((option: any) => {
                // let msg = !option.isActive ? '(Nieaktywny)' : ''
                return {
                    key: option.storeId,
                    text: `${option.name}`,
                    // orderTypeId: option.orderTypeId,
                    // disabled: !option.isActive
                };
            });
    
            setInternalStoreOptions(options);
        })();
    }, []);

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={{}}
        >
            {({ handleSubmit, setFieldValue }) => (
                <Form translate="yes" onSubmit={handleSubmit}>
                    <FormDropDown
                        headerText="Rodzaj dokumentu"
                        name="documentType"
                        options={[
                            { key: DocumentTypeEnum.CONTRACT, text: "Umowa" },
                            { key: DocumentTypeEnum.ACCEPTANCE_REPORT, text: "PO - Protokół odbioru" },
                            { key: DocumentTypeEnum.HANDOVER_PROTOCOL, text: "PP - Protokół przekazania" },
                            { key: DocumentTypeEnum.EXTERNAL_RECEPTION, text: "PZ - przyjęcie zewnętrzne (zakup)" },
                            { key: DocumentTypeEnum.DESTRUCTION_PROTOCOL, text: "Protokół zniszczenia" },                    
                            { key: DocumentTypeEnum.INVENTORY, text: "Inwentura" }, 
                            { key: DocumentTypeEnum.MM_MINUS, text: "Między magazynami minus"},
                            { key: DocumentTypeEnum.MM_PLUS, text: "Między magazynami plus"},                            
                        ]}
                        onChange={(value: any) => setDocumentType({ key: value.key })}
                    />

                    <NewFormField name="description" label="Numer dokumentu" />
                    
                    {(documentType.key === DocumentTypeEnum.CONTRACT ||
                    documentType.key === DocumentTypeEnum.HANDOVER_PROTOCOL ||
                    documentType.key === DocumentTypeEnum.ACCEPTANCE_REPORT) && 
                        <>
                            <ClientsDropDown
                                name="company"
                                label="Klient"
                                setFieldValue={setFieldValue}
                                // resetForm={resetForm}
                                resetForm={() => { }}
                                value={company.key}
                                onChange={setCompany}
                            />

                            {company.data && company.data.multiDepartment ?
                                <FormDropDown
                                    name="department"
                                    headerText="Oddział"
                                    options={departments}
                                    onChange={setDepartment}
                                />
                                //: <OrderDetail title={company.data ? 'Oddział' : ''} value={departments[0] && departments[0].text}>  </OrderDetail>
                            : null}

                            <FormDropDownReception
                                name="receptionPlace"
                                headerText="Punkt Odbioru"
                                options={receptionPlaces}
                                orderTypeItemId={0}
                                setReception={setReception}
                                // onChange={changePrice}
                            />
                        </>
                    }

                    {documentType.key >= 0 && documentType.key !== DocumentTypeEnum.CONTRACT && 
                        <FormDropDownInternalStore
                            name="internalStorePlace"
                            headerText="Magazyn wewnętrzny"
                            options={internalStoreOptions}
                            orderTypeItemId={0}
                            setInternalStore={setInternalStore}
                        />
                    }

                    {documentType.key >= 0 && documentType.key !== DocumentTypeEnum.CONTRACT && 
                        <NewFormField name="containersNumber" label="Ilość" type="number" min="0" />
                    }

                    <NewFormField name="documentDate" label="Data dokumentu" type="date" />
                    <input type="file" name="file" onChange={arrangementFileHandler} />
                    <br />

                    <NewFormBtns
                        positionAbsolute={true}
                        submitBtnText="Dodaj"
                        cancelForm={cancel}
                        disabled={isLoading}
                        disabledOnlySubmitButton={documentType.key === -1} 
                    />

                    <div className={margin}>
                        {isLoading ? <ActivityIndicator /> : <ErrorMessage />}
                    </div>
                </Form>
            )}
        </Formik>
    )
};

export default StoreDocsAddForm;