export const internalStoreColumns = [
    {
        key: "storeName",
        name: "Nazwa Magazynu",
        fieldName: "storeName",
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
    },
    {
        key: "containersAmount",
        name: "Ilość kontenerów",
        fieldName: "containersAmount",
        minWidth: 350,
        maxWidth: 500,
        isResizable: true,
    }
];