import { IActiveActions} from "../../../models/action.types";
import { Quantity, IQuantity } from "../../../../common/models/quantity.model";

export const generateActivecommands = (
    quantity: IQuantity,
    actions: IActiveActions
  ) => {

    const items = [{
      key: "deactivate",
      text: "Deaktywuj",
      iconProps: { iconName: "peopleAlert" },
      name: "deactivate",
      onClick: actions.deactivate,
    },
  ]
    if (quantity === Quantity.NONE) {
      return [];
    }else if(quantity === Quantity.ONE){
      return [
        {
        key: "see",
        text: "Zobacz",
        iconProps: { iconName: "textDocument" },
        name: "see",
        onClick: actions.see,
        },
        {
          key: "edit",
          text: "Edytuj dostępne lata",
          iconProps: { iconName: "edit" },
          name: "edit",
          onClick: actions.edit,
        },
        ...items,
      ];
  
    } else {
      return [
        ...items,
      ];
    }
  };