import { setListMessage } from "../../config/redux/alerts/alertsActions";
import {
  reloadOrders,
  setSelectedListItem,
  toggleOrderPanel,
} from "../../config/redux/client/clientActions";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { generateItems } from "./generateClientOrderPanelCommandBar";
import {
  toggleOrderAccept,
  addOrderComment,
  toggleCancelReason,
} from "../../config/redux/orders/ordersActions";
import {
  acceptRaport,
  getRaportPDF,
  setToRealization,
} from "../../connectors/orders/connectors/index";
import { setOrderEditing } from "../../config/redux/orders/ordersPanel/orderPanelActions";
import { Quantity } from "../../features/common/models/quantity.model";
import { OrderPanelActions } from "../../config/redux/orders/ordersPanel/orderPanel.enum";

export const useClientOrderPanelCommandBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state: RootStateOrAny) => state.client);
  const { activeTab, editing } = useSelector(
    (state: RootStateOrAny) => state.orderPanel
  );
  const { selectedItem } = state;
  // console.log(selectedItem, "selectedItem");
  // console.log(selectedItem.status, "status")

  const canEdit = () => {
    try {
      if (selectedItem === undefined) {
        return false;
      } else if (selectedItem.status === 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };
  canEdit();
  // editing = canEdit();
  // console.log(editing, "stateEditing");
  // console.log(canEdit(), "canEdit");

  const download = async () => {
    await getRaportPDF(selectedItem.id);
  };

  const send = async () => {
    try {
      await setToRealization(selectedItem.id);
      dispatch(reloadOrders(true));
    } catch (err) {
      console.error(err);
    }
  };

  const edit = () => {
    try {
      dispatch(
        toggleOrderPanel({ isOpen: true, formActive: true, editOrder: true })
      );
      dispatch(setSelectedListItem(selectedItem));
    } catch (err) {
      console.error(err);
    }
  };

  const actions = {
    download,
    edit,
    send,
    canEdit,
  };
  const items = selectedItem
    ? generateItems(state, location.pathname, actions, activeTab, editing)
    : [];

  return {
    items,
  };
};
