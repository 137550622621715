import { clearAlerts } from './../../../config/redux/alerts/alertsActions';
import {useState} from "react"
import { FormikValues } from "formik";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import {
  setRegistrationComplete,
  clearCompanyData
} from "../../../config/redux/register/registerActions";
import { registerUser } from "../../../connectors/auth/connectors/index";
import { Errors } from "../enums/errors.enum";

import { IResetForm } from "../../common/models/resetForm.type";
import { setErrorAlert, setSuccessAlert } from "../../../config/redux/alerts/alertsActions";


export const useRegisterUser = () => {
    const [isLoading, setIsLoading] = useState(false)
    const {
        data: { departments, multiDepartment },
      } = useSelector((state: RootStateOrAny) => state.register.company);
      const dispatch = useDispatch();
    
      const handleSubmit = async (
        values: FormikValues,
        { resetForm }: IResetForm
      ) => {
        setIsLoading(true)
        try {
          dispatch(clearAlerts())

          const body = {
            email: values.email,
            // password: values.pass,
            // passwordConfirm: values.pass2,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phoneNumber,
          };
    
          const department = multiDepartment
            ? values.department
            : departments[0].id;
          await registerUser(body, department);
          dispatch(setRegistrationComplete());
          dispatch(setSuccessAlert("Rejestracja przebiegła pomyślnie"))
          resetForm();
        } catch (err:any) {
          if (err.response.data.Errors[0]) {
            dispatch(setErrorAlert(Errors.USER_ALREDY_EXISTS));
          }
        }
        setIsLoading(false)
      };
    
      const cancelForm = () => {
        dispatch(clearCompanyData());
      };


      return{
          cancelForm,
          handleSubmit,
          multiDepartment,
          isLoading
      }
}