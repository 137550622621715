import api from "../../../config/axios/configAxios";
import queryString from "query-string";
import { IVehicle } from "../models/index";

export const getVehicles = async (driverNameOrRegistrationNumber?: string) =>
    api.get(`/api/Admin/Vehicles${!!driverNameOrRegistrationNumber ? '?DriverNameOrRegistrationNumber=' + driverNameOrRegistrationNumber : ''}`);

export const getVehiclesListbox = async (params: any) =>
    api.get(`/api/Admin/Vehicles/listbox?${queryString.stringify(params)}`);

export const saveVehicle = async (data: IVehicle) =>
    api.post(`/api/Admin/Vehicles`, data);

export const edditVehicle = async (data: IVehicle, vehicleId: string) =>
    api.put(`/api/Admin/Vehicles/${vehicleId}`, data);

export const removeVehicle = async (vehicleId: string) =>
    api.delete(`/api/Admin/Vehicles/${vehicleId}`);

export const getMatchedVehicles = async(query?: string)=> 
    api.get(`/api/Admin/Vehicles/matched-vehicles${!!query ? query: ''}`)