import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { reloadOrders, setSelectedListItem, toggleOrderPanel, toggleOrdersReportPanel } from "../../config/redux/client/clientActions";
import {acceptRaport, cancelOrder, getRaportPDF, setToRealization} from "../../connectors/orders/connectors";
import { Quantity } from "../../features/common/models/quantity.model";

export const useOrdersCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedItem } = useSelector((state: RootStateOrAny) => state.client);
  const quantity = selectedItem ? Quantity.ONE : Quantity.NONE;

  const canEdit = () => {
    if(selectedItem === undefined) return false;
    if(selectedItem.status === 0) return true;
    return false;

  }
  const openForm = () => {
    dispatch(toggleOrderPanel({isOpen: true, formActive: true, editOrder: false}));
  };
  const download = async () => {
    await getRaportPDF(selectedItem.id)
  }

  const send = async () => {
    await setToRealization(selectedItem.id);
    dispatch(reloadOrders(true));
  }

  const openFormReport = () => {
    dispatch(toggleOrdersReportPanel({isOpen: true}));
  };

  const openDisplay = () => {
    dispatch(toggleOrderPanel({isOpen: true, formActive: false, editOrder: false}));
  }

  const edit = () => {
    dispatch(toggleOrderPanel({isOpen: true, formActive: true, editOrder: true}));
    dispatch(setSelectedListItem(selectedItem))
  }

  const cancelOrderByUser = async() =>  {
    await cancelOrder(selectedItem ? selectedItem.key : " Anulowane przez użytkownika ");
    dispatch(reloadOrders(true));
    dispatch(setSelectedListItem(""))
  }

  const generateItems = () => {
    switch (quantity) {
      case Quantity.NONE:
        return [
          {
            key: "add",
            text: "Dodaj",
            iconProps: { iconName: "add" },
            name: "add",
            onClick: openForm,
          },
        ];
      case Quantity.ONE:
        if(canEdit()){
          return [
            {
              key: "see",
              text: "Zobacz",
              iconProps: { iconName: "textDocument" },
              name: "see",
              onClick: openDisplay,
            },
            {
              key: "edit",
              text: "Edytuj",
              iconProps: { iconName: "edit" },
              onClick: edit
            },
            {
              key: "report",
              text: "Pobierz",
              iconProps: { iconName: "ReportDocument" },
              name: "report",
              onClick: download,
            },
            {
              key: "send",
              text: "Złóż raport",
              iconProps: { iconName: "Send" },
              name: "send",
              onClick: send,
            }
          ];
        }
        else{
          return [
            {
              key: "see",
              text: "Zobacz",
              iconProps: { iconName: "textDocument" },
              name: "see",
              onClick: openDisplay,
            },
            {
              key: "report",
              text: "Pobierz",
              iconProps: { iconName: "ReportDocument" },
              name: "report",
              onClick: download,
            },
          ]
        }
      default:
        return [];
    }
  };

  const items = generateItems()

  return{
    items
  }
};
