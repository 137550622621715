import { clearAlerts } from './../../../config/redux/alerts/alertsActions';
import {useState} from "react"
import { FormikValues } from "formik";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { registerDepartment } from "../../../connectors/auth/connectors/index";
import { transformData } from "../actions/transformRegisterData";
import {
  setRegistrationComplete,
  setRegisterErr, clearCompanyData
} from "../../../config/redux/register/registerActions";
import { Errors } from "../enums/errors.enum";
import { IResetForm } from "../../common/models/resetForm.type";
import { setErrorAlert, setSuccessAlert } from "../../../config/redux/alerts/alertsActions";


export const useRegisterDepartment = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const {
      data: { taxNumber },
    } = useSelector((state: RootStateOrAny) => state.register.company);
  
    const handleSubmit = async (
      values: FormikValues,
      { resetForm }: IResetForm 
    ) => {
      setIsLoading(true)
      try {
        dispatch(clearAlerts())
        const { department, user } = transformData(values);
        const data = {department, contactPerson: user };
        await registerDepartment(data, taxNumber);
        dispatch(setRegisterErr(""));
        dispatch(setRegistrationComplete());
        dispatch(setSuccessAlert("Rejestracja przebiegła pomyślnie"))
        resetForm();
      } catch (err) {
        if (err.response.data.Errors[0]) {
          dispatch(setErrorAlert(Errors.USER_ALREDY_EXISTS));
        }
      }
      setIsLoading(false)
    };
  
    const cancelForm = () => {
      dispatch(clearCompanyData());
    };

    return {
        cancelForm,
        handleSubmit,
        isLoading
    }
}