import * as React from "react";

import DriversList from "../components/DriversList";
import DriverPanel from "../components/DriverPanel";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import { mergeStyles } from "@fluentui/react/";

const padding = mergeStyles({
  paddingTop: 15
})

const wrapper = mergeStyles({
  width: "95%",
  margin: "0px auto",
});


const Drivers: React.FC = () => {
  return (
    <div className={wrapper}>
      <div className={padding}> 
        <ListSuccessMessage/>
      </div>
      <DriversList />
      <DriverPanel />
    </div>
  );
};

export default Drivers;
