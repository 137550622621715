export const paragraph1 =
  "Stawka za odbiór opon ponadgabarytowych (przemysłowych, pneumatycznych, kół pełnych, rolniczych) wynosi od 800 zł netto/Mg";

export const paragraph2 = "Odbiór zużytych opon osobowych, ciężarowych i od aut dostawczych od PSZOK-ów, RIPOK-ów, Gmin oraz Zakładów Zagospodarowania Odpadów ustalany jest indywidualnie. Stawka za odbiór uzależniona jest od ilości opon oraz lokalizacji podmiotu zgłaszającego odbiór. Warunki odbioru opon ustalić należy z Marcinem Mulkowskim- 667-955-563"

export const paragraphs1 = [
  "Dostawa zużytych opon osobowych i ciężarowych przez klienta we własnym zakresie do punktów Recykl S.A możliwa jest po wcześniejszym kontakcie telefonicznym, w celu ustalenia warunków dostawy:",
  "Śrem 63-100, ul. Letnia 3 (województwo wielkopolskie) – 665-995-315",
  "Krosno Odrzańskie 66-600, ul. Gubińska 40, (województwo lubuskie) – 667-750-140",
  "Chełm 22-100, ul. Fabryczna 6 (województwo lubelskie) – 667-750-624",
];

export const paragraph3 = "Opony ponadgabarytowe (przemysłowe, pneumatyczne i koła pełne, rolnicze) przyjmowane są w stawce od 800 zł netto/Mg"

export const paragraph4 = "Warunki dostawy zużytych opon PSZOK-ów, RIPOK-ów, Gmin oraz Zakładów Zagospodarowania Odpadów ustalić należy z Marcinem Mulkowskim- 667-955-563"

export const paragraph5 = "WAŻNE: Odbiór Odpadów przez Grupę Recykl realizowany będzie na podstawie zgłoszenia przez Przekazującego dokonanego w formie Zlecenia w systemie informatycznym do zgłaszania odbioru zużytych opon pod adresem www.sodo.recykl.pl (SODO)"