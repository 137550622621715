import React from "react";
import { Stack, StackItem } from "@fluentui/react/";
import { Formik, Form } from "formik";
import {
    containerS,
} from "../../../../styles/registerCompany.styles";
import { useRegistration } from "../../../../hooks/useRegistration";
import { validationSchemaCompany, validationSchemaCompanyNotRequired } from "../../../../actions/validationSchema";
import InputSection from "./InputSection";
import RadioBtn from "../../../../../common/layout/form/Radio";
import FormBtns from "../../../../../common/layout/form/NewFormBtns";
import MessageContainer from "../MessageContainer";
import ActivityIndicator from "../../../../../common/layout/ActivityIndicator";

const RegisterCompany: React.FC = () => {
    const { register, initValues, isLoading, cancelForm } = useRegistration();

    return (
        <Formik
            initialValues={initValues}
            onSubmit={register}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            validationSchema={initValues && !!initValues.taxNumber ? validationSchemaCompany : validationSchemaCompanyNotRequired}
        >
            {({ handleSubmit }) => (
                <Form translate="yes" onSubmit={handleSubmit}>
                    <Stack verticalFill styles={containerS}>
                        <InputSection requireCompanyData={initValues && !!initValues.taxNumber} />
                        <StackItem align="start">
                            {initValues && initValues.taxNumber ?  (
                            <div>
                                <RadioBtn
                                    name="multiDepartment"
                                    label="Firma wielooddziałowa"
                                    options={[
                                        { key: "yes", text: "Tak" },
                                        { key: "no", text: "Nie" },
                                    ]}
                                />
                            </div>
                                ): null}
                            {/* <div className={margin}>
                <RegulationsAccept name="accept"/>
              </div> */}
                            {isLoading ? <ActivityIndicator /> : <MessageContainer />}
                            <FormBtns
                                cancelForm={cancelForm}
                                submitBtnText="Zarejestruj"
                                disabled={isLoading}
                            />
                        </StackItem>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default RegisterCompany;