import {
  IRealizationDate,
  ISelectedOrders,
  IRemove,
  IPostalCode,
  IActiveOrder,
  IVehicle
} from "./ordersActions.types";
import { OrdersActions } from "./ordersActions.enum";

export const setActiveOrder = (data: IActiveOrder) => {
  return {
    type: OrdersActions.SET_ACTIVE_ORDER,
    payload: data
  }
}

export const setPostalCode = (data: IPostalCode) => {
  return {
    type: OrdersActions.SET_POSTAL_CODE,
    payload: data,
  };
};

export const setSelectedOrders = (data: ISelectedOrders) => {
  return {
    type: OrdersActions.SET_ORDERS,
    payload: data,
  };
};

export const clearOrdersData = () => {
  return {
    type: OrdersActions.CLEAR_ORDERS_DATA,
  };
};

export const removeData = (data: IRemove) => {
  return {
    type: OrdersActions.REMOVE_DATA,
    payload: data,
  };
};

export const setRealizationDate = (data: IRealizationDate) => {
  return {
    type: OrdersActions.SET_REALIZATION_DATE,
    payload: data,
  };
};

export const reloadOrders = (data: boolean) => {
  return {
    type: OrdersActions.RELOAD,
    payload: data
  }
}

export const setVehicle = (data: IVehicle) => {
  return {
    type: OrdersActions.SET_VEHICLE,
    payload: data,
  };
};

export const addOrderComment = (addComment: boolean) => {
  return {
    type: OrdersActions.ADD_ORDER_COMMENT,
    payload: addComment
  }
}

export const toggleOrderAccept = (orderAccepted: boolean) => {
  return {
    type: OrdersActions.ACCEPT_ORDER,
    payload: orderAccepted
  }
}

export const toggleOrderCancel = (orderCanceled: boolean) => {
  return{
    type: OrdersActions.CANCEL_ORDER,
    payload: orderCanceled
  }
}

export const toggleCancelReason = (isOpen: boolean) => {
  return {
    type: OrdersActions.TOGGLE_CANCEL_REASON,
    payload: isOpen
  }
}

export const togglePrintingLoading = (loading: boolean) => {
  return{
    type: OrdersActions.TOGGLE_PRINITG_LOADING,
    payload: loading
  }
}