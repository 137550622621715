import React from "react";
import ReactDOM from "react-dom";
import {App} from "./App";
import authentication from 'react-azure-b2c';
import {getTheme, initializeIcons, mergeStyles, ThemeProvider} from "@fluentui/react";
import {myTheme} from "./client/styles/layout/theme";

const theme = getTheme();

// global styles
mergeStyles({
    selectors: {
        ":global(body), :global(html), :global(#root)": {
            margin: 0,
            padding: 0,
            fontFamily: "Segoe UI, sans-serif",
        },
        ":global(*) :global(*::after) :global(*::before)": {
            boxSizing: "border-box",
        },
        ":global(body)": {
            color: theme.palette.neutralDark,
        },
    },
});

const {
    REACT_APP_INSTANCE,
    REACT_APP_TENANT,
    REACT_APP_SIGN_IN_POLICY_INTERNAL,
    REACT_APP_SIGN_IN_POLICY_CLIENTS,
    REACT_APP_CLIENT_ID,
    REACT_APP_CACHE_LOCATION,
    REACT_APP_SCOPES,
    REACT_APP_REDIRECT_URL_ADMIN,
    REACT_APP_REDIRECT_URL_CLIENT,
    REACT_APP_BASE_URL,
} = (window as any)._env_;

let isInternal = false;
if (process.env.NODE_ENV === 'development') {
    isInternal = window.location.port === '3000';
} else {
    isInternal = window.location.hostname.includes('admin');
}
// isInternal = window.location.port === "3001";

authentication.initialize({
    instance: REACT_APP_INSTANCE,
    tenant: REACT_APP_TENANT,
    signInPolicy: isInternal ? REACT_APP_SIGN_IN_POLICY_INTERNAL : REACT_APP_SIGN_IN_POLICY_CLIENTS,
    clientId: REACT_APP_CLIENT_ID,
    cacheLocation: REACT_APP_CACHE_LOCATION,
    scopes: [REACT_APP_SCOPES],
    redirectUri: isInternal ? REACT_APP_REDIRECT_URL_ADMIN : REACT_APP_REDIRECT_URL_CLIENT,
    postLogoutRedirectUri: isInternal ? 'https://login.microsoftonline.com/common/oauth2/logout' : window.location.origin,
});

sessionStorage.removeItem("role");
sessionStorage.removeItem("isActivated");

initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');

const containerS = mergeStyles({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
})

const hS = mergeStyles({
    color: myTheme.palette.themePrimary,
});

if (window.location.pathname.includes('/activate')) {
    let urlar = window.location.pathname.split('/');
    let token = urlar[urlar.length - 1];

    fetch(`${REACT_APP_BASE_URL}/api/services/app/UserActivation/Activate?token=${token}`, {method: 'POST'}).then(res => {
        console.log(res);
    })
    ReactDOM.render(
        <ThemeProvider theme={myTheme}>
            <div className={containerS}>
                <h1 className={hS}>Konto zostało aktywowane</h1>
                <p>Użytkownik został powiadomiony o aktywacji jego konta, i może teraz zalogować się do serwisu</p>
            </div>
        </ThemeProvider>,
        document.getElementById("root")
    );
} else {
    authentication.run(() => {
        ReactDOM.render(
            <ThemeProvider theme={myTheme}>
                <App/>
            </ThemeProvider>,
            document.getElementById("root")
        );
    });
}


