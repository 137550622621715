import { mergeStyles } from "@fluentui/react/";
import React from "react";

export interface PricingTableProps {
  data: Array<any>;
}

const tableS = mergeStyles({
  border: "1px solid black",
  borderCollapse: "collapse",
  maxWidth: 400,
  marginBottom: 20,
  marginTop: 20,
  fontWeight: "500",

  selectors: {
    ["th, td"]: {
      border: "2px solid black",
      borderCollapse: "collapse",
    },

    ["th"]: {
      backgroundColor: "#DAE1F3",
      color: "#3A4E73",
      padding: 5,
      minWidth: 150
    },


    ["td"]: {
      textAlign: "center",
      padding: 3
    },

    ["caption"]: {
      padding: "10px",
    },
  },
});

const PricingTable: React.FC<PricingTableProps> = ({ data }) => {
  return (
    <table className={tableS}>
      <thead>
        <tr>
          <th scope="col">Kod pocztowy</th>
          <th scope="col">Auto</th>
          <th scope="col">Cena[zł netto]</th>
        </tr>
      </thead>
      <tbody>
         {data.map((item: any) => {
          return (
            <React.Fragment key={item.postalCodePattern}>
              {item.items.map((el: any, index: number) => (
                <tr key={index}>
                  {index === 0 && <td scope="rowgroup" rowSpan={item.items.length}>{item.postalCodePattern}</td>}
                  <td scope="row">{el.orderTypeItem}</td>
                  <td>{el.value}</td>
                </tr>
              ))}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default PricingTable;
