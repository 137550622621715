import React from 'react';
import {
  CommandBar,
} from "@fluentui/react/";

import {useClientOrderPanelCommandBar}  from '../../hooks/useClientOrderPanelCommandBar';

const commandBarS = {
  root: {
    width: 500,
  },
};

const ClientOrderPanelCommandBar: React.FC = () => {
  const {items} = useClientOrderPanelCommandBar()

  return (
      <CommandBar items={items} styles={commandBarS}/>
  );
}

export default ClientOrderPanelCommandBar;
