import { mergeStyles } from '@fluentui/react/';
import { NeutralColors } from '@fluentui/react/';
import { ITextStyles } from "@fluentui/react//lib/Text";
import { FontSizes } from "@fluentui/react/";


export const textContainerS = mergeStyles({
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10
})

export const titleS: ITextStyles = {
    root: {
        fontSize: FontSizes.size16,
        fontWeight: "600",
        marginBottom: 10,
    }
}

export const subTitleS: ITextStyles = {
    root: {
        fontSize: FontSizes.size16,
        color: NeutralColors.gray130
    }
}
