import React from "react";
import NewFormField from "../../../../../common/layout/form/NewFormField";

import { container, divider, fieldContainer} from "../../../../styles/fieldsContainer.styles";

const DepartmentsFields: React.FC = () => {
  return (
    <div className={container}>
      <div className={divider}>
        <div className={fieldContainer}>
          <NewFormField name="city" label="Miejscowość" />
        </div>
        <div className={fieldContainer}>
          <NewFormField name="postalCode" label="Kod pocztowy" />
        </div>
      </div>
      <NewFormField name="street" label="Ulica i numer" />
    </div>
  );
};

export default DepartmentsFields;
