import * as React from "react";
import {
  CommandBar,
} from "@fluentui/react";
import { comandBarS } from "../../styles/layout/commandBar.styles";
import { useOrdersCommandBar } from "../../hooks/useOrderCommandBar";

 
const ClientOrdersCommandBar: React.FC= () => {
    const {items} = useOrdersCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default ClientOrdersCommandBar;