import * as React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {NavigationPath} from "./NavigationPath";
import Auth from "../../pages/Auth";
import Home from "../../pages/Home";
//orders
import AllOrders from "../../features/orders/pages/AllOrders";
import NewOrders from "../../features/orders/pages/NewOrders";
import AcceptedOrders from "../../features/orders/pages/AcceptedOrders";
import InProgressOrders from "../../features/orders/pages/InProgressOrders";
import CanceledOrders from "../../features/orders/pages/CanceledOrders";
//clients
import UnverifiedClients from "../../features/clients/pages/UnverifiedClients";
import ActiveClients from "../../features/clients/pages/ActiveClients";
import InactiveClients from "../../features/clients/pages/InactiveClients";
import UnactiveUsers from "../../features/unactiveusers/pages/UnactiveUsers";
//config
import OrderTypes from "../../features/orderTypes/pages/OrderTypes";
import Vehicles from "../../features/vehicles/pages/Vehicles";
import Pricing from "../../features/Pricing/components/Pricing";
//layouts  routes
import {AdminLayoutRoute} from "./AdminLayoutRoute";
import Layout from "../../features/common/layout/Layout";
//commandBars
import OrdersComandBar from "../../features/commandBar/components/orders/OrdersCommandBar";
import ClientsCommandBar from "../../features/commandBar/components/clients/ClientsCommandBar";
import DriversCommandBar from "../../features/commandBar/components/settings/DriversCommandBar";
import OrderTypeCommandBar from "../../features/commandBar/components/settings/OrderTypeCommandBar";
import UnactiveUsersCommandBar from "../../features/commandBar/components/UnactiveUsersCommandBar";
import VehiclesCommandBar from "../../features/commandBar/components/settings/VehiclesCommandBar";
//nav
import ClientsNav from "../../features/nav/components/ClientsNav";
import OrdersNav from "../../features/nav/components/OrdersNav";
import Drivers from "../../features/drivers/pages/Drivers";
import PricingCommandBard from "../../features/commandBar/components/pricing/PricingCommandBar";
import Operators from "../../features/operators/components/Operators";
import OperatorsCommandBard from "../../features/commandBar/components/operators/OperatorsCommandBar";
import CancellationResons from "../../features/cancellationReasons/components/CancellationReasons";
import Admins from "../../features/admins_last/pages/Admins";
import CancellationReasonsCommandBar from "../../features/commandBar/components/settings/CancellationReasonsCommandBar";
import AdminsCommandBar from "../../features/commandBar/components/settings/AdminsCommandBar";
import NewPass from "../../pages/NewPass";
import {LayoutRoute} from "./LayoutRoute";
import Dashboard from "../../client/Pages/Dashboard";
import Inactive from "../../pages/Inactive";
import Unavailable from "../../pages/Unavailable";
import ActivityIndicator from "../../features/common/layout/ActivityIndicator";
import UnactivePickupPointsCommandBar from "../../features/commandBar/components/UnactivePickupPointsCommandBar";
import UnactivePickupPoints from "../../features/unactivepickkupoints/pages/UnactivePickupPoints";
import {useUserInfo} from "../../features/common/hooks/useUserInfo";
import ReportsCommandBar from "../../features/reports/commandBar/ReportsCommandBar";
import Store from "../../features/store/components/Store";
import StoreCommandBar from "../../features/store/commandBar/StoreCommandBar";
import StoreDocs from "../../features/storeDocs/components/StoreDocs";
import StoreDocsCommandBar from "../../features/storeDocs/commandBar/StoreDocsCommandBar";
import StoreMapCommandBar from "../../features/storeMap/commandBar/StoreMapCommandBar";
import {StoreMap} from "../../features/storeMap/components/StoreMap";
import InternalStore from "../../features/internalStore/components/InternalStore";
import InternalStoreCommandBar from "../../features/internalStore/commandBar/internalStoreCommandBar";
import Orders from "../../client/Pages/Orders";
import Reports from "../../features/reports/components/Reports";
import ClientOrdersCommandBar from "../../client/components/commandBar/ClientOrdersCommandBar";
import Activate from "../../pages/Activate";

const Routing: React.FC = () => {
    const {data, status, error} = useUserInfo();
    const fetchedData = data ? data.data.result : undefined
    const isActivated = fetchedData ? fetchedData.user.isActive : 'false';
    const role = fetchedData ? fetchedData.user.roles : '';
    const companyId = fetchedData ? fetchedData.companyId : '';
    const departmentId = fetchedData ? fetchedData.departmentId : '';
    sessionStorage.setItem('isActivated', isActivated);
    sessionStorage.setItem('role', role);
    sessionStorage.setItem('companyId', companyId);
    sessionStorage.setItem('departmentId', departmentId);

    if (status === "loading" && !error) {
        return <>
            <ActivityIndicator text={"Przetwarzanie"}/>
        </>
    }
    debugger;
    if (window.location.pathname.includes("/activate")) {
        return <Redirect to={{pathname: NavigationPath.ACTIVATE_ACCOUNT}}/>
    }

    if (error) return <Unavailable queryStatus={status}/>;


    return (
        <Switch>
            <AdminLayoutRoute
                exact
                path={NavigationPath.HOME}
                layout={Layout}
                component={Home}
            />

            //client
            <LayoutRoute
                exact
                path={NavigationPath.DASHBOARD}
                component={Dashboard}
            />
            <LayoutRoute
                exact
                path={NavigationPath.REPORTS}
                component={Orders}
                commandBar={ClientOrdersCommandBar}
            />

            //auth
            <Route path={NavigationPath.AUTH} component={Auth}/>
            <Route path={NavigationPath.NEW_PASS} component={NewPass}/>
            <Route path={NavigationPath.INACTIVE_ACCOUNT} component={Inactive}/>
            <Route path={NavigationPath.UNAVAILABLE} component={Unavailable}/>
            <Route exact path={NavigationPath.ACTIVATE_ACCOUNT} component={Activate}/>

            //Reports
            <AdminLayoutRoute
                component={AllOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.REPORTS_ALL}
            />
            <AdminLayoutRoute
                component={NewOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.REPORTS_ALL}
            />
            <AdminLayoutRoute
                component={AcceptedOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.REPORTS_APPROVED}
            />
            <AdminLayoutRoute
                component={InProgressOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.REPORTS_WAITING}
            />
            <AdminLayoutRoute
                component={CanceledOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.REPORTS_REJECTED}
            />

            //clients
            <AdminLayoutRoute
                component={UnverifiedClients}
                layout={Layout}
                commandBar={ClientsCommandBar}
                sideNav={ClientsNav}
                path={NavigationPath.UNVERIFIED_CLIENTS}
            />
            {/* <AdminLayoutRoute
        component={NewClients}
        layout={Layout}
        commandBar={ClientsCommandBar}
        sideNav={ClientsNav}
        path={NavigationPath.NEW_CLIENTS}
      /> */}
            <AdminLayoutRoute
                component={ActiveClients}
                layout={Layout}
                commandBar={ClientsCommandBar}
                sideNav={ClientsNav}
                path={NavigationPath.ACTIVE_CLIENTS}
            />
            <AdminLayoutRoute
                component={InactiveClients}
                layout={Layout}
                commandBar={ClientsCommandBar}
                sideNav={ClientsNav}
                path={NavigationPath.INACTIVE_CLIENTS}
            />

            <AdminLayoutRoute
                component={UnverifiedClients}
                layout={Layout}
                commandBar={UnverifiedClients}
                path={NavigationPath.UNVERIFIED_CLIENTS}
            />

            <AdminLayoutRoute
                component={UnactivePickupPoints}
                layout={Layout}
                commandBar={UnactivePickupPointsCommandBar}
                path={NavigationPath.UNACTIVE_PICKUP_POINTS}
            />

            //config
            <AdminLayoutRoute
                component={OrderTypes}
                layout={Layout}
                commandBar={OrderTypeCommandBar}
                path={NavigationPath.ORDER_TYPES}
            />
            <AdminLayoutRoute
                component={Vehicles}
                layout={Layout}
                commandBar={VehiclesCommandBar}
                path={NavigationPath.VEHICLES}
            />
            <AdminLayoutRoute
                component={Drivers}
                layout={Layout}
                commandBar={DriversCommandBar}
                path={NavigationPath.YEARS_MANAGEMENT}
            />
            <AdminLayoutRoute
                component={Pricing}
                layout={Layout}
                commandBar={PricingCommandBard}
                path={NavigationPath.PRICING}
            />
            <AdminLayoutRoute
                component={Operators}
                layout={Layout}
                commandBar={OperatorsCommandBard}
                path={NavigationPath.OPERATORS}
            />
            <AdminLayoutRoute
                component={CancellationResons}
                layout={Layout}
                commandBar={CancellationReasonsCommandBar}
                path={NavigationPath.CANCELLATION_REASONS}
            />
            <AdminLayoutRoute
                component={Admins}
                layout={Layout}
                commandBar={AdminsCommandBar}
                path={NavigationPath.ADMINS}
            />
            <AdminLayoutRoute
                component={Reports}
                layout={Layout}
                commandBar={ReportsCommandBar}
                path={NavigationPath.REPORT_LIST}
            />
            <AdminLayoutRoute
                component={Store}
                layout={Layout}
                commandBar={StoreCommandBar}
                path={NavigationPath.STORE_LIST}
                //sideNav ={StoreNav}
            />
            <AdminLayoutRoute
                component={InternalStore}
                layout={Layout}
                commandBar={InternalStoreCommandBar}
                path={NavigationPath.INTERALSTORE_LIST}
                //sideNav ={StoreNav}
            />
            <AdminLayoutRoute
                component={StoreDocs}
                layout={Layout}
                commandBar={StoreDocsCommandBar}
                path={NavigationPath.STORE_DOCS_LIST}
            />
            <AdminLayoutRoute
                component={StoreMap}
                layout={Layout}
                commandBar={StoreMapCommandBar}
                path={NavigationPath.STORE_MAP}
            />
        </Switch>
    );
};

export default Routing;