import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {reloadOrders, setSelectedListItem, toggleOrderPanel} from './../../config/redux/client/clientActions';
import {RootStateOrAny} from 'react-redux';
import {useSelector} from 'react-redux';
import {FormikValues} from 'formik';
import {IResetForm} from '../../features/common/models/resetForm.type';
import {
    addOrder,
    editOrder as apiEditOrder,
} from '../../connectors/orders/connectors';
import {setErrorAlert} from "../../config/redux/alerts/alertsActions";
import {AlertsActions} from "../../config/redux/alerts/alertsActions.enum";
import {useUserInfo} from "../../features/common/hooks/useUserInfo";

export const useOrderForm = (companyId: string, departmentId: string, receptionPlace: any) => {
    const [isLoading, setIsLoading] = useState(false)
    const {
        selectedItem,
        formActive,
        editOrder,
        orderDetails
    } = useSelector((state: RootStateOrAny) => state.client)
    const dispatch = useDispatch()


    const { data } = useUserInfo()
    const user = data.data.result.user;

    const initValuesUpdate = {
        year: selectedItem ? selectedItem.year : "",
        firstPKWiU: selectedItem ? selectedItem.fields[0].value : "",
        secondPKWiU: selectedItem ? selectedItem.fields[1].value : "",
        thirdPKWiU: selectedItem ? selectedItem.fields[2].value : "",
        fourthPKWiU: selectedItem ? selectedItem.fields[3].value : "",
        fifthPKWiU: selectedItem ? selectedItem.fields[4].value : "",
        sixthPKWiU: selectedItem ? selectedItem.fields[5].value : "",
    }

    const initValuesCreate = {
        year: orderDetails.year,
        firstPKWiU: orderDetails.firstPKWiU,
        secondPKWiU: orderDetails.secondPKWiU,
        thirdPKWiU: orderDetails.thirdPKWiU,
        fourthPKWiU: orderDetails.fourthPKWiU,
        fifthPKWiU: orderDetails.fifthPKWiU,
        sixthPKWiU: orderDetails.sixthPKWiU,
    }

    const initValues = editOrder ? initValuesUpdate : initValuesCreate

    const handleSubmit = async (values: FormikValues,
                                {resetForm}: IResetForm) => {
        setIsLoading(true);
        const data = {
            year: values.year,
            fields: [
                {
                    typeId: "22.11.11.0",
                    value: values.firstPKWiU,
                },
                {
                    typeId: "22.11.12.0",
                    value: values.secondPKWiU
                },
                {
                    typeId: "22.11.13.0",
                    value: values.thirdPKWiU
                },
                {
                    typeId: "22.11.14.0",
                    value: values.fourthPKWiU
                },
                {
                    typeId: "22.11.20.0",
                    value: values.fifthPKWiU
                },
                {
                    typeId: "22.11.53.0",
                    value: values.sixthPKWiU
                },
            ]
        }
        try {
            editOrder !== true ? await addOrder(data) : await apiEditOrder(data);
            setIsLoading(false);
            dispatch(toggleOrderPanel({isOpen: false, formActive: false}))
            dispatch(setSelectedListItem(''))
            dispatch(reloadOrders(true));
        } catch (err) {
            setIsLoading(false);
            dispatch(setErrorAlert(user.bdo && user.bdo.length == 9 ? 'Wystąpił błąd' : 'Uzupełnij numer bdo'))
        }

    }

    const cancelForm = () => {
        dispatch(toggleOrderPanel({isOpen: false, formActive}))
        dispatch(setSelectedListItem(''))
    }

    return {
        handleSubmit,
        cancelForm,
        isLoading,
        initValues,
    }
}