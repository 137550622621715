import React, { useState } from "react";
import BasicList from "../../common/layout/BasicList";
import {
    setDriver,
    setSelectedDrivers,
    toggleDriversPanel,
    reloadDrivers,
    addNewDriver,
} from "../../../config/redux/drivers/driversActions";
import { columns } from "../../drivers/components/driversColumns"
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react/";
import { RenderNavigation } from "./CustomNavigationYearlyReport";
import {
  addOrderComment,
  setActiveOrder,
  setSelectedOrders,
} from "../../../config/redux/orders/ordersActions";
import { OrderTabsNames } from "../models/OrderTabs.enum";
import { clearAlerts } from "../../../config/redux/alerts/alertsActions";
import { useOrderData } from "../hooks/useOrderData";
import { createNewOrder, setActiveOrderTab, setOrderEditing, toggleYearlyReportPanel } from "../../../config/redux/orders/ordersPanel/orderPanelActions";
import { getYears } from "../../../connectors/drivers/connectors";
import YearlyReportCommandBar from "../../commandBar/components/settings/YearlyReportCommandBar";

const panelS = {
  content: {
    padding: 20,
  },
  main: {
    height: "100%",
  },
};

const YearlyReportPanel: React.FC = () => {
  const dispatch = useDispatch();
  const {
    selectedDrivers,
    activeDriver,
    driverPanelOpen,
    reloadDriversList,
    addDriver,
    removeDriver,
} = useSelector((state: RootStateOrAny) => state.drivers);
  const {yearlyReportPanelOpen} = useOrderData()

  const dissmisPanel = (ev: any) => {
    dispatch(setActiveOrder({}));
    dispatch(setSelectedOrders({ selectedOrders: [] }));
    dispatch(setActiveOrderTab(OrderTabsNames.DETAILS));
    dispatch(clearAlerts())
    dispatch(addOrderComment(false));
    dispatch(toggleYearlyReportPanel(false));
    dispatch(setOrderEditing(false));
    dispatch(createNewOrder(false))
    localStorage.removeItem("receptionPlace");
    localStorage.removeItem("orderTypeItemId");
  };

  const parseDriver = (items: any) => {
    const drivers = items.items.map((item: any) => {
        return {
            key: item.id,
            id: item.id,
            isActive: item.isActive,
        };
    });
    return drivers;
};

  return (
    <Panel
      isOpen={yearlyReportPanelOpen}
      closeButtonAriaLabel="Close"
      type={3}
      onDismiss={dissmisPanel}
      onOuterClick={() => {}}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      onRenderNavigation={RenderNavigation}
      styles={panelS}
      isFooterAtBottom={true}
    >
        {/* <YearlyReportCommandBar /> */}
        <BasicList
            setSelectedItems={setSelectedDrivers}
            setActiveItem={setDriver}
            togglePanel={toggleDriversPanel}
            reload={reloadDrivers}
            editList={addNewDriver}
            getData={getYears}
            activeItem={activeDriver}
            panelOpen={driverPanelOpen}
            selectedItems={selectedDrivers}
            removeItems={removeDriver}
            reloadItems={reloadDriversList}
            listEdited={addDriver}
            columns={columns}
            parseItems={parseDriver}
        />
    </Panel>
  );
};

export default YearlyReportPanel;