import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react/";

import { panelS } from "../../../common/styles/panelStyles";
import { toggleSelfEditPanel } from "../../../../config/redux/admins/adminsActions";
import SelfEditForm from "../forms/SelfEditForm";

const SelfEditPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { selfEditPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.admins
  );

  const dismissPanel = () => {
    dispatch(toggleSelfEditPanel(false));
  };

  return (
    <Panel
      isOpen={selfEditPanelOpen}
      closeButtonAriaLabel="Close"
      headerText="Profil"
      onDismiss={dismissPanel}
      type={1}
      styles={panelS}
      isHiddenOnDismiss={false}
    >
      <SelfEditForm />
    </Panel>
  );
};

export default SelfEditPanel;
