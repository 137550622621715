import {useMemo, useState} from 'react';
import { useDispatch } from 'react-redux';
import { clearAlerts, setListMessage, setErrorAlert } from './../../config/redux/alerts/alertsActions';
import { setSelectedListItem, toggleReceptionPlacePanel } from './../../config/redux/client/clientActions';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import { FormikValues } from 'formik';
import { IResetForm } from '../../features/common/models/resetForm.type';
import { addPickUpPoint, editPickUpPoint } from '../../connectors/clients/connectors';
import { useQuery } from "react-query";
import { getOrderTypes } from "../../connectors/orderTypes/connectors";
import { setActivatedUsers } from '../../config/redux/unactivepickuppoints/unactivepickuppointsActions';

export const ReportsForm = () => {
  const [loading, setIsLoading] = useState(false)
  const {selectedItem, formActive} = useSelector((state: RootStateOrAny) => state.client)
  const { selectedIds, basicInfo } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );
  const dispatch = useDispatch();
  const getBody = (values: any) => {
    let returnObj: any = {
      city: values.city,
      postalCode: values.postalCode,
      street: values.street,
      isDefault: values.default,
      bdoNumber: values.bdoNumber,
      phone: values.phone,
    };

    if(!!values.orderTypeId && typeof values.orderTypeId === 'number') {
      returnObj.orderTypeId = values.orderTypeId;
    }

    return returnObj;
  };
  
  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true);
    const body = getBody(values);

    try {
      let departmentId = sessionStorage.getItem("departmentId");
      selectedItem ? await editPickUpPoint(selectedItem.id, body, departmentId) : await addPickUpPoint(body, departmentId);
      setIsLoading(false);
      resetForm();
      dispatch(toggleReceptionPlacePanel({isOpen: false, formActive}));
      dispatch(setSelectedListItem(''));
      dispatch(setListMessage("Operacja przebiegła pomyślnie."));

      dispatch(setActivatedUsers({a: 'a'}));
    } catch(err) {
      setIsLoading(false);
      dispatch(setErrorAlert("Wystąpił błąd podczas wykonywania akcji."));
    }
  };

  const {data} = useQuery("orderTypes", getOrderTypes);
  const prepareOrderTypes = () => {
    var orderTypesMap =  data ? data.data.data : []
    var orderTypesa = Array<any>();
    
    orderTypesMap.map((x : any) => {
      var order = {
        key: x.id, 
        text: x.name
      }
      orderTypesa.push(order);
    })
    return orderTypesa;
  };
  
  const orderTypes = prepareOrderTypes();

  const cancelForm = () => {
    dispatch(clearAlerts());
    dispatch(toggleReceptionPlacePanel({isOpen: false, formActive}));
    dispatch(setSelectedListItem(''));
  }

  const initValues = useMemo(() => {
    if(selectedItem){
      return {
        city: selectedItem.city,
        postalCode: selectedItem.postalCode,
        street: selectedItem.street,
        default: selectedItem.isDefault,
        bdoNumber: selectedItem.bdoNumber,
        phone: selectedItem.phone,
        orderTypeId: selectedItem.orderTypeId,
        department: selectedItem.departmentId,    
      }
    } else return {

    }
  },[selectedItem])

  return{
    selectedItem,
    handleSubmit,
    cancelForm,
    loading,
    initValues,
    orderTypes
  }
}