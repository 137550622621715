import { NavigationPath } from './../../../config/routing/NavigationPath';
import { setNewPass } from "./../../../connectors/auth/connectors/index";
import { FormikValues } from "formik";
import { useEffect, useState } from "react";
import { IResetForm } from "../../common/models/resetForm.type";
import { useHistory, useLocation } from "react-router-dom";

export const useResetPass = () => {
  const location = useLocation();
  const history = useHistory()
  const [loading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");

  const getParams = (searchParams: any) => ({
    token: searchParams.get("token") || "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const { token } = getParams(searchParams);

    setToken(token);
    return () => {
      setMessage("");
    };
  }, []);


  const handleReset = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true);
    setMessage("")

    const body = {
      password: values.password,
      passwordConfirm: values.passwordConfirm,
      token,
    };

    try {
      await setNewPass(body);
      setIsLoading(false);
      resetForm()
      setMessage("Zresetowano hasło");
    } catch (err) {
      setIsLoading(false);
      setMessage("Link utracił ważność");
    }
  };

  const cancelForm = () => {
      history.push(NavigationPath.AUTH)
  }

  return {
    loading,
    message,
    handleReset,
    cancelForm
  };
};
