import { StoreMapActions } from "./storeMap.enum";
import { IStoreMapActions } from "./storeMap.types";

const initState = {
  showReceptionPlacePoints: true,
  showContainerPoints: true
};

export const storeMapReducer = (state = initState, action: IStoreMapActions) => {
  const { type, payload } = action;
  switch (type) {
    case StoreMapActions.SHOW_CONTAINER_POINTS:
      return { ...state, showContainerPoints: payload };
    case StoreMapActions.SHOW_RECEPTIONPLACE_POINTS:
      return { ...state, showReceptionPlacePoints: payload };
    case StoreMapActions.SHOW_INTERNALSTORE_POINTS:
      return { ...state, showInternalStorePoints: payload };
    default:
      return state;
  }
};
