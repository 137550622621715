import { toggleClientsPanel } from "./../../../config/redux/clients/panel/clientPanelActions";
import { FormikValues } from "formik";
import { useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setErrorAlert } from "../../../config/redux/alerts/alertsActions";
import { IResetForm } from "../../common/models/resetForm.type";
import { useQuery } from "react-query";
import {
  getYearsClients,
  putYearsClients,
} from "../../../connectors/drivers/connectors";
import { IYear } from "../../../connectors/drivers/models";

export const useBasicInfoForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { basicInfo } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );

  const {
    id,
    userName,
    name,
    surname,
    emailAddress,
    isActive,
    fullName,
    nip,
    bdo,
    phoneNumber,
    lastLoginTime,
    creationTime,
    company,
    roleNames,
  } = basicInfo;

  const { data } = useQuery(basicInfo.id, getYearsClients);
  const prepareOrderTypes = () => {
    const orderTypesMap = data ? data.data.result.items : [];
    let orderTypes = Array<any>();
    orderTypesMap.map((x: any) => {
      let order = {
        id: x.id,
        isActive: x.isActive,
        isSpecial: x.isSpecial,
        specialYearId: x.specialYearId,
      };
      orderTypes.push(order);
    });
    return orderTypes;
  };

  const [years, setYears] = useState<any[]>(prepareOrderTypes());

  useEffect(() => {
    if (data && years && years.length === 0) setYears(prepareOrderTypes());
  }, [data]);

  const initValues = useMemo(
    () => ({
      id: id,
      userName: userName ? userName : "",
      name: name ? name : "",
      surname: surname ? surname : "",
      emailAddress: emailAddress ? emailAddress : "",
      fullName: fullName ? fullName : "",
      nip: nip ? nip : "",
      bdo: bdo ? bdo : "",
      company: company.companyInfo ? company.companyInfo.name : "",
      phoneNumber: phoneNumber ? phoneNumber : "",
    }),
    [basicInfo]
  );

  const readOnlyData = useMemo(
    () => ({
      id: id,
      name: name,
      nip: nip,
    }),
    [basicInfo]
  );
  const specialYears = <any>[];
  years.forEach((year) => {
    if (year.isSpecial == true) {
      specialYears.push(year.id);
    }
  });

  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true);
    const data: IYear = {
      userId: values.id,
      years: specialYears,
    };
    try {
      await putYearsClients(data);
    } catch (err) {
      console.error(err);
      dispatch(setErrorAlert(err.message));
    }
    dispatch(toggleClientsPanel(false));
    resetForm();
    setIsLoading(false);

    // await putYearsClients(data);
    // setIsLoading(true);
    // try {
    //   const body = values;
    //   await editUserYears(readOnlyData.id, body);
    //   await putYearsClients(putYearData);
    //   resetForm();
    //   setIsLoading(false);
    //   dispatch(clearAlerts());
    //   dispatch(toggleEditing({ editing: false }));
    //   dispatch(refetchClientPanelData(true));
    //   dispatch(addNewClient(true))
    //
    //   dispatch(setSuccessAlert(Messages.OPERATION_SUCCESS));
    // } catch (err) {
    //   setIsLoading(false);
    //   dispatch(setErrorAlert("Wystąpił błąd"));
    // }
  };

  const setCustomYears = (newYears: any) => {
    setYears(newYears);
  };

  return {
    initValues,
    handleSubmit,
    isLoading,
    readOnlyData,
    years,
    setCustomYears,
  };
};
