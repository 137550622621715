import { mergeStyleSets } from "@fluentui/react";
import { IColumn, Icon, TooltipHost } from "@fluentui/react/";
import React from "react";

const classNames = mergeStyleSets({
  active: {
    fontSize: "18px",
    color: "green",
  },
  inActive: {
    fontSize: "18px",
    color: "red",
  },
  commandBar: {},
});

export const columns: IColumn = [
  {
    key: "Id",
    name: "Rok",
    fieldName: "id",
    minWidth: 250,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "isActive",
    name: "Aktywny",
    fieldName: "isActive",
    minWidth: 200,
    maxWidth: 250,
    onRender: (item: any) => {
      if (item.isActive) {
        return (
          <TooltipHost content="Aktywny">
            <Icon iconName={"CheckMark"} className={classNames.active} />
          </TooltipHost>
        );
      } else {
        return (
          <TooltipHost content="Nieaktywny">
            <Icon iconName={"Cancel"} className={classNames.inActive} />
          </TooltipHost>
        );
      }
    },
  },
];
