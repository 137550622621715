import * as React from "react";
import { useEditOperator } from "../../hooks/useEditOperator";
import OperatorForm from "./OperatorForm";

export interface EditOperatorProps {
  cancelForm: any;
  operatorId: string;
}

const EditOperator: React.FC<EditOperatorProps> = ({
  cancelForm,
  operatorId,
}) => {
  const { edit, initValues, loading, selectedOperator } = useEditOperator(operatorId);

  return (
    <OperatorForm
      initValues={initValues}
      handleSubmit={edit}
      cancelForm={cancelForm}
      loading={loading}
      selectedOperator={selectedOperator}
    />
  );
};

export default EditOperator;
