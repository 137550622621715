import api from "../../../config/axios/configAxios";


export const addAdmin = async (data: any) => api.post(`/api/Admin/Users/AddAdmin`, data);

export const editAdmin = async (data: any, adminId: string) =>
  api.put(`/api/Admin/Users/${adminId}`, data);

  export const getAdmins = async () =>
  api.get(`/api/Admin/Users/admins`);

  export const deleteAdmin= async (userId: string) =>
  api.delete(`/api/Admin/Users/${userId}`);
