import React, { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { setSelectedOperator } from "../../../../config/redux/operators/operatorsActions";
import { getOperators } from "../../../../connectors/operators/connectors";

import { useList } from "../../../common/hooks/useList";
import { parseData } from "../../actions/operatorsUtils";
import { useOperators } from "../../hooks/useOperators";
import OperatorsList from "./OperatorList";

const OperatorsListContainer: React.FC = () => {
  const { data } = useQuery("operators", getOperators);
  const fetchedData = useMemo(() => {
    return data ? parseData(data.data.data) : undefined
  },[data]) ;
  const disptach = useDispatch()
  const { setPanelOpen, setOperatorOnSelection, selectedOperator } = useOperators();
  const { handleInvoke, selection, items } = useList(
    fetchedData,
    setPanelOpen,
    setOperatorOnSelection,
  );

  useEffect(() => {
    if(!selectedOperator){
      selection.selectToKey("", true)
    }
  },[selectedOperator])

  useEffect(() => {
    return (() => {
      disptach(setSelectedOperator("")) 
    })
  },[])

  return (
    <OperatorsList
      handleInvoke={handleInvoke}
      selection={selection}
      items={items}
    />
  );
};

export default OperatorsListContainer;
