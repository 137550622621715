import * as React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import BasicList from "../../common/layout/BasicList";

import {
  setAdmin,
  setSelectedAdmins,
  toggleAdminsPanel,
  reloadAdmins,
  addNewAdmin,
} from "../../../config/redux/admins/adminsActions";
import {columns} from "./adminsColumns"
import { getAdmins } from "../../../connectors/admins/connectors/index";

const AdminsList: React.FC = () => {
  const {
    selectedAdmins,
    activeAdmin,
    adminsPanelOpen,
    reloadAdminsList,
    addAdmin,
  } = useSelector((state: RootStateOrAny) => state.admins);

  const parseAdmin = (items: Array<any>) => {
    const admins = items.map((item: any) => item.userData);
    return admins;
  };

  return (
    <>
      <BasicList
        setSelectedItems={setSelectedAdmins}
        setActiveItem={setAdmin}
        togglePanel={toggleAdminsPanel}
        reload={reloadAdmins}
        editList={addNewAdmin}
        getData={getAdmins}
        activeItem={activeAdmin}
        panelOpen={adminsPanelOpen}
        selectedItems={selectedAdmins}
        removeItems={reloadAdminsList}
        listEdited={addAdmin}
        columns={columns}
        parseItems={parseAdmin}
        
      />
    </>
  );
};

export default AdminsList;


