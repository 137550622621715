import * as React from "react";
import { IContainerPoint } from "../intefaces/IContainerPoint";
import { wrapperStyles } from "../styles/style";

export interface ContainerPopupHtmlProps {
    containnerData: IContainerPoint;
}

export const ContainerPopupHtml: React.FC<ContainerPopupHtmlProps> = ({ containnerData }) => {
    return (
        <div style={wrapperStyles.popupStyles}>
            <b>Identyfikator: {containnerData.friendlyName}</b>< br />
            Ostatni odczyt: {containnerData.lastActiveDate}< br />
            {(containnerData.imei != undefined && containnerData.imei != 0) ? <>IMEI: {containnerData.imei} < br /> </> : <></>}
            {containnerData.iccid != undefined ? <>Iccid: {containnerData.iccid} < br /> </> : <></>}
        </div>
    )
}