import { IUsersAction } from "./unactiveusersActions.types";
import { UnactiveUsersActions } from "./unactiveusersActions.enum";

const initState = {
  selectedUnactiveUsers: [],
  activeUser: {},
  userPanelOpen: false,
  reload: false,
  addUser: false,
  activatedUsers: {}
};

export const unactiveusersReducer = (state = initState, action: IUsersAction) => {
  const { type, payload } = action;

  switch (type) {
    case UnactiveUsersActions.SET_SELECTED_USERS:
      return { ...state, selectedUnactiveUsers: payload };
    case UnactiveUsersActions.TOGGLE_USERS_PANEL:
      return { ...state, userPanelOpen: payload.open };
    case UnactiveUsersActions.RELOAD_USERS:
      return { ...state, reload: payload.reload };
    case UnactiveUsersActions.SET_USER:
      return { ...state, activeUser: payload };
    case UnactiveUsersActions.ADD_USER:
      return { ...state, addUser: payload.add };
    case UnactiveUsersActions.CLEAR:
      return { ...initState };
    case UnactiveUsersActions.SET_ACTIVATED_USERS:
      return { ...initState, activatedUsers: payload };
    default:
      return state;
  }
};
