import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react/";
import { toggleReportsPanel } from "../../../config/redux/reports/reportsAction";
import { panelS } from "../../common/styles/panelStyles";

const StorePanel: React.FC = () => {
    const dispatch = useDispatch();
    const { reportsPanelOpen } = useSelector(
        (state: RootStateOrAny) => state.reports
    );

    const dismissPanel = () => {
        dispatch(toggleReportsPanel({ open: false }));
    };

    return (
        <Panel
            isOpen={reportsPanelOpen}
            closeButtonAriaLabel="Close"
            headerText="Raport"
            onDismiss={dismissPanel}
            type={3}
            styles={panelS}
            isHiddenOnDismiss={false}
        >
            {/* <ReportForm /> */}
            {/* Tutaj będzie ten formularz od generowania raportu */}
            {/* <AdminForm /> */}
        </Panel>
    );
};

export default StorePanel;