import {useDispatch, useSelector, RootStateOrAny} from "react-redux"

import { setSelectedOperator, toggleOperatorsPanel } from './../../../config/redux/operators/operatorsActions';


export const useOperators = () => {
    const {selectedOperator} = useSelector((state: RootStateOrAny) => state.operators)
    const dispatch = useDispatch()

    const setPanelOpen = () => {
        dispatch(toggleOperatorsPanel(true))
    }

    const setOperatorOnSelection = (arr: Array<string>) => {
        dispatch(setSelectedOperator(arr[0]))
    }

    return {
        setPanelOpen,
        setOperatorOnSelection,
        selectedOperator,
    }
}