import { refetchClientPanelData, setSelectedIds } from './../../../../config/redux/clients/panel/clientPanelActions';
import { activatePickUpPoint, activateUser, deactivateUser, deleteCompaniesAttachments, getLinkToFile, inviteUserAgain } from "./../../../../connectors/clients/connectors/index";
import { clearAlerts, setErrorAlert } from "./../../../../config/redux/alerts/alertsActions";
import { generatePickUpPointsCommands } from "./../../actions/generateCommands/clients/panel/generatePickUpPointsCommands";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  toggleEditing,
  reload,
} from "../../../../config/redux/clients/panel/clientPanelActions";
import { TabsNames } from "../../../clients/components/edditionPanel/CTabs";
import { checkQuantity } from "../../actions/checkQuantity";
import { generateUsersComands } from "../../actions/generateCommands/clients/panel/generateUserCommands";
import { generateOthers } from "../../actions/generateCommands/clients/panel/generateOtherCommands";
import {
  deleteDepartment,
  deletePickUpPoint,
  deleteUser,
  deleteInvitedUser,
} from "../../../../connectors/clients/connectors/index";
import { setSuccessAlert } from "../../../../config/redux/alerts/alertsActions";
import { generateFilesCommands } from '../../actions/generateCommands/clients/panel/generateFilesCommands';
import { useQuery } from 'react-query';
import { getOrderTypes } from '../../../../connectors/orderTypes/connectors';

export const usePanelCommandBar = () => {
  const { activeTab, editing, selectedIds } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );

  const dispatch = useDispatch();
  const quantity = checkQuantity(selectedIds.length);

  const verifyPickuPoint = async (e: any) => {
    try {
      const res = await activatePickUpPoint(selectedIds[0].id, e.currentTarget.id);
      dispatch(refetchClientPanelData(true));
      dispatch(setSuccessAlert("Operacja przebiegła pomyślnie"));
    } catch (err) {
      dispatch(setErrorAlert("Wystąpił błąd"))
    }
  };

  const toggle = () => {
    dispatch(toggleEditing({ editing: true }));
  };

  const toggleInvite = () => {
    dispatch(setSelectedIds({ selectedIds: [] }));
    dispatch(toggleEditing({ editing: true }));
  };

  const deletePickUpPointById = async () => {
    dispatch(clearAlerts());
    try {
      const res = await deletePickUpPoint(selectedIds[0].id);
      dispatch(reload({ reload: true }));
      dispatch(setSuccessAlert("Operacja pomyślna"));
    } catch(err) {
      console.error(err);
    }
  };
  const deleteUserById = async () => {
    dispatch(clearAlerts());
    try {
      const res = !selectedIds[0].isInvitationInProgress ? await deleteUser(selectedIds[0].id) : await deleteInvitedUser(selectedIds[0].id);
      dispatch(reload({ reload: true }));
      dispatch(setSuccessAlert("Operacja pomyślna"));
    } catch(err) {
      console.error(err);
    }
  };

  const activateUserById = async () => {
    try {
      const res = await activateUser(selectedIds[0].id);
      dispatch(refetchClientPanelData(true));
      dispatch(setSuccessAlert("Operacja pomyślna"));
    } catch(err) {
      console.error(err);
    }
  };

  const deactivateUserById = async () => {
    try {
      const res = await deactivateUser(selectedIds[0].id);
      dispatch(refetchClientPanelData(true));
      dispatch(setSuccessAlert("Operacja pomyślna"));
    } catch(err) {
      console.error(err);
    }
  }

  const inviteUserAgainByMail = async () => {
    try {
      const res = await inviteUserAgain(selectedIds[0].email);
      dispatch(setSuccessAlert("Zaproszenie zostało wysłane"));
    } catch(err) {
      console.error(err);
      dispatch(setErrorAlert("Wystąpił błąd"))
    }
  }

  const deleteDepartmentById = async () => {
    dispatch(clearAlerts());
    try {
      await deleteDepartment(selectedIds[0].id);
      dispatch(reload({ reload: true }));
      dispatch(setSuccessAlert("Operacja pomyślna"));
    } catch(err) {
      console.error(err);
    }
  };

  const deleteFileById = async () => {
    dispatch(clearAlerts());
    try {      
      await deleteCompaniesAttachments(selectedIds[0].id);
      dispatch(reload({ reload: true }));
      dispatch(setSuccessAlert("Operacja pomyślna"));
    } catch(err) {
      console.error(err);
    }
  }

  const downloadFileById = async () => {
    const url = await getLinkToFile(selectedIds[0].blobFileName);
    const link = document.createElement('a');
    link.href = url.data;
    //link.setAttribute('download', selectedIds[0].originalFileName);
    document.body.appendChild(link);
    link.click();
  }

  const { data } = useQuery("orderTypes", []);
  const prepareOrderTypes = () => {
    var orderTypesMap = data ? data.data.result : []
    var orderTypesa = Array<any>();
    orderTypesMap.map((x: any) => {
      var order = {
        key: x.id,
        text: x.name
      }
      orderTypesa.push(order)
    })
    return orderTypesa;
  };

  const orderTypes = prepareOrderTypes()

  const _generateItems = () => {
    if (editing) {
      return [];
    } else {
      if (activeTab === TabsNames.USERS) {
        return generateUsersComands(quantity, {
          toggle,
          toggleInvite,
          deleteUserById,
          activateUserById,
          deactivateUserById,
          inviteUserAgainByMail
        },
          selectedIds[0]
        );
      } else if (activeTab === TabsNames.BASIC_INFO) {
        return [{
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          name: "edit",
          onClick: toggle,
        }];
      } else if (activeTab === TabsNames.PICKUP_POINTS) {
        return generatePickUpPointsCommands(quantity, {
          toggle,
          deletePickUpPointById,
          verifyPickuPoint
        }, orderTypes, selectedIds[0] ? selectedIds[0].isActive : false);
      } else if (activeTab === TabsNames.DEPARTMENTS) {
        return generateOthers(quantity, { toggle, deleteDepartmentById });
      } else if (activeTab === TabsNames.FILES) {
        return generateFilesCommands(quantity, { toggle, deleteFileById, downloadFileById })
      }
      {
        return [];
      }
    }
  };

  const items = _generateItems();

  return {
    items,
  };
};