import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setListErr, setListMessage } from "../../../config/redux/alerts/alertsActions";
import { getLinkToFile } from "../../../connectors/clients/connectors";
import { approveDocument, cancelDocument } from "../../../connectors/store/connectors";
import { DocumentStatusEnum } from "../models/DocumentStatusEnums";
import { reloadData, setApprove, setCancel, setStoreDocs, toggleAddStoreDocPanel } from "./../../../config/redux/storeDocs/storeDocsActions";
import { Quantity } from "./../../../features/common/models/quantity.model";

export const useStoreDocsCommandBar = () => {
    const dispatch = useDispatch();
    const { selectedStoreDocs } = useSelector((state: RootStateOrAny) => state.storeDocs);
    const quantity = selectedStoreDocs[0] ? Quantity.ONE : Quantity.NONE;

    const addStoreDocs = () => {
        dispatch(toggleAddStoreDocPanel({ isOpen: true, add: true }));
    };

    const approveDoc = async () => {
        try {
            let response = await approveDocument(selectedStoreDocs[0].id);
            dispatch(reloadData({ reload: true }));
            dispatch(setApprove(true));
            dispatch(setListMessage("Operacja przebiegła pomyślnie"));
            dispatch(setStoreDocs({ selectedStoreDocs: [] }));
        } catch (err: any) {
            if (err.message && typeof err.message === 'string') {
                dispatch(setListErr(err.message));
                dispatch(reloadData({ reload: true }));
                dispatch(setStoreDocs({ selectedStoreDocs: [] }));
            };
        }
    };

    const cancelDoc = async () => {
        try {
            let response = await cancelDocument(selectedStoreDocs[0].id);
            dispatch(reloadData({ reload: true }));
            dispatch(setCancel(true));
            dispatch(setListMessage("Operacja przebiegła pomyślnie"));
            dispatch(setStoreDocs({ selectedStoreDocs: [] }));
        } catch (err: any) {
            if (err.message && typeof err.message === 'string') {
                dispatch(reloadData({ reload: true }));
                dispatch(setListErr(err.message));
                dispatch(setStoreDocs({ selectedStoreDocs: [] }));
            }
        }
    };

    const downloadFile = async () => {
        try {
            if (selectedStoreDocs[0].fileExists) {
                const url = await getLinkToFile(selectedStoreDocs[0].blobFileName);
                const link = document.createElement('a');
                link.href = url.data;
                //link.setAttribute('download', selectedIds[0].originalFileName);
                document.body.appendChild(link);
                link.click();
            }
        } catch (err: any) {
            if (err.message && typeof err.message === 'string') {
                dispatch(setListErr(err.message));
            }
        }
    }

    const showDetails = () => {
        dispatch(toggleAddStoreDocPanel({ isOpen: true, add: false }));
    }

    const generateItems = () => {
        switch (quantity) {
            case Quantity.NONE:
                return [{
                    key: "add",
                    text: "Dodaj",
                    iconProps: { iconName: "reportdocument" },
                    name: "prepare",
                    onClick: addStoreDocs,
                },
                ]
            case Quantity.ONE:
                let downloadButton: any[] = []
                let showButton = [{
                    key: "showFile",
                    text: "Zobacz",
                    iconProps: { iconName: "textDocument" },
                    name: "showFile",
                    onClick: showDetails,
                }]
                if (selectedStoreDocs[0].fileExists == true) {
                    downloadButton = [{
                        key: "downloadFile",
                        text: "Pobierz dokument",
                        iconProps: { iconName: "download" },
                        name: "downloadFile",
                        onClick: downloadFile,
                    }]
                }
                if (selectedStoreDocs[0].documentStatus == DocumentStatusEnum.BUFFOR) {
                    let approveButton = [{
                        key: "approve",
                        text: "Zatwierdź dokument",
                        iconProps: { iconName: "CheckMark" },
                        name: "approve",
                        onClick: approveDoc,
                    }];
                    return [...showButton, ...downloadButton, ...approveButton]
                }
                else if (selectedStoreDocs[0].documentStatus == DocumentStatusEnum.APPROVED) {
                    let cancelButton = [
                        {
                            key: "cancel",
                            text: "Anuluj dokument",
                            iconProps: { iconName: "Cancel" },
                            name: "cancel",
                            onClick: cancelDoc,
                        }];
                    return [...showButton, ...downloadButton, ...cancelButton];
                }
                else if (selectedStoreDocs[0].documentStatus == DocumentStatusEnum.CANCELED) {
                    return showButton;
                }
                else {
                    return downloadButton;
                }

            default:
                return [];
        }
    };

    const items = generateItems()

    return {
        items
    }
};