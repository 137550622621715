import * as yup from "yup";
export const validationSchemaDepartmentShort = yup.object().shape({
  city: yup.string().required("Żeby się zarejestrować musisz podać miejscowość"),
  street: yup.string().required("Żeby się zarejestrować musisz podać ulice"),
  postalCode: yup
    .string()
    .required("kod pocztowy jest wymagany")
    .matches(/^[0-9, -]+$/, "podaj poprawny kod pocztowy")
    .min(6, "podaj poprawny kod pocztowy")
    .max(6, "podaj poprawny kod pocztowy"),
});

export const validationSchemaDepartment = yup.object().shape({
  email: yup
    .string()
    .email("Podaj poprawny email")
    .required("Żeby się zarejestrować musisz podać email")
    .max(50),
  // pass: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać hasło")
  //   .min(6, "za krótkie hasło")
  //   .max(50),
  // pass2: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać hasło")
  //   .min(6, "za krótkie hasło")
  //   .max(50)
  //   .oneOf([yup.ref("pass")], "hasła muszą być takie same"),
  phoneNumber: yup
    .string()
    .required("Żeby się zarejestrować musisz podać numer telefonu")
    .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
    .min(9, "niepoprawny numer telefonu")
    .max(9, "niepoprawny numer telefonu"),
  city: yup
    .string()
    .required("Żeby się zarejestrować musisz podać miejscowość"),
  street: yup.string().required("Żeby się zarejestrować musisz podać ulice"),
  postalCode: yup
    .string()
    .required("Żeby się zarejestrować podaj kod pocztowy")
    .matches(/^[0-9, -]+$/, "podaj poprawny kod pocztowy")
    .min(6, "podaj poprawny kod pocztowy")
    .max(6, "podaj poprawny kod pocztowy"),
  accept: yup
    .boolean()
    .oneOf([true], "W celu rejestracji musiz zakceptować regulamin"),
});

export const validationSchemaUser = yup.object().shape({
  email: yup
    .string()
    .email("Podaj poprawny email")
    .required("Żeby się zarejestrować musisz podać email")
    .max(50),
  // pass: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać hasło")
  //   .min(6, "za krótkie hasło")
  //   .max(50),
  // pass2: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać hasło")
  //   .min(6, "za krótkie hasło")
  //   .max(50)
  //   .oneOf([yup.ref("pass")], "hasła muszą być takie same"),
  // phoneNumber: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać numer telefonu")
  //   .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
  //   .min(9, "niepoprawny numer telefonu")
  //   .max(9, "niepoprawny numer telefonu"),
  department: yup
    .string()
    .required("Żeby się zarejestrować musisz wybrać oddział"),
});


export const validationSchemaUserShort = yup.object().shape({
  email: yup
    .string()
    .email("Podaj poprawny email")
    .required("Żeby się zarejestrować musisz podać email")
    .max(50),
  // pass: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać hasło")
  //   .min(6, "za krótkie hasło")
  //   .max(50),
  // pass2: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać hasło")
  //   .min(6, "za krótkie hasło")
  //   .max(50)
  //   .oneOf([yup.ref("pass")], "hasła muszą być takie same"),
  // phoneNumber: yup
  //   .string()
  //   .required("Żeby się zarejestrować musisz podać numer telefonu")
  //   .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
  //   .min(9, "niepoprawny numer telefonu")
  //   .max(9, "niepoprawny numer telefonu"),
});

export const validationSchemaUpdateUser = yup.object().shape({
  firstName: yup
    .string()
    .required("Podaj imię"),
  lastName: yup
    .string()
    .required("Podaj nazwisko"),
  phoneNumber: yup
    .string()
    .required("Podaj poprawny numer telefonu")
    .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
    .min(9, "niepoprawny numer telefonu")
    .max(9, "niepoprawny numer telefonu"),
});

export const PickUpPointSchema = yup.object().shape({
  city: yup
    .string()
    .required("Uzupełnij miejscowość"),
  street: yup.string().required("Uzupełnij ulice"),
  postalCode: yup
    .string()
    .required("Uzupełnij kod pocztowy")
    .matches(/^[0-9, -]+$/, "Podaj poprawny kod pocztowy")
    .min(6, "Podaj poprawny kod pocztowy")
    .max(6, "Podaj poprawny kod pocztowy"),
  phone: yup
    .string()
    .required("Podaj poprawny numer telefonu")
    .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
    .min(9, "Niepoprawny numer telefonu")
    .max(9, "Niepoprawny numer telefonu"),
  bdoNumber: yup
    .string()
    .required("Uzupełnij numer BDO")
    .matches(/^[0-9]+$/, "Podaj cyfry")
    .nullable()
});

export const PickUpPointSchemaAdmin = yup.object().shape({
  city: yup
    .string()
    .required("Uzupełnij miejscowość"),
  street: yup.string().required("Uzupełnij ulice"),
  postalCode: yup
    .string()
    .required("Uzupełnij kod pocztowy")
    .matches(/^[0-9, -]+$/, "Podaj poprawny kod pocztowy")
    .min(6, "Podaj poprawny kod pocztowy")
    .max(6, "Podaj poprawny kod pocztowy"),
  bdoNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Podaj cyfry")
    .nullable(),
    phone: yup
    .string()
    .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
    .min(9, "Niepoprawny numer telefonu")
    .max(9, "Niepoprawny numer telefonu"),
});

// export const addPickUpPointSchemaShort = yup.object().shape({
//   city: yup
//     .string()
//     .required("uzupełnij miejscowość"),
//   street: yup.string().required("uzupełnij ulice"),
//   postalCode: yup
//     .string()
//     .required("uzupełnij kod pocztowy")
//     .matches(/^[0-9, -]+$/, "podaj poprawny kod pocztowy")
//     .min(6, "podaj poprawny kod pocztowy")
//     .max(6, "podaj poprawny kod pocztowy"),
// });

// export const editPickUpPointSchema = yup.object().shape({
//   city: yup
//     .string()
//     .required("uzupełnij miejscowość"),
//   street: yup.string().required("uzupełnij ulice"),
//   postalCode: yup
//     .string()
//     .required("uzupełnij kod pocztowy")
//     .matches(/^[0-9, -]+$/, "podaj poprawny kod pocztowy")
//     .min(6, "podaj poprawny kod pocztowy")
//     .max(6, "podaj poprawny kod pocztowy"),
// });
