export enum LinksNames {
  UNVERIFIED_CLIENTS = "Bez weryfikacji",
  NEW_CLIENTS = "Nowo dodani",
  ACTIVE_CLIENTS = "Aktywni",
  INACTIVE_CLIENTS = "Nieaktywni",

  REPORTS_ALL = "Wszystkie",
  NEW_ORDERS = "Nowe",
  REPORTS_APPROVED = "Przyjęte",
  REPORTS_WAITING = "Oczekujące",
  REPORTS_REJECTED = "Anulowane",

  ORDER_TYPES = "Typy zamówień",
  VEHICLES = "Flota",
  DRIVERS = "Kierowcy",
  PRICING = "Cennik",
  OPERATORS = "Operatorzy",
  CANCELLATION_REASONS = "Powody anulowania",
  ADMINS = "Administratorzy",

  REPORT_LIST = "Lista raportów",

  CLIENT_USERS = "Użytkownicy",
  ACTIVATE_USERS = "Akytwuj użytkowników",

  YEARS_MANAGEMENT = "Zarządzanie Latami",

  DOWNLOAD_YEARLY_REPORT = "Pobierz raport roczny"
}