import { useEffect, useState } from "react";
import { IContainerPoint } from "../intefaces/IContainerPoint";
import { IReceptionPlacePoint } from "../intefaces/IReceptionPlacePoint";
import { getReceptionPlaceCoordaintes, getContainerCoordinates, getInternalStoreItems } from "../../../connectors/store/connectors";
import { PointTypeEnum } from "../enums/PointTypeEnum";
import moment from 'moment';
import { IInteralStorePoint } from "../intefaces/IInternalStorePoint";

export const useStoreMap = () => {
    const [receptionPlacePoints, setReceptionPlacePoints] = useState<any[]>([]);
    const [containerPoints, setContainerPoints] = useState<any[]>([]);
    const [internalStorePoints, setInternalStorePoints] = useState<any[]>([]);
    const [receptionPlacePointsLoading, setReceptionPlacePointsLoading] = useState<boolean>(true);
    const [containerPointsLoading, setContainerPointsLoading] = useState<boolean>(true);
    const [internalStorePointsPointsLoading, setInternalStorePointsPointsLoading] = useState<boolean>(true);

    useEffect(() => {
        setReceptionPlaces();
    }, [])

    useEffect(() => {
        setMapContainers();
    }, [])

    useEffect(() => {
        setInternalStore();
    }, [])

    const setReceptionPlaces = async () => {
        const res = await getReceptionPlaceCoordaintes();
        var tempClientPoints: IReceptionPlacePoint[] = [];
        res.data.map((receptionPlacePoint: IReceptionPlacePoint) => {
            if (receptionPlacePoint.latitude != undefined && receptionPlacePoint.longitude != undefined) {
                receptionPlacePoint.distances.map((x: any) =>
                    x.distance = Math.round(x.distance / 1000))
                tempClientPoints.push(
                    {
                        key: "client_" + receptionPlacePoint.id,
                        id: receptionPlacePoint.id,
                        longitude: receptionPlacePoint.longitude,
                        latitude: receptionPlacePoint.latitude,
                        elevation: receptionPlacePoint.elevation,
                        bdoNumber: receptionPlacePoint.bdoNumber,
                        city: receptionPlacePoint.city,
                        postalCode: receptionPlacePoint.postalCode,
                        street: receptionPlacePoint.street,
                        companyName: receptionPlacePoint.companyName,
                        taxNumber: receptionPlacePoint.taxNumber,
                        groupId: PointTypeEnum.RECEPTION_PLACE,
                        distances: receptionPlacePoint.distances
                    }
                );
            }
        });
        setReceptionPlacePoints(tempClientPoints);
        setReceptionPlacePointsLoading(false)
    };

    const setMapContainers = async () => {
        const res = await getContainerCoordinates();
        var tempContainerPoints: IContainerPoint[] = [];
        res.data.list.map((containerPoint: IContainerPoint) => {
            if (containerPoint.latitude != undefined && containerPoint.latitude != undefined) {
                tempContainerPoints.push(
                    {
                        key: "containerPoint_" + containerPoint.id,
                        id: containerPoint.id,
                        longitude: containerPoint.longitude,
                        latitude: containerPoint.latitude,
                        elevation: containerPoint.elevation,
                        friendlyName: containerPoint.friendlyName,
                        lastActiveDate: moment(containerPoint.lastActiveDate).format('DD-MM-YYYY HH:mm:ss'),
                        imei: containerPoint.imei,
                        iccid: containerPoint.iccid,
                        groupId: PointTypeEnum.CONTAINER
                    });
            }
        }
        );
        setContainerPoints(tempContainerPoints);
        setContainerPointsLoading(false);
    };

    const setInternalStore = async () => {
        var res = await getInternalStoreItems();
        var temp: IInteralStorePoint[] = [];
        res.data.result.map((x: IInteralStorePoint) => {
            temp.push({
                key: "internalPoint_" + x.id,
                id: x.id,
                name: x.name,
                longitude: x.longitude,
                latitude: x.latitude,
                groupId: PointTypeEnum.INTERNAL_STORE
            })
        });
        setInternalStorePoints(temp);
        setInternalStorePointsPointsLoading(false);
    }

    return {
        receptionPlacePoints,
        containerPoints,
        internalStorePoints,
        containerPointsLoading,
        receptionPlacePointsLoading,
        internalStorePointsPointsLoading
    }
}