import { IUsersAction } from "./unactivepickuppointsActions.types";
import { UnactivePickupPointsActions } from "./unactivepickuppointsActions.enum";

const initState = {
  selectedUnactiveUsers: [],
  activeUser: {},
  userPanelOpen: false,
  reload: false,
  addUser: false,
  activatedUsers: {}
};

export const unactivepickuppointsReducer = (state = initState, action: IUsersAction) => {
  const { type, payload } = action;

  switch (type) {
    case UnactivePickupPointsActions.SET_SELECTED_USERS:
      return { ...state, selectedUnactiveUsers: payload };
    case UnactivePickupPointsActions.TOGGLE_USERS_PANEL:
      return { ...state, userPanelOpen: payload.open };
    case UnactivePickupPointsActions.RELOAD_USERS:
      return { ...state, reload: payload.reload };
    case UnactivePickupPointsActions.SET_USER:
      return { ...state, activeUser: payload };
    case UnactivePickupPointsActions.ADD_USER:
      return { ...state, addUser: payload.add };
    case UnactivePickupPointsActions.CLEAR:
      return { ...initState };
    case UnactivePickupPointsActions.SET_ACTIVATED_USERS:
      return { ...initState, activatedUsers: payload };
    default:
      return state;
  }
};
