import * as React from "react";
import { IInteralStorePoint } from "../intefaces/IInternalStorePoint";
import { wrapperStyles } from "../styles/style";

export interface InteralStorePopupHtmlProps {
    interalStorePopupHtmlProps: IInteralStorePoint;
}

export const InteralStorePopupHtml: React.FC<InteralStorePopupHtmlProps> = ({ interalStorePopupHtmlProps }) => {
    return (
        <div style={wrapperStyles.popupStyles}>
            <b>{interalStorePopupHtmlProps.name}</b>
        </div>
    )
}