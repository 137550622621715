import { IUser, IUsersPanel, IReload, IAdd } from "./unactiveusersActions.types";
import {UnactiveUsersActions} from './unactiveusersActions.enum'

export const setUnactiveUser = (data: any) => {
    return{
        type: UnactiveUsersActions.SET_USER,
        payload: data
    }
}

export const setSelectedUnactiveUsers = (data: any) => {
  return {
    type: UnactiveUsersActions.SET_SELECTED_USERS,
    payload: data
  }
}

export const toggleUsersPanel = (data: IUsersPanel) => {
  return {
    type: UnactiveUsersActions.TOGGLE_USERS_PANEL,
    payload: data
  }
}

export const reloadUsers = (data: IReload) => {
  return {
    type: UnactiveUsersActions.RELOAD_USERS,
    payload: data
  }
}

export const clearUsers = () => {
  return {
    type: UnactiveUsersActions.CLEAR,
  }
}

export const addNewUser = (data: IAdd) => {
  return {
    type: UnactiveUsersActions.ADD_USER,
    payload: data
  }
}

export const setActivatedUsers = (data: any) => {
  return {
    type: UnactiveUsersActions.SET_ACTIVATED_USERS,
    payload: data
  }
}