import { setErrorAlert, clearAlerts } from './../../../config/redux/alerts/alertsActions';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FormikValues } from "formik";

import { verifyCompany } from "../../../connectors/auth/connectors/index";
import {
  setCompanyData,
} from "../../../config/redux/register/registerActions";


export const useVerification = () => {
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleVerification = async (
    values: FormikValues,
    { resetForm }: any
  ) => {
    if(values && !!values.nip) {
      setIsLoading(true);
      dispatch(clearAlerts())
      const res = await verifyCompany(values.nip);
      if (res) {
        setIsLoading(false);
      }
  
      resetForm();
  
      if (res.data) {
        dispatch(setCompanyData(res.data));   
      } if(res.data.message) {
        dispatch(setErrorAlert(res.data.message));
      }
    } else {
      dispatch(clearAlerts());

      resetForm();
      dispatch(setCompanyData({success: true, data: {
        alredyRegistered: false,
        multiDepartment: false,
        name: '',
        regon: '',
        requestId: "",
        residenceAddress: "",
        statusVat: '',
        taxNumber: '',
        companyAddress: {
          city: '',
          postal: '',
          street: '',
        }
      }}));
    }
  };

  return { handleVerification, loading };
};
