import React from "react";
import {useSelector, RootStateOrAny, useDispatch} from "react-redux";
import {
    Stack,
    Text,
    ITextStyles,
    IStackStyles,
    mergeStyles,
} from "@fluentui/react/";
import {FontSizes} from "@fluentui/react/";
import {FontWeights} from "@fluentui/react/";

import MessageContainer from "../../../../auth/components/register/forms/MessageContainer";
import FormHeader from "../FormHeader";

const filedS: ITextStyles = {
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
        width: 200,
        marginRight: 25,
    },
};

const textS: ITextStyles = {
    root: {
        fontSize: FontSizes.size14,
        width: 400,
    },
};

const wrapperS: IStackStyles = {
    root: {
        marginTop: 45,
        marginLeft: 10,
    },
};

const textContainerS: IStackStyles = {
    root: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
};

enum FieldNames {
    USERNAME = "Nazwa użytkownika",
    NAME = "Imię",
    SURNAME = "Nazwisko",
    EMAIL = "Email",
    IS_ACTIVE = "Aktywny",
    FULL_NAME = "Pełna nazwa",
    NIP = "NIP",
    BDO = "Numer BDO",
    PHONE = "Numer telefonu",
    LAST_LOGIN = "Ostatnie logowanie",
    DATE_JOINED = "Data dołączenia",
    // ORDER_TYPE = "Typ klienta"
}

const margin = mergeStyles({
    marginTop: 20,
});

const ClientBasicInfo: React.FC = () => {
    const {basicInfo} = useSelector(
        (state: RootStateOrAny) => state.clientPanel
    );

    const {
        userName,
        name,
        surname,
        emailAddress,
        isActive,
        fullName,
        nip,
        bdo,
        phoneNumber,
        lastLoginTime,
        creationTime,
        roleNames
    } = basicInfo;
    const items = [
        {
            name: FieldNames.USERNAME,
            value: userName,
        },
        {
            name: FieldNames.NAME,
            value: name,
        },
        {
            name: FieldNames.SURNAME,
            value: surname,
        },
        {
            name: FieldNames.EMAIL,
            value: emailAddress,
        },
        {
            name: FieldNames.PHONE,
            value: phoneNumber,
        },
        {
            name: FieldNames.NIP,
            value: nip,
        },
        {
            name: FieldNames.BDO,
            value: bdo,
        },
        {
            name: FieldNames.DATE_JOINED,
            value: new Date(Date.parse(creationTime)).toLocaleDateString('pl-PL', {
                year: "numeric",
                month: "long",
                day: "numeric"
            }).toUpperCase()
        }
    ];

    return (
        <Stack styles={wrapperS}>
            <FormHeader text="Informacje podstawowe"/>
            {items.map(({name, value}) => {
                return (
                    <Stack horizontal styles={textContainerS} key={name}>
                        <Text styles={filedS}>{name}</Text>
                        <Text styles={textS}>{value ? value : "Brak"}</Text>
                    </Stack>
                );
            })}
            {/* <CloseBtn/> */}
            <div className={margin}>
                <MessageContainer/>
            </div>
        </Stack>
    );
};

export default ClientBasicInfo;
