import * as React from "react";
import {
  CommandBar,
  ICommandBarStyles,
  getTheme,
} from "@fluentui/react/";
import { useClientsCommandBar } from "../../hooks/clients/useClientsCommandBar";
import { Stack, IStackStyles } from "@fluentui/react/";

const theme = getTheme();

const containerS: IStackStyles = {
  root: {
    position: "fixed",
    top: 50,
    left: 230,
    width: "calc(100% - 230px)",
    zIndex: 10,
    backgroundColor: "white",
    borderBottom: `2px solid ${theme.palette.neutralLighter}`,
  },
};

const commandBarS: ICommandBarStyles = {
  root: {
    paddingLeft: 0,
    width: 500,
  },
};

const ClientsCommandBar: React.SFC = () => {
  
  const { items } = useClientsCommandBar();

  return (
    <Stack
      styles={containerS}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
    >
      <CommandBar items={items} styles={commandBarS} />
    </Stack>
  );
};

export default ClientsCommandBar;