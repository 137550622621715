import { IPanelStyles } from "@fluentui/react/";
import { Breakpoints } from "./utils/Breakpoints";

export const panelS: Partial<IPanelStyles> = {
    header: {
      marginBottom: "35px",
      marginTop: "25px",
      marginLeft: "20px",
      selectors: {
        [`@media ${Breakpoints.LARGE}`]: {
          marginLeft: "0",
        },
      },
    },
  
    navigation: {
      position: "absolute",
      top: "20px",
      right: "10px",
      zIndex: 3,
      selectors: {
        [`@media ${Breakpoints.LARGE}`]: {
          top: "20px",
          right: "20px",
        },
      },
    },
  
    content: {
      selectors: {
        [`@media (min-width: 375px)`]: {
          padding: "30px", paddingTop: "70px",
        },
      },
    },
  };