import { useState, useMemo, useEffect } from "react";
import { Selection, IObjectWithKey } from "@fluentui/react//lib/DetailsList";

export const useList = (
  data: Array<any> | undefined,
  onInvoke?: any,
  onSelection?: any
) => {
  const [selectedItems, setSelectedItems] = useState<IObjectWithKey[]>([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let items: any = [];

    if (data) {
      data.forEach((item: any) => items.push(item));
      setItems(items);
    }
  }, [JSON.stringify(data)]);

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          setSelectedItems(selection.getSelection());
          if (onSelection) {
            onSelection(selection.getSelection());
          }
        },
      }),
    []
  );

  const handleInvoke = (item: any) => {
    if (onInvoke) {
      onInvoke();
    }
  };

  return {
    selection,
    selectedItems,
    handleInvoke,
    items,
  };
};
