import { IQuantity, Quantity } from "../../../../common/models/quantity.model";

export const generateInProgress = (quantity: IQuantity, actions: any) => {
  const items = [{
    key: "reject",
    text: "Odrzuć raport",
    iconProps: { iconName: "delete" },
    name: "delete",
    onClick: actions.setOrderCanceled,
  },
    {
      key: "activate",
      text: "Akceptuj raport",
      iconProps: { iconName: "followUser" },
      name: "activate",
      onClick: actions.setOrderAccepted,
    },
  ]

  switch (quantity) {
    case Quantity.NONE:
      return [];
    case Quantity.ONE:
      return [
        {
          key: "see",
          text: "Zobacz",
          iconProps: { iconName: "textDocument" },
          onClick: actions.openPanelToSee,
        },
        {
          key: "download",
          text: "Pobierz",
          iconProps: { iconName: "ReportDocument" },
          onClick: actions.download,
        },
        ...items
      ];
    case Quantity.MANY:
      return [...items];
  }
};
