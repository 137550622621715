import * as React from "react";
import {Image, Text, Stack, getTheme} from "@fluentui/react/";

import {FontSizes} from "@fluentui/react/";
import {FontWeights} from "@fluentui/react/";
import {NavLink} from "react-router-dom";
import {NavigationPath} from "../../../../config/routing/NavigationPath";

const theme = getTheme()

const containerS = {
    root: {
        minWidth: "250px",
        display: "flex",
        alignItems: "center",
    }
}

const imageS = {
    root: {
        marginRight: "15px"
    }
}

const textS = {
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
        color: theme.palette.white,
    },
}

const Logo: React.FC = () => {
    return (
        <Stack horizontal styles={containerS}>
                <Image src="/logo_32x32.png" styles={imageS}/>
            <NavLink to={NavigationPath.HOME} style={{textDecoration: "none"}}>
                <Text styles={textS}>ROSPRO</Text>
            </NavLink>
        </Stack>
    );
};

export default Logo;
