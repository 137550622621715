import React, { } from 'react';
import { useCancelFrom } from '../../clients/hooks/useCancelForm';
import { RootStateOrAny, useSelector } from 'react-redux';
import { wrapper } from '../../reports/styles/style';
import OrderDetailTxt from '../../orders/components/forms/edditOrder/OrderDetailTxt';

const StoreDocsShowForm = () => {
    const { selectedStoreDocs } = useSelector(
        (state: RootStateOrAny) => state.storeDocs
    );

    const cancel = useCancelFrom();

    return (
        <div className={wrapper}>
            {selectedStoreDocs[0] && (
                <div>
                    {selectedStoreDocs[0].documentStatusStr != undefined ?
                        <OrderDetailTxt
                            title="Status dokumentu"
                            value={selectedStoreDocs[0].documentStatusStr}
                        /> : <></>}
                    {selectedStoreDocs[0].description != undefined ?
                        <OrderDetailTxt
                            title="Numer dokumentu"
                            value={selectedStoreDocs[0].description}
                        /> : <></>}
                    {selectedStoreDocs[0].documentDate != undefined ?
                        <OrderDetailTxt
                            title="Data wystawienia"
                            value={selectedStoreDocs[0].documentDate}
                        /> : <></>}
                    {selectedStoreDocs[0].documentTypeName != undefined ?
                        <OrderDetailTxt
                            title="Typ"
                            value={selectedStoreDocs[0].documentTypeName}
                        /> : <></>}
                    {selectedStoreDocs[0].companyName != undefined ?
                        <OrderDetailTxt
                            title="Klient"
                            value={selectedStoreDocs[0].companyName}
                        /> : <></>}
                    {selectedStoreDocs[0].receptionPlaceName != undefined ?
                        <OrderDetailTxt
                            title="Punkt odbioru"
                            value={selectedStoreDocs[0].receptionPlaceName}
                        /> : <></>}
                    {selectedStoreDocs[0].internalStoreName != undefined ?
                        <OrderDetailTxt
                            title="Magazyn wewnętrzny"
                            value={selectedStoreDocs[0].internalStoreName}
                        /> : <></>}
                    {selectedStoreDocs[0].containersNumber != undefined ?
                        <OrderDetailTxt
                            title="Ilość kontenerów"
                            value={selectedStoreDocs[0].containersNumber}
                        /> : <></>}
                </div>
            )}
        </div>
    )
};

export default StoreDocsShowForm;