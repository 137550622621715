import api from "../../../config/axios/configAxios";

import { IEditPricing, IAddPricing } from "../models/index";

export const getPrices = async () => api.get(`/api/Admin/Prices`);

export const getPriceItem = async (key: any, id: string) =>
  api.get(`/api/Admin/Prices/${id}`);

export const editPricing = async ({
  orderTypeItemId,
  price,
  prices,
  postalCodePrices,
}: IEditPricing) =>
  api.put(`/api/Admin/Prices/${orderTypeItemId}`, {
    price,
    prices,
    postalCodePrices,
  });

export const addPricing = async ({
  orderTypeItemId,
  price,
  prices,
  postalCodePrices,
}: IAddPricing) =>
  api.post(`api/Admin/Prices/${orderTypeItemId}`, {
    price,
    prices,
    postalCodePrices,
  });

export const deletePriceById = async (id: string) =>
  api.delete(`/api/Admin/Prices/${id}`);

export const getGroupedPrices = async () => api.get(`/api/Admin/Prices/grouped-prices`)
 