import { setErrorAlert, clearAlerts, setListMessage } from './../../../config/redux/alerts/alertsActions';
import { useMemo, useState } from "react";
import { IResetForm } from "./../../common/models/resetForm.type";
import { FormikValues } from "formik";
import { editOperator } from "./../../../connectors/operators/connectors/index";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryCache } from "react-query";
import { toggleOperatorsPanel } from "../../../config/redux/operators/operatorsActions";

export const useEditOperator = (operatorId: string) => {
  const [loading, setIsLoading] = useState(false)
  const cache = useQueryCache();
  const dispatch = useDispatch();
  const { selectedOperator } = useSelector(
    (state: RootStateOrAny) => state.operators
  );
  const initValues = useMemo(() => {
    return operatorId ? selectedOperator : {};
  }, [operatorId]);

  const [mutate] = useMutation(editOperator, {
    onSuccess: () => {
      cache.invalidateQueries("operators");
      setIsLoading(false)
      dispatch(toggleOperatorsPanel(false));
    },
    onError: (err) => {
      throw new Error();
    },
  });

  const edit = async (values: FormikValues, { resetForm }: IResetForm) => {
    dispatch(clearAlerts())
    setIsLoading(true)
    const body = {
      operator: {
        name: values.name,
        isActive: values.isActive ? true : false,
      },
      operatorId: selectedOperator.id,
    };
    try {
      const data = await mutate(body);
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    } catch (err) {
      dispatch(setErrorAlert("Operator o podanej nazwie już istnieje"))
      setIsLoading(false)
    }
  };

  return {
    edit,
    initValues,
    loading,
    selectedOperator
  };
};
