import React from "react";

import OrdersList from "./OrdersList";
import OrderPanel from "./OrderPanel";
import CancelReason from "./CancelReason";
import RealizationError from "./RealizationError";
import { useLocation } from "react-router-dom";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import PrintingLoader from "./PrintingLoader";
import { connect, RootStateOrAny } from "react-redux";
import OrderOptimaPanel from "./OrderOptimaPanel";
import YearlyReportPanel from "./YearlyReportPanel";

export interface IStateProps {
  isPanelOpen: boolean,
}

const OrdersLayout: React.FC<IStateProps> = ({isPanelOpen}) => {
  const location = useLocation();

  return (
    <>
      <ListSuccessMessage />
      <OrdersList pathname={location.pathname} isPanelOpen={isPanelOpen}/>
      <OrderPanel />
      <YearlyReportPanel />
      <OrderOptimaPanel />
      <CancelReason />
      <RealizationError />
      <PrintingLoader/>
    </>
  );
};

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    isPanelOpen: state.orderPanel.orderPanelOpen,
  };
};

export default connect(mapStateToProps)(OrdersLayout);
