import * as React from "react";

import ActivityIndicator from "../features/common/layout/ActivityIndicator";

const Auth: React.FC = () => {
  return (
     <ActivityIndicator />
  );
};

export default Auth;
