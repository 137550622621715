import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react/";

import { panelS } from "../../common/styles/panelStyles";
import { toggleAdminsPanel } from "../../../config/redux/admins/adminsActions";
import AdminForm from "./AdminForm";



const AdminPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { adminsPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.admins
  );

  const dismissPanel = () => {
    dispatch(toggleAdminsPanel({ open: false }));
  };



  return (
    <Panel
      isOpen={adminsPanelOpen}
      closeButtonAriaLabel="Close"
      headerText="Administrator"
      onDismiss={dismissPanel}
      type={1}
      styles={panelS}
      isHiddenOnDismiss={false}
    >
      <AdminForm />
    </Panel>
  );
};

export default AdminPanel;