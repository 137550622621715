import {NavigationPath} from "../../../config/routing/NavigationPath";
import {INavLinkGroup} from "@fluentui/react/";

export const navLinkGroups: INavLinkGroup[] = [
    {
        links: [
            {
                name: 'Raporty',
                url: `${NavigationPath.REPORTS}`,
                key: `${NavigationPath.REPORTS}`,
            },
        ],
    },
];