import React from "react";
import { Text, ITextStyles } from "@fluentui/react/";

const textS: Partial<ITextStyles> = {
  root: {
    fontSize: 22,
    fontWeight: "600",
    paddingBottom: 20
  },
};

export interface PanelHeaderProps {
  text: string;
}

const PanelHeader: React.FC<PanelHeaderProps> = ({ text }) => {
  return <Text styles={textS}>{text}</Text>;
};

export default PanelHeader;
