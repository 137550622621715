import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react/";

export interface DialogErrProps {
  message: string;
}

const DialogErr: React.FC<DialogErrProps> = ({ message }) => {
  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={true}
      dismissButtonAriaLabel="Close"
    >
      {message}
    </MessageBar>
  );
};

export default DialogErr;
