import * as React from "react";
import { Stack, IStackStyles } from "@fluentui/react/";
import { RootStateOrAny, useSelector } from "react-redux";
import { MessageBar, MessageBarType } from "@fluentui/react/";
import { useDispatch } from "react-redux";
import { clearAlerts } from "../../../../../config/redux/alerts/alertsActions";

const messageContinerS: IStackStyles = {
  root: {
    width: "100%",
    // height: "70px",
    marginTop: 20,
    marginBottom: 20,
  },
};

const MessageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { errorMessage, successMessage } = useSelector(
    (state: RootStateOrAny) => state.alerts
  );

  const dismiss = () => {
    dispatch(clearAlerts());
  };

  return (
    <Stack styles={messageContinerS}>
      {errorMessage && (
        <MessageBar onDismiss={dismiss} messageBarType={MessageBarType.error}>
          {errorMessage}
        </MessageBar>
      )}
      {successMessage && (
        <MessageBar onDismiss={dismiss} messageBarType={MessageBarType.success}>
          {successMessage}
        </MessageBar>
      )}
    </Stack>
  );
};

export default MessageContainer;