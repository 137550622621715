import React from "react";
import { Text } from "@fluentui/react/";
import {
  cos,
  textContainer,
  textS,
} from "../../styles/pricingParagraph.styles";

export interface PricingParagraphProps {
  paragraphs: Array<string>;
}

const PricingParagraph: React.FC<PricingParagraphProps> = ({ paragraphs }) => {
  return (
    <div className={textContainer}>
      {paragraphs.map((paragraph: string) => {
        return (
          <div className={cos} key={paragraph}>
            <Text styles={textS}>{paragraph}</Text>
          </div>
        );
      })}
    </div>
  );
};

export default PricingParagraph;
