import React from"react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel} from "@fluentui/react/";
import { clear } from "../../../config/redux/orderTypes/orderTypesActions";
import {panelS} from "../../common/styles/panelStyles"
import TypeForm from "./TypeForm";
import { useOrderTypeForm } from "../hooks/useOrderTypeForm";

const OrderTypesPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { editing } = useSelector(
    (state: RootStateOrAny) => state.orderTypes
  );

  const dissmisPanel = () => {
    dispatch(clear());
  };

  const {
    handleSubmit,
    initValues,
    cancelForm,
    selectedOrderTypes,
    loading,
  } = useOrderTypeForm();
  
  return (
    <Panel
      headerText={selectedOrderTypes[0] ? "Edytuj typ zamówenia" : "Dodaj typ zamówienia"}
      isOpen={editing}
      closeButtonAriaLabel="Close"
      type={1}
      onDismiss={dissmisPanel}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      styles={panelS}
      isFooterAtBottom={true}
    >
      <TypeForm 
        handleSubmit={handleSubmit}
        initValues={initValues}
        cancelForm={cancelForm}
        selectedOrderTypes={selectedOrderTypes}
        loading={loading}
      />
    </Panel>
  );
};

export default OrderTypesPanel;
