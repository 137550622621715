import * as React from "react";
import {
  Stack
} from "@fluentui/react/";

import Logo from "./Logo";
import SearchBoxClients from "./SearchBoxClients";
import Interface from "./Interface";
import {reloadData} from "../../../../config/redux/clients/clientsActions"
import {reloadOrders} from "../../../../config/redux/orders/ordersActions"
import {reloadData as reloadStoreDocs} from "../../../../config/redux/storeDocs/storeDocsActions"
import { useLocation } from "react-router-dom";
import SearchBoxOrders from "./SearchBoxOrders";
import SearchBoxStoreDocs from "./SearchBoxStoreDocs";
import SearchBoxStore from "./SearchBoxStore";
import { reloadStoreItems } from "../../../../config/redux/store/storeAction";
import {myTheme} from "../../../../client/styles/layout/theme";

const theme = myTheme;

const containerS = {
  root: {
    backgroundColor: theme.palette.themePrimary,
    width: "100%",
    height: "50px",
    padding: "10px 25px",
    zIndex: 2000,
    position: "fixed",
    top: 0,
    left: 0,
    boxShadow: '0 5px 5px -5px #aaa',
  },
};

const sectionS = {
  root: {
    width: "100%",
    marginRight: "70px",
  },
};

const Header: React.FC = () => {
  const location = useLocation()

  return (
    <Stack
      styles={containerS}
      verticalAlign="center"
      horizontal
      horizontalAlign="space-between"
      theme={myTheme}
    >
      <Logo />
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        styles={sectionS}
      >
        {location.pathname.split("/")[1] === "clients" && <SearchBoxClients reload={reloadData}/>}
        {location.pathname.split("/")[1] === "orders"  && <SearchBoxOrders reload={reloadOrders}/>}
        {/*{(location.pathname.split("/")[1] === "store" && location.pathname.split("/")[2] === "docs")  && <SearchBoxStoreDocs reload={reloadStoreDocs}/>}*/}
        {/*{(location.pathname.split("/")[1] === "store" && location.pathname.split("/")[2] === "list")  && <SearchBoxStore reload={reloadStoreItems}/>}*/}
      </Stack>
      <Interface />
    </Stack>
  );
};

export default Header;