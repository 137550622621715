import { clearAlerts, setListMessage, setErrorAlert } from './../../../config/redux/alerts/alertsActions';
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { FormikValues } from "formik";

import {
  toggleDriversPanel,
} from "../../../config/redux/drivers/driversActions";
import { IResetForm } from "../../common/models/resetForm.type";
import { useState } from "react";
import { toggleSyncOrderPanel } from '../../../config/redux/orders/ordersPanel/orderPanelActions';
import { syncOrderWithOptima } from '../../../connectors/orderTypes/connectors';
import { reloadOrders } from '../../../config/redux/orders/ordersActions';

export const useSyncOrderForm = () => {
  const [loading, setIsLoading] = useState(false)
  const { selectedOrders } = useSelector((state: RootStateOrAny) => state.orders);

  const dispatch = useDispatch();
  let initValues = {
    numberOptima: "",
  };

  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true)
    const body = {
        numberOptima: values.numberOptima,
        orderId: selectedOrders[0].id
    };
    try {
      dispatch(clearAlerts())
      await syncOrderWithOptima(body);
      setIsLoading(false)
      resetForm();
      dispatch(toggleSyncOrderPanel(false));
      dispatch(reloadOrders(true));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    } catch (err) {
      setIsLoading(false)
      dispatch(setErrorAlert("Wystąpił błąd"))
    }
  };

  const cancelForm = () => {
    dispatch(toggleDriversPanel({ open: false }));
    dispatch(clearAlerts())
  };

  return {
    handleSubmit,
    initValues,
    cancelForm,
    loading
  };
};
