import { addNewClient } from "./../../../config/redux/clients/clientsActions";
import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { getCompanies } from "../../../connectors/clients/connectors/index";
import { reloadData } from "../../../config/redux/clients/clientsActions";
import { toggleFiltration } from "../../../config/redux/filters/filterActions";
import { IPaginationProps, IPaginationState } from "./pagination.types";
import { getParams } from "../actions/getClientsParams";
import { generateItems } from "../actions/generateClients";
import { AxiosResponse } from "axios";
import { column } from "../../orders/styles/readOnlyDetails.styles";

class Pagination extends React.Component<IPaginationProps, IPaginationState> {
  private _data: any = [];

  footerRef = React.createRef<HTMLDivElement>();

  state = {
    data: [],
    limit: 35,
    total: 0,
    page: 1,
    loading: false,
  };

  componentDidMount() {
    if (this.props.footerRef.current !== null) {
      this._observer.observe(this.props.footerRef.current);
    }

    this._setClients();
  }

  componentDidUpdate(prevProps: IPaginationProps) {
    const { reload, searchPhrase, filter, add, column, dispatch } = this.props;

    if (reload) {
      this._reload();
      this._removeItems();
    }

    // console.log(filter);
    if (filter) {
      this._reload();
      if (column) {
        this._filterClients(
          searchPhrase,
          // column.key,
          column.fieldName,
          column.isSortedDescending
        );
      } else {
        this._filterClients(searchPhrase);
      }

      dispatch(toggleFiltration({ filter: false }));
    }

    if (add) {
      this._setClients(1);
      dispatch(addNewClient(false));
    }
  }

  componentWillUnmount() {
    if (this.props.footerRef.current !== null) {
      this._observer.unobserve(this.props.footerRef.current);
    }
  }

  private _handleObserver = (entry: any, observer: any) => {
    if (this.state.data.length < this.state.total) {
      this._setClients();
    }
  };

  private _observer = new IntersectionObserver(this._handleObserver, {
    root: null,
    rootMargin: "200px",
    threshold: 1,
  });

  private _reload = async () => {
    this.props.dispatch(reloadData({ reload: false }));
  };

  private _removeItems = () => {
    const clients = _.remove(this.state.data, (client: any) => {
      if (!this.props.selectedClients.includes(client.id)) {
        return client;
      }
    });

    this.setState({ data: [...clients] });
  };

  private _filterClients = async (
    searchPhrase: string,
    orderBy: undefined | string = undefined,
    desc: undefined | boolean = false
  ) => {
    const { limit } = this.state;
    const params = getParams(this.props.location.pathname);

    var res: AxiosResponse<any>;
    if (orderBy !== undefined) {
      orderBy += desc ? " Desc" : " Asc";
      res = await getCompanies({
        page: 1,
        limit,
        ...params,
        Sorting: orderBy,
        Keyword: searchPhrase,
      });
    } else {
      res = await getCompanies({
        page: 1,
        limit,
        ...params,
        Keyword: searchPhrase,
      });
    }
    const clients = generateItems(res.data.result);
    this.setState((prevState: IPaginationState) => {
      return {
        data: [...clients],
        page: 2,
        total: res.data.total,
      };
    });
  };

  private _setClients = async (newPage?: number) => {
    const { searchPhrase, column } = this.props;
    const { limit, page } = this.state;
    const params = getParams(this.props.location.pathname);
    const p = newPage ? newPage : page;
    let res: any;
    // this.setState({loading: true})
    if (column) {
      res = await getCompanies({
        page: p,
        limit,
        ...params,
        Desc: column.isSortedDescending,
        Keyword: searchPhrase,
      });
    } else {
      res = await getCompanies({
        page: p,
        limit,
        ...params,
        Keyword: searchPhrase,
      });
    }
    const clients = generateItems(res.data.result);
    this.setState((prevState: IPaginationState) => {
      return {
        data: newPage ? [...clients] : [...prevState.data, ...clients],
        page: newPage ? newPage : prevState.page + 1,
        total: res.data.total,
        // loading: false
      };
    });
  };

  public render() {
    const renderProps = {
      data: this.state.data,
      loading: this.state.loading,
    };

    return this.props.render(renderProps);
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    reload: state.clients.reload,
    add: state.clients.addNewClient,
    searchPhrase: state.filtration.searchPhrase,
    selectedClients: state.clients.selectedClients,
    filter: state.filtration.filter,
    column: state.filtration.column,
  };
};

export default connect(mapStateToProps)(withRouter(Pagination));
