import { IInitState } from "./initState.type";
import { IClientPanelAction } from "./clientPanelActions.types";
import { ClientPanelActions } from "./clientPanelActions.enum";
import { TabsNames } from "../../../../features/clients/components/edditionPanel/CTabs";

const initState: IInitState = {
  editing: false,
  activeTab: TabsNames.BASIC_INFO,
  activeUser: {},
  activeDepartment: {},
  successMessage: "",
  activePickUpPoint: "",
  activeFile : {},
  selectedIds: [],
  reload: false,
  refetch: false,
  basicInfo: "",
  clientPanelOpen: false,
};

export const clientPanelReducer = (
  state = initState,
  action: IClientPanelAction
) => {
  const { type, payload } = action;

  switch (type) {
    case ClientPanelActions.TOGGLE_EDITING:
      return { ...state, editing: payload.editing };
    case ClientPanelActions.TOGGLE_CLIENTS_PANEL:
      return { ...state, clientPanelOpen: payload };
    case ClientPanelActions.SET_ACTIVE_TAB:
      return { ...state, activeTab: payload };
    case ClientPanelActions.SET_ACTIVE_USER:
      return { ...state, activeUser: payload, editing: true };
    case ClientPanelActions.SET_ACTIVE_DEPARTMENT:
      return { ...state, activeDepartment: payload, editing: true };
    case ClientPanelActions.SET_ACTIVE_PICKUPPOINT:
      return { ...state, activePickUpPoint: payload, editing: true };
    case ClientPanelActions.SET_ACTIVE_FILES:
      return { ...state, activeFile: payload, editing: true}
    case ClientPanelActions.SET_SELECTED_IDS:
      return { ...state, selectedIds: [...payload.selectedIds] };
    case ClientPanelActions.RELOAD:
      return { ...state, reload: payload.reload };
    case ClientPanelActions.SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: payload.message };
    case ClientPanelActions.REFETCH:
      return { ...state, refetch: payload };
    case ClientPanelActions.SET_BASIC_INFO:
      return { ...state, basicInfo: payload };
    case ClientPanelActions.CLEAR:
      return {
        ...state,
        selectedIds: [],
        editing: false,
        activeUser: "",
        successMessage: "",
        activeDepartment: "",
        activePickUpPoint: "",
        activeFile: ""
      };
    default:
      return state;
  }
};
