import React, { useCallback } from "react";
import {
  Nav,
  INavStyleProps,
  IScrollablePaneStyles,
  getTheme,
  INavStyles,
  ScrollablePane,
  FontSizes,
} from "@fluentui/react/";
import { useHistory, useLocation } from "react-router-dom";

import { navLinkGroups } from "./links";

const theme = getTheme();
const msGray = 'rgb(243, 242, 241)';

const containerS: Partial<IScrollablePaneStyles> = {
  root: {
    width: "230px",
    borderRight: `2px solid ${theme.palette.neutralLighter}`,
    height: "calc(100vh - 50px)",
    position: "fixed",
    top: 50,
    left: 0,
    boxSizing: "border-box",
    backgroundColor: '#fff',
    // boxShadow: '5px 0 5px -5px #ccc',
    zIndex: 1000
  },
};

const navS = (props: INavStyleProps): Partial<INavStyles> => ({
  chevronButton: {
    borderBottom: "none",
    fontWeight: 600,
    fontSize: FontSizes.medium 
  },

  chevronIcon: [
    {
      transform: "rotate(-90deg)",
    },
    props.isExpanded && { transform: " rotate(0)" },
  ],

  link: {
    paddingLeft: "35px",
  },
  group: {
    backgroundColor: '#fff',
  },
  groupContent: {
    backgroundColor: '#fff',
    marginBottom: '0'
  },
  navItem: {
    backgroundColor: '#fff',
  },
  navItems: {
    backgroundColor: '#fff',
  },
  compositeLink: {
    backgroundColor: '#fff',
  },
  
});

const Navigation: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const handleLinkClick = useCallback((e: any, element: any) => {
    const {key} = element
      e.preventDefault()
      history.push(`${key}`)
  }, [])

  return (
    <ScrollablePane styles={containerS}>
      <Nav
        groups={navLinkGroups}
        styles={navS}
        onLinkClick={handleLinkClick}
        selectedKey={location.pathname}
      />
    </ScrollablePane>
  );
};

export default Navigation;
