import { Form, Formik } from "formik";
import { IDropdownOption, Label, mergeStyles, Stack } from "@fluentui/react";
import * as React from "react";
import MessageContainer from "../../../features/auth/components/register/forms/MessageContainer";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import FormDatePicker from "../../../features/common/layout/form/FormDatePicker";
import FormDropDown from "../../../features/common/layout/form/FormDropDown";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import { reportClientValidation } from "../../../features/orders/actions/validationSchemas";
import { useOrdersReportForm } from "../../hooks/useOrdersReportForm";

const margin = mergeStyles({
  marginTop: 15,
});

export interface ReceOrderProps {}

const OrdersReportClientForm: React.FC<ReceOrderProps> = () => {
  const { 
    handleSubmit, 
    cancelForm, 
    isLoading, 
  } = useOrdersReportForm();

  const options: IDropdownOption[] = [
    { key: 0, text: 'Nowe' },
    { key: 1, text: 'Przyjęte' },
    { key: 2, text: 'W trakcie realizacji' },
    { key: 3, text: 'Zrealizowane' },
    { key: 4, text: 'Anulowane' },
  ];
  const optionsSort: IDropdownOption[] = [
    { key: 'true', text: 'Malejąco' },
    { key: 'false', text: 'Rosnąco' },
  ];
  const optionsOrderBy: IDropdownOption[] = [
    { key: 'Number', text: 'Numer zamówienia' },
    { key: 'CreatedAt', text: 'Data zamówienia' },
    { key: 'ReceptionPlace', text: 'Klient' },
    { key: 'CurrentStatus', text: 'Status zamówienia' },
  ];

  return (
    <Formik
      initialValues={{}}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={reportClientValidation}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, values }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack verticalAlign="space-between" verticalFill>
            <Label required>Data zamówienia</Label>
          <Stack horizontal>
          
              <FormDatePicker placeholder="Min" name="createdFrom" values={values} required/>
              <div style={{ padding: '2px', lineHeight: 2 }}>&#8211;</div>
              <FormDatePicker placeholder="Max" name="createdTo" values={values} required/>
          
            </Stack>
            <div >
             <FormDropDown required options={options} name="currentStatus" headerText="Status zamówienia" placeholder="Wybierz status"/>
            </div>
            <div >
            <FormDropDown required options={optionsOrderBy} name="orderBy" headerText="Sortuj po" placeholder="Wybierz kolumnę"/>
            </div>
            <div >
              <FormDropDown required options={optionsSort} name="desc" headerText="Sortuj" placeholder="Wybierz kierunek"/>
            </div>
            <div className={margin}> 
              <NewFormBtns 
                submitBtnText={"Pobierz"} 
                cancelForm={cancelForm} 
                disabled={isLoading}
                positionAbsolute={true}
                /> 
            </div>
            <div className={margin}>
              {isLoading ? <ActivityIndicator /> : <MessageContainer />}
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default OrdersReportClientForm;