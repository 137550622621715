import { Form, Formik } from "formik";
import { mergeStyles, Stack } from "@fluentui/react";
import * as React from "react";
import { PickUpPointSchema } from "../../../features/clients/actions/validationSchemas";
import FormHeader from "../../../features/clients/components/edditionPanel/FormHeader";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import ErrorMessage from "../../../features/common/layout/form/ErrorMessage";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import NewFormCheckbox from "../../../features/common/layout/form/NewFormCheckbox";
import NewFormField from "../../../features/common/layout/form/NewFormField";
import { ReportsForm } from "../../hooks/ReportsForm";

const margin = mergeStyles({
  marginTop: 15,
})

const padding = mergeStyles({
  paddingTop: 15,
})

export interface ReceptionPlaceFormProps {
  predefinedInitValues?: any;
}

const ReceptionPlaceForm: React.FC<ReceptionPlaceFormProps> = ({ predefinedInitValues }) => {
  const {
    handleSubmit,
    cancelForm,
    selectedItem,
    loading,
    initValues,
  } = ReportsForm();

  return (
    <Formik
      initialValues={!!initValues && Object.keys(initValues).length > 0 ? initValues : (!!predefinedInitValues && Object.keys(predefinedInitValues).length > 0 ? predefinedInitValues : initValues)}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={PickUpPointSchema}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack verticalAlign="space-between" verticalFill>
            <FormHeader
              text={
                selectedItem ? "Edytuj punkt odbioru" : "Dodaj punkt odbioru"
              }
            />
            {selectedItem && sessionStorage.getItem("role") !== "ADMIN" ? null :
              <>
                <NewFormField name="city" label="Miejscowość" />
                <NewFormField name="street" label="Ulica i numer" />
                <NewFormField name="postalCode" label="Kod pocztowy" />
                <NewFormField name="bdoNumber" label="BDO" />
              </>}
            <NewFormField name="phone" label="Telefon" />

            {/* <FormDropDown
                  headerText="Typ klienta"
                  name="orderTypeId"
                  options={orderTypes}
                /> */}
            <div className={padding}>
              <NewFormCheckbox name="default" label="Domyślny punkt odbioru" />
            </div>
            <div className={margin}>
              <NewFormBtns
                submitBtnText={selectedItem ? "Zapisz" : "Dodaj"}
                cancelForm={cancelForm}
                positionAbsolute={true}
              />
            </div>
            {loading ? <ActivityIndicator /> : <ErrorMessage />}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default ReceptionPlaceForm;