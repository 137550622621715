import {IQuantity, Quantity} from "../../../common/models/quantity.model";

interface IActions {
    setDownloadPdf: () => void;
}

export const generateItems = (quantity: IQuantity, actions: IActions, selectedItem: any) => {
    console.log(quantity)
    switch (quantity) {
        case Quantity.NONE:
            return [];
        case Quantity.ONE:
            if (selectedItem[0].isActive) {
                return [
                    {
                        key: "download",
                        text: "Pobierz",
                        iconProps: { iconName: "ReportDocument" },
                        onClick: actions.setDownloadPdf,
                      },
                ]
            } else {
                return []
            }
        default:
            return [];
    }
};
