import { FormikValues } from "formik";
import { useEffect, useMemo, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { clearReports, reloadReports, setSelectedReports, toggleReportsPanel } from "../../../config/redux/reports/reportsAction";
import { IResetForm } from "../../common/models/resetForm.type";
import { reportParameterType } from "../model/reportParameterType.enum";
import { getOrderTypes } from "../../../connectors/orderTypes/connectors";
import { getOperators } from "../../../connectors/operators/connectors";
import { setListErr, setListMessage } from "../../../config/redux/alerts/alertsActions";
import { GetReportFile } from "../../../connectors/reports/connectors";
import moment from "moment";
import { IReportColumn } from "../interfaces/IReportColumn";
import { IReportParameter } from "../interfaces/IReportParameter";

export const useReportForm = () => {
    const dispatch = useDispatch();
    const [loading, setIsLoading] = useState(false);
    const [orderTypes, setOrderTypes] = useState({})
    const [operators, setOperators] = useState({})
    const { reportDetails } = useSelector(
        (state: RootStateOrAny) => state.reports
    );

    var format = "YYYY-MM-DD";
    const date = new Date();
    const time = date.getTime();
    const tenDays = 1000 * 60 * 60 * 24 * 10;
    const tenDaysAgo = time - tenDays;

    const firstGroupBy = "FirstGroupBy";
    const secondGroupBy = "SecondGroupBy";
    
    const initValues = useMemo(() => {
        if (reportDetails.reportColumns) {
            var tempInitValues: any = {
                DateFrom: moment(tenDaysAgo).format(format),
                DateTo: moment(time).format(format),
                isDesc: true,
                orderBy: ""
            }
            reportDetails.reportColumns.map((col: IReportColumn) => {
                tempInitValues[col.name] = true
            })
            var reportColumnsCount = reportDetails.reportColumns.filter((col: IReportColumn) => col.canBeGrouped == true).length
            if (reportColumnsCount >= 1) {
                tempInitValues[firstGroupBy] = "";
                tempInitValues[secondGroupBy] = "";
            }
            return tempInitValues;
        }
    }, [reportDetails.reportColumns])

    useEffect(() => {
        if (reportDetails.reportParameters == undefined) return;
        reportDetails.reportParameters.map((reportParameter: IReportParameter) => {
            switch (reportParameter.type) {
                case reportParameterType.RecykleOperators:
                    setOperatorsState();
                    return;
                case reportParameterType.OrderTypes:
                    setOrderTypesState();
                    return;
            }
        })
    }, [reportDetails.reportParameters])

    const setOrderTypesState = async () => {
        let orderTypes = await getOrderTypes();
        var data: any[] = []
        orderTypes.data.data.map((ord: any) => {
            data.push({
                key: ord.id,
                text: ord.name
            })
        })
        setOrderTypes(data);
    }

    const setOperatorsState = async () => {
        let operators = await getOperators();
        var data: any[] = []
        operators.data.data.map((ord: any) => {
            data.push({
                key: ord.id,
                text: ord.name
            })
        })
        setOperators(data);
    }

    const cancelForm = () => {
        dispatch(clearReports());
        dispatch(toggleReportsPanel({ open: false }));
    };

    const handleSubmit = async (values: FormikValues, { resetForm }: IResetForm) => {
        setIsLoading(true);
        try {
            var query = "";
            var columnsInitStr = "ExcludedColumns=";
            var columns = columnsInitStr;
            for (let key in values) {
                if (reportDetails.reportParameters.find((reportParamter: IReportParameter) => reportParamter.name == key)) {
                    if (key == "Companies") {
                        query += "\@" + key + "=" + values[key].key + "&";
                    }
                    else if (key == "DateFrom" || key == "DateTo") {
                        if (reportDetails.reportParameters.find((reportParamter: IReportParameter) => reportParamter.name == "DateFrom" || reportParamter.name == "DateTo"
                        )) {
                            query += "\@" + key + "=" + values[key] + "&";
                        }
                    }
                    else {
                        query += "\@" + key + "=" + values[key] + "&";
                    }
                }
                else if (reportDetails.reportColumns.find((reportColumn: IReportColumn) => reportColumn.name == key)) {
                    if (values[key] == false) {
                        columns += key + ","
                    }
                }
            }
            if (columns.endsWith(",")) {
                columns = columns.slice(0, -1);
            }
            if (columns != columnsInitStr) {
                columns += "&"
                query += columns;
            }

            if(values["orderBy"] != ""){
                query += "OrderBy=";
                query += values["orderBy"];
                query += "&"
                query += "IsDesc="

                query += (values["isDesc"] == true ? "true" : "false");
                query += "&"
            }
            if (values[firstGroupBy] != "") {
                query += "GroupBy=";
                query += values[firstGroupBy];
                if (values[secondGroupBy] != "") {
                    query += ",";
                    query += values[secondGroupBy];
                }
                query += "&"
            }
            else if (values[firstGroupBy] == "" && values[secondGroupBy] != "") {
                query += "GroupBy=";
                query += values[secondGroupBy];
                query += "&"
            }
            query += "ReportOutput=2&Format=1"
            await GetReportFile(reportDetails.name, reportDetails.reportId, query);
            dispatch(reloadReports({ reload: true }));
            resetForm();
            dispatch(toggleReportsPanel({ open: false }));
            dispatch(setListMessage("Pomyślnie pobrano raport"));
            dispatch(setSelectedReports([]));

        } catch (err) {
            dispatch(reloadReports({ reload: true }));
            resetForm();
            dispatch(toggleReportsPanel({ open: false }));
            dispatch(setListErr("Błąd podczas pobierania raportu"));
            dispatch(setSelectedReports([]));
        }
        setIsLoading(false);
    }

    return {
        loading,
        orderTypes,
        operators,
        initValues,
        cancelForm,
        handleSubmit
    }
}