import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  toggleOrderPanel,
  setSelectedListItem,
} from "./../../config/redux/client/clientActions";

export const useOrdersList = () => {
  const { selectedItem } = useSelector((state: RootStateOrAny) => state.client);
  const dispatch = useDispatch();

  const setPanelOpen = () => {
    dispatch(toggleOrderPanel({isOpen: true, formActive: false, editOrder: false}));
  };

  const setItemOnSelection = (arr: Array<string>) => {
    dispatch(setSelectedListItem(arr[0]));
  };

  return {
    setPanelOpen,
    setItemOnSelection,
    selectedItem,
  };
};
