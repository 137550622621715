// import {INavLinkGroup} from "@fluentui/react";

import {NavigationPath} from "../../../config/routing/NavigationPath";
import {LinksNames} from "../models/LinkNames.enum"

export const navLinkGroups = [
    {
        name: "Raporty",
        links: [
            {
                key: `${NavigationPath.REPORTS_WAITING}`,
                name: `${LinksNames.REPORTS_WAITING}`,
                url: "",
            },
            {
                key: `${NavigationPath.REPORTS_APPROVED}`,
                name: `${LinksNames.REPORTS_APPROVED}`,
                url: "",
            },
            {
                key: `${NavigationPath.REPORTS_REJECTED}`,
                name: `${LinksNames.REPORTS_REJECTED}`,
                url: "",
            },
            {
                key: `${NavigationPath.REPORTS_ALL}`,
                name: `${LinksNames.REPORTS_ALL}`,
                url: "",
            },
        ]
    },
    {
        name: "Użytkownicy",
        links: [
            // {
            //     key: `${NavigationPath.UNVERIFIED_CLIENTS}`,
            //     name: `${LinksNames.UNVERIFIED_CLIENTS}`,
            //     url: "",
            // },
            {
                key: `${NavigationPath.ACTIVE_CLIENTS}`,
                name: `${LinksNames.ACTIVE_CLIENTS}`,
                url: "",
            },
            {
                key: `${NavigationPath.INACTIVE_CLIENTS}`,
                name: `${LinksNames.INACTIVE_CLIENTS}`,
                url: "",
            },
        ],
    },
    {
        name: "Ustawienia",
        collapseByDefault: true,
        links: [
            {
                key: `${NavigationPath.YEARS_MANAGEMENT}`,
                name: `${LinksNames.YEARS_MANAGEMENT}`,
                url: "",
            },
        ],
    },
];