import * as React from "react";
import {
    CommandBar,
} from "@fluentui/react/";
import { comandBarS } from "./../../../features/commandBar/styles/commandBar.styles";
import { useStoreDocsCommandBar } from "./../hooks/useStoreDocsCommandBar";

const StoreDocsCommandBar: React.FC = () => {
    const { items } = useStoreDocsCommandBar()

    return <CommandBar items={items} styles={comandBarS} />;
}

export default StoreDocsCommandBar;