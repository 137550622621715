import React from "react";
import { mergeStyles } from "@fluentui/react/";
import OperatorPanel from "./panel/OperatorPanel";
import OperatorsListContainer from "./list/OperatorListContainer";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";

const wrapper = mergeStyles({
  width: "95%",
  margin: "0px auto",
});

const padding = mergeStyles({
  paddingTop: 15
})

export interface OperatorsProps {}

const Operators: React.FC<OperatorsProps> = () => {
  return (
    <div className={wrapper}>
      <div className={padding}>
        <ListSuccessMessage />
      </div>
      <OperatorsListContainer />
      <OperatorPanel />
    </div>
  );
};

export default Operators;
