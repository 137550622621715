import { clientReducer } from './client/clientReducer';
import { adminsReducer } from './admins/adminsReducer';
import { cancellationReasonsReducer } from './cancellationReasons/cancelationsReasonsReducer';
import { orderPanelReducer } from './orders/ordersPanel/orderPanelReducer';
import { combineReducers } from "redux";

import { register } from "./register/registerReducer";
import { login } from "./login/loginReducer";
import { clientsReducer } from "./clients/clientsReducer";
import { filtersReducer } from "./filters/filtersReducer";
import { clientPanelReducer } from "./clients/panel/clientPanelReducer"
import { orderTypesReducer } from "./orderTypes/orderTypesReducer"
import { ordersReducer } from "./orders/ordersReducer"
import { vehiclesReducer } from "./vehicles/vehiclesReducer"
import { driversReducer } from "./drivers/driversReducer"
import { alertsReducer } from "./alerts/alertsReducer"
import { pricingReducer } from "./pricing/pricingReducer"
import { operatorsReducer } from "./operators/operatorsReducer"
import { usersReducer } from "./users/usersReducer"
import { unactiveusersReducer } from "./unactiveusers/unactiveusersReducer"
import { reportsReducer } from './reports/reportsReducer';
import { unactivepickuppointsReducer } from "./unactivepickuppoints/unactivepickuppointsReducer"
import { storeReducer } from './store/storeReducer';
import {storeDocsReducer} from './storeDocs/storeDocsReducer';
import { storeMapReducer } from './storeMap/storeMapReducer';

const rootReducer = combineReducers({
    register,
    login,
    clients: clientsReducer,
    clientPanel: clientPanelReducer,
    filtration: filtersReducer,
    orderTypes: orderTypesReducer,
    orders: ordersReducer,
    orderPanel: orderPanelReducer,
    vehicles: vehiclesReducer,
    drivers: driversReducer,
    alerts: alertsReducer,
    pricing: pricingReducer,
    operators: operatorsReducer,
    cancellationReasons: cancellationReasonsReducer,
    client: clientReducer,
    admins: adminsReducer,
    users: usersReducer,
    unactiveusers: unactiveusersReducer,
    unactivepickuppoints: unactivepickuppointsReducer,
    reports: reportsReducer,
    store: storeReducer,
    storeDocs: storeDocsReducer,
    storeMap: storeMapReducer
});

export default rootReducer;