import * as React from "react";
import DisplayText from "./DisplayTxt";

export interface ReceptionDataDisplayProps {
  selectedItem: any;
}

const ReceptionDataDisplay: React.FC<ReceptionDataDisplayProps> = ({
  selectedItem,
}) => {
  return (
    <div>
      <DisplayText title="Miejscowość" value={selectedItem.city} />
      <DisplayText title="Ulica i numer" value={selectedItem.street} />
      <DisplayText title="Kod pocztowy" value={selectedItem.postalCode} />
      <DisplayText title="Telefon" value={selectedItem.phone} />
      <DisplayText title="BDO" value={selectedItem.bdoNumber} />
      <DisplayText title="Typ klienta" value={selectedItem.orderType} />
      <DisplayText
        title="Domyślny punkt"
        value={selectedItem.isDefault ? "Tak" : "Nie"}
      />
    </div>
  );
};

export default ReceptionDataDisplay;
