import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setBasicInfo } from "../../../config/redux/clients/panel/clientPanelActions";
import { getUser } from "../../../connectors/clients/connectors";

export const useBasicInfo = () => {
  const dispatch = useDispatch();
  const { selectedClients, addNewClient} = useSelector(
    (state: RootStateOrAny) => state.clients
  );

  const {clientPanelOpen, refetch}  = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );

  useEffect(() => {
    if(clientPanelOpen){
      setUserData();
    }
  }, [clientPanelOpen, addNewClient, selectedClients[0], refetch]);

  const setUserData = async () => {
      const res = await getUser(selectedClients[0]);
    dispatch(setBasicInfo(res.data.result));
  };
};
