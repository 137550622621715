import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { checkQuantity } from "../../actions/checkQuantity";
import { generateItems } from "../../actions/generateCommands/generateYearlyReportCommands";
import { getAnnualRaportPDF } from '../../../../connectors/orders/connectors';

export const useYearlyReportCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedDrivers, reloadDriversList } = useSelector(
    (state: RootStateOrAny) => state.drivers
  );

  const setDownloadPdf = async () => {
    let id = selectedDrivers[0].id;
    await getAnnualRaportPDF(id)
  }

  const actions = {
      setDownloadPdf
  }

  const quantity = checkQuantity(selectedDrivers.length);

  const items = generateItems(quantity, actions, selectedDrivers)

  return {
    items,
  };
};