import React, {useCallback} from "react";
import {useSelector, RootStateOrAny, useDispatch} from "react-redux";
import {Pivot, PivotItem} from "@fluentui/react//lib/Pivot";

import ClientBasicInfo from "./displayData/ClientBasicInfo";
import PickUpPointsForm from "./forms/PickUpPointsForm";
import UsersForm from "./forms/UsersForm";
import DepartmentsForm from "./forms/DepartmentsForm";
import CompanyDetailData from "./CompanyDetailData";
import {
    departmentsColumns,
    generateDepartments,
} from "../colums/departments-columns";
import {generateUsers} from "../colums/users-columns";
import {
    pickUpPointsColumns,
    generatePickUpPoints,
    pickUpPointsColumnsShort,
} from "../colums/pickUpPoints-columns";
import {
    getDepartments,
    getUsers,
    getPickUpPoints,
    getCompaniesAttachments,
} from "../../../../connectors/clients/connectors/index";
import {
    setActiveDepartment,
    setActivePickUpPoint,
    setActiveUser,
    clearData,
    setActiveTab,
    setActiveFiles,
} from "../../../../config/redux/clients/panel/clientPanelActions";
import {clearAlerts} from "../../../../config/redux/alerts/alertsActions";
import BasicInfoForm from "./forms/BasicInfoForm";
import {DirectionalHint, FontIcon, TooltipDelay, TooltipHost} from "@fluentui/react/";
import FilesUploadForm from "./forms/FilesUploadForm";
import {filesColumns, generateFilesList} from "../colums/files-columns";

export enum TabsNames {
    BASIC_INFO = "Informacje podstawowe",
    PICKUP_POINTS = "Punkty odbioru",
    USERS = "Użytkownicy",
    DEPARTMENTS = "Oddziały",
    FILES = "Pliki"
}

const ClientTabs: React.FC = () => {
    const dispatch = useDispatch();
    const {editing, reload, basicInfo} = useSelector(
        (state: RootStateOrAny) => state.clientPanel
    );

    const handleClick = useCallback((e: any) => {
        dispatch(setActiveTab(e.props.id));
        dispatch(clearAlerts());
        dispatch(clearData());
    }, []);

    return (
        <>
            {basicInfo && (editing ? <BasicInfoForm/> : <ClientBasicInfo/>)}
        </>
    );
};

export default ClientTabs;
