import { RootStateOrAny, useSelector } from "react-redux";
import React from "react";
import OrderDetailTxt from "../../orders/components/forms/edditOrder/OrderDetailTxt";
import { divider, mainWrapper, wrapper } from "../styles/style"

const ReportReadOnlyDetails: React.FC = () => {
    const { reportDetails } = useSelector(
        (state: RootStateOrAny) => state.reports
    );
    return (
        <div className={mainWrapper}>
            <div className={wrapper}>
                <div className={divider}>
                    <OrderDetailTxt
                        title="Nazwa Raportu"
                        value={reportDetails ? reportDetails.name : ""}
                    />
                </div>
                <div className={divider}>
                    <OrderDetailTxt
                        title="Opis raportu"
                        value={reportDetails ? reportDetails.description : ""}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReportReadOnlyDetails;