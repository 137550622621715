import { setSelectedOperator } from './../../../../config/redux/operators/operatorsActions';
import { setErrorAlert, setListErr, setListMessage, clearListMessages } from './../../../../config/redux/alerts/alertsActions';
import { Quantity } from "./../../../common/models/quantity.model";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import { generateItems } from "../../actions/generateCommands/generateOperatorsCommands";
import { toggleOperatorsPanel } from "../../../../config/redux/operators/operatorsActions";
import { deleteOperatorById } from "../../../../connectors/operators/connectors";
import { useMutation, useQueryCache } from "react-query";

export const useOperatorsCommandBar = () => {
  const dispatch = useDispatch();
  const cache = useQueryCache();
  const { selectedOperator } = useSelector(
    (state: RootStateOrAny) => state.operators
  );

  const openPanelToAdd = () => {
    dispatch(setSelectedOperator(""))
    dispatch(toggleOperatorsPanel(true));
  }

  const openPanelToEdit = () => {
    dispatch(toggleOperatorsPanel(true));
  };


  const [mutate] = useMutation(deleteOperatorById, {
    onSuccess: () => {
      cache.invalidateQueries("operators");
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
      dispatch(setSelectedOperator(""))
    },
    onError: (err) => {
      throw new Error();
    },
  });

  const deleteOperator = async () => {
    dispatch(clearListMessages())
    try{
      await mutate(selectedOperator.id);
    }catch(err){
      dispatch(setListErr("Operator przypisany jest do aktywnego zamówienia"))
    }
  };

  const actions = {
    openPanelToAdd,
    openPanelToEdit,
    deleteOperator,
  };

  const quantity = selectedOperator ? Quantity.ONE : Quantity.NONE;
  const items = generateItems(quantity, actions);

  return {
    items,
  };
};
