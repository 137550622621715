import { createTheme } from '@fluentui/react';

export const myTheme = createTheme({
    palette: {
        themePrimary: '#298c0a',
        themeLighterAlt: '#f3faf1',
        themeLighter: '#d2edc9',
        themeLight: '#addd9f',
        themeTertiary: '#6bba52',
        themeSecondary: '#399a1c',
        themeDarkAlt: '#247e09',
        themeDark: '#1f6b07',
        themeDarker: '#174f05',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});