import { setListMessage } from './../../../../config/redux/alerts/alertsActions';
import {reloadDrivers, setDriver, setSelectedDrivers} from './../../../../config/redux/drivers/driversActions';
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import {toggleDriversPanel} from "../../../../config/redux/drivers/driversActions"
import { checkQuantity } from "../../actions/checkQuantity";
import { generateItems } from "../../actions/generateCommands/generateDriversCommands";
import {markAsActive, markAsInActive, deleteYears} from "../../../../connectors/drivers/connectors";

export const useDriversCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedDrivers, reloadDriversList } = useSelector(
    (state: RootStateOrAny) => state.drivers
  );

    const openPanelToAdd = () => {
      selectedDrivers.splice(0, selectedDrivers.length)
      dispatch(toggleDriversPanel({open: true}))
    }

  const openPanelToEdit = () => {
    dispatch(toggleDriversPanel({open: true}))
  }

  const setAsActive = async () => {
    let id = selectedDrivers[0].id;
    await markAsActive(id).then((res) => {
      dispatch(reloadDrivers({reload: true}))
    })
  }

  const setAsInActive = async () => {
    let id = selectedDrivers[0].id;
    await markAsInActive(id).then((res) => {
      dispatch(reloadDrivers({reload: true}))
    })
  }

  const setDeleteYears = () => {
    let id = selectedDrivers[0].id;
    deleteYears(id);
    dispatch(reloadDrivers({reload: true}))
  }

  const actions = {
      openPanelToAdd,
      openPanelToEdit,
      setAsActive,
      setAsInActive,
      setDeleteYears
  }

  const quantity = checkQuantity(selectedDrivers.length);

  const items = generateItems(quantity, actions, selectedDrivers)

  return {
    items,
  };
};