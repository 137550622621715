import { DefaultButton, mergeStyles } from "@fluentui/react/";
import { Panel, PanelType } from "@fluentui/react//lib/components/Panel";
import React from "react";
import { useQuery } from "react-query";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { togglePricing } from "../../../../config/redux/register/registerActions";
import { getGroupedPrices } from "../../../../connectors/pricing/connectors";
import { closeBtnS } from "../../styles/pricingParagraph.styles";
import {
  paragraph1,
  paragraphs1,
  paragraph2,
  paragraph3,
  paragraph4,
  paragraph5,
} from "./data/paragraph1";
import Map from "./Map";
import PricingParagraph from "./PricingParagraph";
import PricingTable from "./PricingTable";

const wrapper = mergeStyles({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const container = mergeStyles({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  selectors: {
    ["@media (min-width: 768px)"]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
});

const PrcingPanel: React.FC = () => {
  const { pricingOpen } = useSelector(
    (state: RootStateOrAny) => state.register
  );
  const dispatch = useDispatch();
  const {data} = useQuery("prices", getGroupedPrices)
  const dismiss = () => {
    dispatch(togglePricing(false));
  };

  return (
    <Panel
      headerText="Cennik - obowiązuje od dnia: 06.07.2020r."
      isOpen={pricingOpen}
      closeButtonAriaLabel="Close"
      onDismiss={dismiss}
      type={PanelType.custom}
      onOuterClick={() => {}}
      isHiddenOnDismiss
      customWidth={"888px"}
    >
      <div className={container}>
        <div className={wrapper}>
          {data && data.data.data[0] && data.data.data[0].items && <PricingTable data={data.data.data} />}
        </div>
        <div>
          <Map />
        </div>
      </div>
      <PricingParagraph paragraphs={[paragraph1]} />
      <PricingParagraph paragraphs={[paragraph2]} />
      <PricingParagraph paragraphs={paragraphs1} />
      <PricingParagraph paragraphs={[paragraph3]} />
      <PricingParagraph paragraphs={[paragraph2]} />
      <PricingParagraph paragraphs={[paragraph4]} />
      <PricingParagraph paragraphs={[paragraph5]} />
      <DefaultButton styles={closeBtnS} onClick={dismiss}>
        Zamknij
      </DefaultButton>
    </Panel>
  );
};

export default PrcingPanel;