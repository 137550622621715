import { setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { toggleVehiclePanel, reloadVehicles } from '../../../../config/redux/vehicles/vehiclesActions';
import {useDispatch, useSelector, RootStateOrAny } from "react-redux"
import { generateItems} from "../../actions/generateCommands/generateVehiclesCommands"
import { checkQuantity } from "../../actions/checkQuantity";
import {removeVehicle} from "../../../../connectors/vehicles/connectors/index"

export const useVehiclesCommandBar = () => {
    const dispatch = useDispatch()
    const { selectedVehicles} = useSelector(
        (state: RootStateOrAny) => state.vehicles
    );
    const quantity = checkQuantity(selectedVehicles.length);

    const openPanelToAdd = () => {
        selectedVehicles.splice(0, selectedVehicles.length)
        dispatch(toggleVehiclePanel({open: true}))
    }
    
    const openPanelToEdit = () => {
        dispatch(toggleVehiclePanel({open: true}))
    }

    const deleteVehicle = async () => {
        try {
            await removeVehicle(selectedVehicles[0].id)
            dispatch(reloadVehicles({reload: true}))
            dispatch(setListMessage("Operacja przebiegła pomyślnie"))
        } catch(err) {
            console.error(err)
        }
    }

    const actions = {
        openPanelToAdd,
        openPanelToEdit,
        deleteVehicle
    }

    const items = generateItems(quantity, actions)

    return {
        items
    }
}