import { OrdersActions } from "./ordersActions.enum";
import { IOrdersAction } from "./ordersActions.types";

const initState = {
  selectedOrders: [],
  remove: false,
  realizationDate: "",
  vehicle: "",
  activeOrder: {},
  addComment: false,
  orderAccepted: false,
  orderCanceled: false,
  cancelReasonOpen: false,
  reloadOrders: false,
  printingLoading: false,
};

export const ordersReducer = (state = initState, action: IOrdersAction) => {
  const { type, payload } = action;
  switch (type) {
    case OrdersActions.SET_ORDERS:
      return { ...state, selectedOrders: payload.selectedOrders };
    case OrdersActions.REMOVE_DATA:
      return { ...state, reload: payload.reload };
    case OrdersActions.SET_REALIZATION_DATE:
      return { ...state, realizationDate: payload.date };
    case OrdersActions.SET_VEHICLE:
      return { ...state, vehicle: payload.vehicle };
    case OrdersActions.SET_POSTAL_CODE:
      return { ...state, postalCode: payload.postalCode };
    case OrdersActions.SET_ACTIVE_ORDER:
      return { ...state, activeOrder: payload };
    case OrdersActions.ADD_ORDER_COMMENT:
      return { ...state, addComment: payload };
    case OrdersActions.ACCEPT_ORDER:
      return { ...state, orderAccepted: payload };
    case OrdersActions.CANCEL_ORDER:
      return { ...state, orderCanceled: payload };
    case OrdersActions.TOGGLE_CANCEL_REASON:
      return { ...state, cancelReasonOpen: payload };
    case OrdersActions.RELOAD:
      return { ...state, reloadOrders: payload };
    case OrdersActions.TOGGLE_PRINITG_LOADING:
      return { ...state, printingLoading: payload };
    case OrdersActions.CLEAR_ORDERS_DATA:
      return { ...initState };
    default:
      return state;
  }
};
