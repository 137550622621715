import * as yup from "yup";

export const resetPassSchema = yup.object().shape({
  password: yup
    .string()
    .required("Podaj hasło")
    .min(6, "za krótkie hasło")
    .max(50),
  passwordConfirm: yup
    .string()
    .required("Podaj hasło")
    .min(6, "za krótkie hasło")
    .max(50)
    .oneOf([yup.ref("password")], "Hasła muszą być takie same"),
});