import {
  MessageBar,
  MessageBarType,
  IMessageBarStyles,
} from "@fluentui/react/";
import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { clearListMessages } from "../../../config/redux/alerts/alertsActions";
import { useEffectAfterMount } from "../hooks/useEffectAfterMount";

const messageBarS: IMessageBarStyles = {
  root: {
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    height: "32px",
    minHeight: "32px",
    position: "relative",
    marginBottom: "-20px",
    zIndex: 234     
  },
  iconContainer: {
    margin: "8px 0px 8px 12px"
  },
  text: {
    margin: "8px 0px 8px 12px"
  }
};

const messageBarPlaceHolderS: IMessageBarStyles = {
  root: {
    visibility: "hidden",
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    height: "32px",
    minHeight: "32px",
    position: "relative",
    marginBottom: "-20px",
    zIndex: 234,
  },
  iconContainer: {
    margin: "8px 0px 8px 12px"
  },
  text: {
    margin: "8px 0px 8px 12px"
  }
};

export interface ListSuccessMessageProps { 
  clearListMessageTimeout?: number;
}

const ListSuccessMessage: React.FC<ListSuccessMessageProps> = ({clearListMessageTimeout}) => {
  const dispatch = useDispatch();
  const { listMessage, listErr } = useSelector((state: RootStateOrAny) => state.alerts);

  useEffectAfterMount(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (listMessage) {
      timer = setTimeout(() => {
        dispatch(clearListMessages());
      }, !!clearListMessageTimeout ? clearListMessageTimeout : 3000);
    }

    return () => {
      if (listMessage) {
        dispatch(clearListMessages());
      }

      clearTimeout(timer);
    };
  }, [listMessage]);

  useEffectAfterMount(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (listErr) {
      timer = setTimeout(() => {
        dispatch(clearListMessages());
      }, 3000);
    }

    return () => {
      if (listErr) {
        dispatch(clearListMessages());
      }

      clearTimeout(timer);
    };
  }, [listErr]);

  return (
    <>
      {(!listMessage && !listErr) && (
        <MessageBar
          messageBarType={MessageBarType.success}
          styles={messageBarPlaceHolderS}
        >
        </MessageBar>
      )}

      {listMessage && (
        <MessageBar
          messageBarType={MessageBarType.success}
          styles={messageBarS}
        >
          {listMessage}
        </MessageBar>
      )}

      {listErr && (
        <MessageBar
          messageBarType={MessageBarType.error}
          styles={messageBarS}
        >
          {listErr}
        </MessageBar>
      )}
    </>
  );
};

export default ListSuccessMessage;