import React, {useEffect, useState} from "react";
import {Formik, Form, useField} from "formik";
import {Stack, StackItem} from "@fluentui/react//lib/Stack";
import FormBtns from "../../../../common/layout/form/NewFormBtns";
import ActivityIndicator from "../../../../common/layout/ActivityIndicator";
import ErrorMessage from "../../../../common/layout/form/ErrorMessage";
import {useCancelFrom} from "../../../hooks/useCancelForm";
import MultiDepartmentRadio from "./MultiDepartmentRadio";
import {containerS, radioContainer} from "../../../styles/basicInfoForm.styles";
import {useBasicInfoForm} from "../../../hooks/useBasicInfoForm";
import DisplayText from "../displayData/DisplayText";
import FormHeader from "../FormHeader";
import {validationSchemaCompanyBasicInfo} from "../../../../auth/actions/validationSchema";
import NewFormField from "../../../../common/layout/form/NewFormField";
import FormDropDown from "../../../../common/layout/form/FormDropDown";
import NewFormCheckbox from "../../../../common/layout/form/NewFormCheckbox";
import NewFormBtns from "../../../../common/layout/form/NewFormBtns";

const BasicInfoForm: React.FC = () => {
    const cancel = useCancelFrom();
    const {
        initValues,
        handleSubmit,
        isLoading,
        readOnlyData,
        years,
        setCustomYears
    } = useBasicInfoForm();
    // const [customYears, setCustomYears] = useState<any[]>([]);

    // useEffect(() => {
    //   if(customYears.length === 0 && years.length > 0) {
    //     setCustomYears([...years]);
    //   }
    // }, [years]);

    return (
        <Formik initialValues={initValues} onSubmit={handleSubmit} enableReinitialize={true}
                validateOnBlur={false} validateOnChange={false}
        >
            {({handleSubmit}) => (
                <Form translate="yes" onSubmit={handleSubmit}>
                    <Stack styles={containerS} verticalAlign="space-between" verticalFill>
                        <FormHeader text={"Edytuj dostępne lata dla użytkownika " + readOnlyData.name} />

                        {years.map((year, index: number) => {
                            return (
                                <div style={{margin: "3px"}}>
                                    <NewFormCheckbox name={year.id} label={year.id} checked={year.isSpecial || year.isActive} disabled={(year.isActive && !year.isSpecial)}
                                                     customHandleChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                        let yearsClone: any[] = [...years];
                                                        if(checked === true) {
                                                          yearsClone[index].isSpecial = true;
                                                        } else {
                                                          yearsClone[index].isSpecial = false;
                                                        }
                                                        setCustomYears(yearsClone);
                                                      }}
                                    />
                                </div>
                            )
                        })}

                        <NewFormBtns
                            submitBtnText={"Zapisz"}
                            cancelForm={cancel}
                            disabled={false}
                            // disabledOnlySubmitButton={isLoading}
                        />
                        <div>{isLoading ? <ActivityIndicator/> : <ErrorMessage/>}</div>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default BasicInfoForm;