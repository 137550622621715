import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react";
import {
  setSelectedListItem,
    toggleOrderPanel,
} from "../../../config/redux/client/clientActions";
import NewOrderClientForm from "../forms/NewOrderClientForm";
import OrderDisplay from "../display/OrderDisplay";
import EditOrderClientForm from "../forms/EditOrderClientForm";
import { useClientData } from "../../../features/orders/hooks/useClientData";
import {RenderNavigation} from "../commandBar/ClientCustomNavigation";
import {addOrderComment, setActiveOrder, setSelectedOrders} from "../../../config/redux/orders/ordersActions";
import {
  createNewOrder,
  setActiveOrderTab,
  setOrderEditing
} from "../../../config/redux/orders/ordersPanel/orderPanelActions";
import {OrderTabsNames} from "../../../features/orders/models/OrderTabs.enum";
import {clearAlerts} from "../../../config/redux/alerts/alertsActions";
import {useOrderData} from "../../../features/orders/hooks/useOrderData";

const panelS = {
  content: {
    padding: 20,
  },
  main: {
    height: "100%",
  },
};

const OrderPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { orderPanelOpen, formActive, editOrder, selectedItem } = useSelector(
    (state: RootStateOrAny) => state.client
  );
  
  useClientData();
  // const {orderPanelOpen} = useOrderData()

  const dismissPanel = () => {
    // dispatch(
    //   toggleOrderPanel({ isOpen: false, formActive: formActive, editOrder: false })
    // );
    // dispatch(setSelectedListItem(""));
    // localStorage.removeItem("receptionPlace");
    // localStorage.removeItem("orderTypeItemId");
    dispatch(setActiveOrder({}));
    dispatch(setSelectedOrders({ selectedOrders: [] }));
    dispatch(setActiveOrderTab(OrderTabsNames.DETAILS));
    dispatch(clearAlerts())
    dispatch(addOrderComment(false));
    dispatch(toggleOrderPanel({isOpen: false}));
    dispatch(setOrderEditing(false));
    dispatch(createNewOrder(false))
    localStorage.removeItem("receptionPlace");
    localStorage.removeItem("orderTypeItemId");
  };
  return (
    <Panel
      isOpen={orderPanelOpen}
      closeButtonAriaLabel="Close"
      type={3}
      onDismiss={dismissPanel}
      onOuterClick={() => {}}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      onRenderNavigation={RenderNavigation}
      styles={panelS}
      isFooterAtBottom={true}
    >
      {formActive && editOrder === false && <NewOrderClientForm/>}
      {formActive && editOrder === true && <EditOrderClientForm selectedItem={selectedItem} />}
      {!formActive && selectedItem && (
        <OrderDisplay selectedItem={selectedItem} />
      )}
    </Panel>
  );
};

export default OrderPanel;