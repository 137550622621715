import * as React from "react";
import {
  FontIcon,
  mergeStyles,
  Stack,
  IStackStyles,
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
  themeRulesStandardCreator,
  getTheme,
} from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";
import { NavLink } from "react-router-dom";
import { NavigationPath } from "../../../../config/routing/NavigationPath";
import UserIcon from "./UserIcon";
import { usePanel } from "../../../auth/hooks/usePanel";
import RegisterPanel from "../../../auth/components/register/RegisterPanel";
import { NotificationPanel } from "../panels/NotificationsPanel";
import { useState } from "react";
import { useQuery } from "react-query";
import { getNotifications } from "../../../../connectors/others/connectors";
import { Text } from '@fluentui/react//lib/Text';
const theme = getTheme();

export interface Notification {
  notificationType: number;
  count: number;
  name: string | undefined;
  dismissPanel: () => void;
}

const containerS: IStackStyles = {
  root: {
    height: "100%",
  },
};

const iconClass = mergeStyles({
  color: "white",
  fontSize: FontSizes.size16,
  textDecoration: "none",
  textAlign: "center",

});

const iconClassActive = mergeStyles({
  color: theme.palette.themePrimary,
  fontSize: FontSizes.size16,
  textDecoration: "none",
  fontWeight: 600,

});

const iconContainer = mergeStyles({
  display: "flex",
  justifyContent: "flex-end",
  padding: "17px",
  cursor: "pointer",
  position: "relative"
});

const iconContainerActive = mergeStyles({
  display: "flex",
  justifyContent: "flex-end",
  padding: "17px",
  cursor: "pointer",
  backgroundColor: "white",
});

const iconClassNotification = mergeStyles({
  fontSize: 12,
  position: "absolute",
  top: 13,
  right: 13,
  color: "white"
});

const iconClassNotificationText = mergeStyles({
  fontSize: 10,
  position: "absolute",
  top: 13,
  right: 13,
  width: 12,
  height: 12,
  color: theme.palette.themePrimary,
  lineHeight: "1",
  textAlign: "center"
});

const Interface: React.FC = () => {
  const { isOpen, openPanel, dismissPanel } = usePanel();
  const [type, setType] = useState(true);

  const { data, status } = useQuery("notifications", getNotifications, {staleTime: 60*1000})
  let  fetchedData = []
  fetchedData = data ? data.data.data : undefined
  let notificationCount = 0
  if (fetchedData) {
    notificationCount = fetchedData.length;
  }

  const exe = (e: boolean) => {
    openPanel();
    setType(e);
  }

  return (
    <Stack horizontal styles={containerS} verticalAlign="center">
      {/*<TooltipHost*/}
      {/*  content="Powiadomienia"*/}
      {/*  delay={TooltipDelay.zero}*/}
      {/*  id={"1"}*/}
      {/*  directionalHint={DirectionalHint.bottomCenter}*/}
      {/*>*/}
      {/*  <div onClick={!isOpen || !type ? () => exe(true) : dismissPanel} className={isOpen && type ? iconContainerActive : iconContainer}>*/}
      {/*    <FontIcon iconName="Ringer" className={isOpen && type ? iconClassActive : iconClass} />*/}
      {/*    {!(isOpen && type)  ? <FontIcon iconName="CircleFill" className={iconClassNotification} /> : null}*/}
      {/*    {!(isOpen && type)  ? <Text className={iconClassNotificationText}> {notificationCount}</Text> : null}*/}
      {/*  </div>*/}
      {/*</TooltipHost>*/}
      {/*/!* <TooltipHost*/}
      {/*  content="Ustawienia"*/}
      {/*  delay={TooltipDelay.zero}*/}
      {/*  id={"2"}*/}
      {/*  directionalHint={DirectionalHint.bottomCenter}*/}
      {/*> *!/*/}
      {/*  <NotificationPanel type={type} dismissPanel={dismissPanel} isOpen={isOpen} />*/}

      {/*  /!* <div onClick={!isOpen || type ? () => exe(false) : dismissPanel} className={isOpen && !type ? iconContainerActive : iconContainer}>*/}
      {/*    <FontIcon iconName="Settings" className={isOpen && !type ? iconClassActive : iconClass} />*/}
      {/*  </div>*/}
      {/*</TooltipHost> *!/*/}
      <UserIcon />
    </Stack>
  );
};

export default Interface;
