import * as React from 'react';
import { ITextStyles, Text, mergeStyles } from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";

const headerContainer = mergeStyles({
  marginBottom: "20px",
  width: "100%",
});
export const headerS: ITextStyles = {
  root: {
    fontSize: FontSizes.size20,
  },
};

export interface InputHeaderProps {
    text:string
}
 
const InputHeader: React.FC<InputHeaderProps> = ({text}) => {
    return ( 
        <div className={headerContainer}>
          <Text styles={headerS}>{text}</Text>
        </div>
     );
}
 
export default InputHeader;