import {IQuantity, Quantity} from "../../../common/models/quantity.model";

interface IActions {
    openPanelToAdd: () => void;
    openPanelToEdit: () => void;
    setAsActive: () => void;
    setAsInActive: () => void;
    setDeleteYears: () => void;
}

export const generateItems = (quantity: IQuantity, actions: IActions, selectedItem: any) => {
    switch (quantity) {
        case Quantity.NONE:
            return [
                {
                    key: "add",
                    text: "Dodaj rok",
                    iconProps: {iconName: "add"},
                    name: "add",
                    onClick: actions.openPanelToAdd,
                }
            ];
        case Quantity.ONE:
            if (selectedItem[0].isActive) {
                return [
                    {
                        key: "add",
                        text: "Dodaj rok",
                        iconProps: {iconName: "add"},
                        name: "add",
                        onClick: actions.openPanelToAdd,
                    },
                    {
                        key: "delete",
                        text: "Usuń rok",
                        iconProps: {iconName: "delete"},
                        name: "delete",
                        onClick: actions.setDeleteYears,
                    },
                    {
                        key: "setAsInActive",
                        text: "Oznacz jako nieaktywny",
                        iconProps: {iconName: "Cancel"},
                        name: "setAsInActive",
                        onClick: actions.setAsInActive,
                    }
                ]
            } else {
                return [
                    {
                        key: "add",
                        text: "Dodaj rok",
                        iconProps: {iconName: "add"},
                        name: "add",
                        onClick: actions.openPanelToAdd,
                    },
                    {
                        key: "delete",
                        text: "Usuń rok",
                        iconProps: {iconName: "delete"},
                        name: "delete",
                        onClick: actions.setDeleteYears,
                    },
                    {
                        key: "setAsActive",
                        text: "Oznacz jako aktywny",
                        iconProps: {iconName: "CheckMark"},
                        name: "setAsActive",
                        onClick: actions.setAsActive,
                    },
                ]
            }
        default:
            return [];
    }
};
