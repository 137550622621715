import React, { useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { PrimaryButton } from "@fluentui/react//lib/Button";
import { IDropdownOption } from "@fluentui/react//lib/Dropdown";
import { Dialog } from "@fluentui/react//lib/Dialog";
import { mergeStyles } from "@fluentui/react/";
import {
  setSelectedOrders,
  toggleCancelReason,
  toggleOrderCancel,
} from "../../../config/redux/orders/ordersActions";
import { errorButtonS } from "../../common/styles/errorBtn.styles";
import ReasonsDropDown from "./ReasonsDropDow";
import { cancelOrder } from "../../../connectors/orders/connectors/index";
import DialogErr from "../../common/layout/DialogErr";
import { useEffectAfterMount } from "../../common/hooks/useEffectAfterMount";
import { toggleOrderPanel } from "../../../config/redux/orders/ordersPanel/orderPanelActions";
import { setListMessage } from "../../../config/redux/alerts/alertsActions";
import { TextField } from "@fluentui/react//lib/TextField";

const messageContainer = mergeStyles({
    width: "100%",
    height: 15,
    marginTop: 10,
    marginBottom: 20
})

const CancelReason: React.FC = () => {
  const [errVisible, changeErrVisibility] = useState(false);
  const [selectedReason, setSelectedReason] = React.useState<IDropdownOption>();
  const [comment, setComment] = useState('');

  const dispatch = useDispatch();
  const { cancelReasonOpen, selectedOrders, orderCanceled } = useSelector(
    (state: RootStateOrAny) => state.orders
  );

  const dismiss = () => {
    dispatch(toggleCancelReason(false));
    setSelectedReason(undefined)
    changeErrVisibility(false)
  };   

  const setOrderCanceled = async () => {
    if (selectedReason) {
      try {
        let canceledOrders = await Promise.all(
          selectedOrders.map(async ({id}: any) => {
            cancelOrder(id, selectedReason.key.toString(), comment)
          })
        ) 
        dispatch(toggleOrderCancel(true));
        dispatch(setListMessage("Pomyślnie anulowano zamówienie"))
        dispatch(toggleCancelReason(false));
        dispatch(setSelectedOrders({selectedOrders: []}))
        dispatch(toggleOrderPanel(false))
      } catch (err) {
        console.error(err);
      }
    }else {
        changeErrVisibility(true)
    }
  };

  useEffectAfterMount(() => {
    if(orderCanceled){
      setSelectedReason(undefined)
    }
  },[orderCanceled])

  return (
    <Dialog
      hidden={!cancelReasonOpen}
      onDismiss={dismiss}      
    >
      <ReasonsDropDown
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
      />  
      <TextField
        label={"Komentarz"}
        multiline
        rows={8}
        resizable={false}
        type="text"
        onChanged = {(x => setComment(x))}   
      />
      <PrimaryButton
        styles={errorButtonS}
        text="Anuluj zamówienie"
        onClick={setOrderCanceled}
      />
      <div className={messageContainer}>
        {errVisible && <DialogErr message="Wybierz powód anulowania"/>}
      </div>
    </Dialog>
  );
};

export default CancelReason;