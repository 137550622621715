import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { SearchBox, Spinner, SpinnerSize } from "@fluentui/react/";
import { useDelay } from "../../hooks/useDelay";
import {
    filter, setFinished,
} from "../../../../config/redux/filters/filterActions";
import { toggleFiltration } from "../../../../config/redux/filters/filterActions";
import { searchBoxStyle } from "../../styles/searchBox.style";

interface ISearchBoxProps {
    reload: any;
}

const SearchBoxOrders: React.FC<ISearchBoxProps> = ({ reload }) => {

    const { finished } = useSelector(
        (state: RootStateOrAny) => state.filtration
    );

    const dispatch = useDispatch();
    const { setSearchTerm, searchTerm } = useDelay(
        setfiltration
    );

    function setfiltration() {
        if (searchTerm != "") {
            dispatch(setFinished({ finished: false }));
        }
        dispatch(filter({ searchPhrase: searchTerm }));
        dispatch(toggleFiltration({ filter: true }));
        dispatch(reload({ reload: true }));
        return null;
    };

    return (
        <>
            {finished == false ? <div style={{ marginRight: 5, marginLeft: "auto" }}><Spinner size={SpinnerSize.small} /></div> : <></>}
            <SearchBox
                styles={searchBoxStyle}
                placeholder="Szukaj zamówienia"
                name="searchPhrase"
                onChange={(value: string) => {
                    setSearchTerm(value)
                }}
                type="text"
            //onKeyUp = {(event: any) => {
            //  if(event.key == "Enter" && event.target.value != ""){
            //    dispatch(setFinished({finished: false}));
            //    setSearchTerm(event.target.value)
            //  }
            //}}
            />
        </>
    );
};

export default SearchBoxOrders;