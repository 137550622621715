import * as React from "react";
import {
  CommandBar,
} from "@fluentui/react/";


import { comandBarS} from "../../styles/commandBar.styles"
import { useCancellationReasonsCommandBar } from "../../hooks/settings/useCancellationReasonCommandBar";
 
const CancellationReasonsCommandBar: React.FC = () => {
    const {items} = useCancellationReasonsCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default CancellationReasonsCommandBar;