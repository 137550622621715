import { IQuantity } from "../../../common/models/quantity.model";
import { NavigationPath } from "../../../../config/routing/NavigationPath";
import { generateAll } from "./orders/generateDefaultCommands"
import { generateAccepted } from "./orders/generateAcceptedCommands"
import { generateCancellation } from "./orders/generateCancellationCommands"
import { generateInProgress } from "./orders/generateInProgressCommands"
import { generateNew } from "./orders/generateNewCommands"
import { generateCompleted } from "./orders/generateCompleted"

export const generateItems = (
  quantity: IQuantity,
  pathname: string,
  actions: any,
  selectedOrder?: any
) => {
  switch (pathname) {
    case NavigationPath.REPORTS_APPROVED:
      return generateAccepted(quantity, actions)
    case NavigationPath.REPORTS_ALL:
      return generateNew(quantity, actions)
    case NavigationPath.REPORTS_WAITING:
      return generateInProgress(quantity, actions)
    case NavigationPath.REPORTS_REJECTED:
      return generateCancellation(quantity, actions)
    default:
      return generateAll(quantity, actions);
  }
};






