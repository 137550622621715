import { QueryCache } from 'react-query';
import { ReactQueryCacheProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import store from './config/redux/store';
import Routing from './config/routing/Routing';
import './App.css';

const queryCache = new QueryCache()

export const App: React.FC = () => {
    return (
            <Provider store={store}>
                <ReactQueryCacheProvider queryCache={queryCache}>
                    <Router>
                        <Routing/>
                    </Router>
                </ReactQueryCacheProvider>
            </Provider>
    );
};