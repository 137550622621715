import React from "react";
import { Form, Formik } from "formik";
import NewFormField from "../../../common/layout/form/NewFormField";
import { postalCodes } from "../../data/postalCodes";
import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import { mergeStyles } from "@fluentui/react/";

const fieldContainer = mergeStyles({
  marginTop: 0
})

export interface EditPriceFormProps {
  handleSubmit: any;
  initValues: any;
  cancelForm: any;
  selectedPrice: any;
}

const EditPriceForm: React.FC<EditPriceFormProps> = ({
  handleSubmit,
  initValues,
  cancelForm,
  selectedPrice,
}) => {
  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          {selectedPrice.postalCodePrices ? (
            <div className={fieldContainer}>
              {postalCodes.map((code: string) => (
                
                  <NewFormField
                    key={code}
                    name={code}
                    label={`Cena dla kodów ${code}`}
                    required={false}
                  />             
              ))}
            </div>
          ) : (
            <div className={fieldContainer}>
              <NewFormField name={"price"} label={`Cena`} required={false} />
            </div>
          )}
          <NewFormBtns submitBtnText={"Zapisz"} cancelForm={cancelForm}  positionAbsolute={true}/>
        </Form>
      )}
    </Formik>
  );
};

export default EditPriceForm;
