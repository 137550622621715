import * as React from "react";
import {
  Text,
  ITextStyles,
} from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";
import { FontWeights } from "@fluentui/react/";



const headerS: ITextStyles = {
  root: {
    fontSize: FontSizes.size20,
    fontWeight: FontWeights.semibold,
    paddingBottom: 30
  },
};


interface FormHeaderProps {
 text: string
}
 
const FormHeader: React.FC<FormHeaderProps> = ({text}) => {
    return ( 
        <Text styles={headerS}>
            {text}
        </Text>
     );
}
 
export default FormHeader;