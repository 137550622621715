import { Icon, TooltipHost } from "@fluentui/react/";
import React from "react";
import "../../../client/styles/tableStyles.css";

export const reportsColumns = [
  {
    name: "Rok",
    fieldName: "yearString",
    minWidth: 50,
    maxWidth: 80,
  },
  {
    name: "Nazwa firmy",
    fieldName: "company.companyInfo.name",
    minWidth: 50,
    maxWidth: 380,
    onRender: (item: any) => {
      return (
        <>
          <p>{item.company.companyInfo.name}</p>
        </>
      );
    },
  },
  {
    name: "Adres firmy",
    fieldName: "company.companyInfo.companyAddress.normalized",
    minWidth: 50,
    maxWidth: 380,
    onRender: (item: any) => {
      return (
        <>
          <p>
            {item.company.companyInfo.companyAddress.city},{" "}
            {item.company.companyInfo.companyAddress.street},{" "}
            {item.company.companyInfo.companyAddress.postalCode}
          </p>
        </>
      );
    },
  },
  {
    name: "NIP",
    fieldName: "creatoruser.nip",
    minWidth: 100,
    maxWidth: 200,
  },
  {
    name: "Status Raportu",
    fieldName: "status",
    minWidth: 30,
    maxWidth: 110,
    onRender: (item: any) => {
      let message: string = "";
      let iconName: string = "";
      let className: string = "";
      if (item.status == "1") {
        message = "Oczekuję na akceptację";
        iconName = "HourGlass";
        className = "pending";
      } else if (item.status == "2") {
        message = "Zatwierdzono";
        iconName = "CheckMark";
        className = "approved";
      } else if (item.status == "3") {
        message = "Odrzucone";
        iconName = "Cancel";
        className = "decline";
      } else {
        message = "Możliwe do edycji";
        iconName = "EditNote";
        className = "editing";
      }
      return (
        <TooltipHost content={message}>
          <Icon iconName={iconName} className={`tableIcon ${className}`} />
        </TooltipHost>
      );
    },
  },
  {
    name: "Data Utworzenia",
    fieldName: "creationTime",
    minWidth: 60,
    maxWidth: 120,
    onRender: (item: any) => {
      return new Date(Date.parse(item.creationTime))
        .toLocaleDateString("pl-PL", {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
        .toUpperCase();
    },
  },
];
