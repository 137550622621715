import { RootStateOrAny, useSelector } from "react-redux";
import { getOrderDetails } from "./../../../connectors/orders/connectors/index";
import { useState, useEffect } from "react";

export const useOrderDetails = () => {
  const [details, setDetails] = useState<any>("");
  const { selectedOrders } = useSelector(
    (state: RootStateOrAny) => state.orders
  );

  useEffect(() => {
    if (selectedOrders.length) {
      fetch();
    }
  }, []);

  const fetch = async () => {
    const res = await getOrderDetails(selectedOrders[0].id);
    setDetails(res.data.result);
  };

  return {
    selectedOrders,
    details,
  };
};
