import { IQuantity, Quantity } from "../../../common/models/quantity.model";

interface IActions {
  openPanel: () => void;
  delete: () => void;
  addReason: (e: any) => void
}

export const generateItems = (
  quantity: IQuantity,
  actions: IActions,
  reasons: Array<any>
) => {
  switch (quantity) {
    case Quantity.NONE:
      const itemsArr = reasons.map(({ id, name }) => {
        let icon = { iconProps: {} };

        if (name === 'Deaktywacji klienta') {
          icon = { iconProps: { iconName: "userRemove" } };
        } else {
          icon = { iconProps: { iconName: "UnstackSelected" } };
        } 

        return {
          key: id,
          id: id,
          text: name,
          iconProps: icon.iconProps,
          onClick: actions.addReason,
        };
      });

      return [
        {
          key: "add",
          text: "Dodaj powód",
          iconProps: { iconName: "add" },
          subMenuProps: {
            items: [...itemsArr],
          },
        },
      ];
    case Quantity.ONE:
      return [
        {
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          name: "edit",
          onClick: actions.openPanel,
        },
        {
          key: "delete",
          text: "Usuń",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.delete,
        },
      ];
    default:
      return [];
  }
};
