import React from 'react';
import { wrapper } from '../../auth/styles/activityIndicatorContainer.styles';
import ListSuccessMessage from '../../common/layout/ListSuccessMessage';
import { padding } from '../../reports/styles/style';
import StoreDocsPanel from '../panel/StoreDocsPanel';
import StoreDocsList from './StoreDocsList';

export interface StoreDocsProps { }

const StoreDocs: React.FC<StoreDocsProps> = () => {
    return (
        <div className={wrapper}>
            <div className={padding}>
                <ListSuccessMessage />
            </div>
            <StoreDocsList isPanelOpen={false}/>
            <StoreDocsPanel /> 
        </div>
    );
}

export default StoreDocs;