import { OrderTabsNames } from "../../features/orders/models/OrderTabs.enum";
import { NavigationPath } from "../../config/routing/NavigationPath";

export const generateItems = (
    state: any,
    pathname: string,
    actions: any,
    activeTab: string,
    editing: boolean
) => {
  // console.log(state, "state");
  // console.log(pathname, 'pathname');
  // console.log(actions, 'actions');
  // console.log(activeTab, 'activeTabs');
  // console.log(editing, "editing");
  const { addComment } = state;
  let items: Array<any> = [];
  let edit = actions.canEdit();

  if(edit){
    items = [
      {
        key: "edit",
        text: "Edytuj",
        iconProps: { iconName: "edit" },
        onClick:actions.edit
      },
      {
        key: "report",
        text: "Pobierz",
        iconProps: { iconName: "ReportDocument" },
        name: "report",
        onClick: actions.download,
      },
      {
        key: "send",
        text: "Złóż raport",
        iconProps: { iconName: "Send" },
        name: "send",
        onClick: actions.send,
      }
    ];
  } else {
    items = [
      {
        key: "report",
        text: "Pobierz",
        iconProps: { iconName: "ReportDocument" },
        name: "report",
        onClick: actions.download,
      },
    ]
  }



  return items;
};
