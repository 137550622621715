import * as React from "react";
import {
  Stack,
  Text,
  IStackStyles,
  concatStyleSets,
  ITextStyles,
  DefaultButton,
  IStackTokens,
  FontIcon,
} from "@fluentui/react/";
import { pDark, hDark } from "../../../common/styles/text";
import authentication from "react-azure-b2c";
import { useDispatch } from "react-redux";
import { togglePricing } from "../../../../config/redux/register/registerActions";
import { toggleReceptionPlacePanel } from "../../../../config/redux/client/clientActions";
import ListSuccessMessage from "../../../common/layout/ListSuccessMessage";

const marginP: ITextStyles = {
  root: {
    marginBottom: "23px",
  },
};

const marginH: ITextStyles = {
  root: {
    marginBottom: "15px",
  },
};

const container: IStackStyles = {
  root: {
    textAlign: "center",
    width: "100%",
  },
};

const stackS: IStackStyles = {
  root: {
    width: "100%",
  },
};

const stackSMargin: IStackStyles = {
  root: {
    width: "80%",
    marginTop: "20px !important",
  },
};

const customSpacingStackTokens: IStackTokens = {
  childrenGap: "-20%",
};

const logout = () => {
  sessionStorage.removeItem("role");
  sessionStorage.removeItem("isActivated");
  authentication.signOut();
};

const lastText = concatStyleSets(pDark, marginP);
const header = concatStyleSets(hDark, marginH);

// const desc1 = "Aby korzystać z systemu i składać";
const desc1 = "Aby skorzystać z systemu niezbędna jest akceptacja";
// const desc2 = "zamówienia, należy uzupełnić dane";
const desc2 = "administratora Recykl. Proszę czekać na aktywację";
const desc3 = "Konto oczekuje aktywacji administratora";

interface IRegisterDesc {
  openPanel: () => void;
  user: any;
  isActivated: boolean;
  showAddReceptionPlaceButton?: boolean;
}

const RegisterDesc: React.FC<IRegisterDesc> = ({
  openPanel,
  user,
  showAddReceptionPlaceButton,
  isActivated,
}) => {
  const dispatch = useDispatch();
  const openPanelPricing = () => {
    dispatch(togglePricing(true));
  };
  const openPanelReceptionPlaces = () => {
    dispatch(toggleReceptionPlacePanel({ isOpen: true, formActive: true }));
  };

  return (
    <Stack verticalAlign="center" horizontalAlign="center" styles={container}>
      <Text styles={header}>{user ? user.emails[0] : ""}</Text>

      {isActivated ? (
        <>
          <Text styles={lastText}>
            <FontIcon iconName="Warning" /> {desc3}
          </Text>
        </>
      ) : (
        <>
          <Text styles={pDark}>{desc1}</Text>
          <Text styles={lastText}>{desc2}</Text>
        </>
      )}

      <Stack horizontal tokens={customSpacingStackTokens} styles={stackS}>
        {/*<Stack.Item grow={1}>*/}
        {/*  <DefaultButton text="Zobacz cennik" onClick={openPanelPricing} />*/}
        {/*</Stack.Item>*/}

        <Stack.Item grow={1}>
          <DefaultButton text="Wyloguj się" onClick={logout} />
        </Stack.Item>

        {/* {isActivated ? null : 
        <Stack.Item grow={1}>
          <PrimaryButton
            text="Uzupełnij dane"
            onClick={openPanel}
          />
        </Stack.Item>} */}
      </Stack>

      {showAddReceptionPlaceButton === true && (
        <Stack horizontal tokens={customSpacingStackTokens} styles={stackS}>
          <Stack.Item grow={1}>
            <DefaultButton
              text="Dodaj punkt odbioru"
              onClick={openPanelReceptionPlaces}
              style={{ width: "76%", marginTop: 20 }}
            />
          </Stack.Item>
        </Stack>
      )}

      <Stack horizontal tokens={customSpacingStackTokens} styles={stackSMargin}>
        <Stack.Item grow={1}>
          <ListSuccessMessage clearListMessageTimeout={1000 * 60 * 60 * 24} />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default RegisterDesc;
