import { setSelectedPrice } from "./../../../config/redux/pricing/pricingActions";
import {
  clearAlerts,
  setListMessage,
} from "./../../../config/redux/alerts/alertsActions";
import { FormikValues } from "formik";
import { useMutation, useQueryCache } from "react-query";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  setErrorAlert,
  setSuccessAlert,
} from "../../../config/redux/alerts/alertsActions";
import { togglePricesPanel } from "../../../config/redux/pricing/pricingActions";
import { addPricing } from "../../../connectors/pricing/connectors";
import { IResetForm } from "../../common/models/resetForm.type";
import { parsePrices } from "../actions/pricesUtils";
import { PricingMessages } from "../models/pricingMessages.enum";
import { useState } from "react";

export const useAddPrice = () => {
  const cache = useQueryCache();
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { activePriceType, selectedPrice, prcingPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.pricing
  );

  const [add, { isSuccess: addSucces, isError, error: addErr }] = useMutation(
    addPricing,
    {
      onSuccess: () => cache.invalidateQueries("prices"),
      onError: (err) => {
        throw new Error();
      },
    }
  );

  const addPrice = async (values: FormikValues, { resetForm }: IResetForm) => {
    setIsLoading(true);
    const { orderTypeItemId } = values;
    const prices = parsePrices(values);


    const data =
      activePriceType.name === "Pojazd"
        ? {
            orderTypeItemId,
            postalCodePrices: true,
            prices: prices,
            price: 1
          }
        : {
            orderTypeItemId,
            postalCodePrices: false,
            price: values.price,
          };

    
    try {
      await add(data);
      dispatch(clearAlerts());
      setIsLoading(false);
      resetForm();
      dispatch(setSelectedPrice(""));
      dispatch(togglePricesPanel(false));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"));
    } catch (err) {
      setIsLoading(false);
      dispatch(setErrorAlert(PricingMessages.PRICE_ALREDY_EXISTS));
    }
  };

  const cancelForm = () => {
    dispatch(clearAlerts());
    dispatch(setSelectedPrice(""));
    dispatch(togglePricesPanel(false));
  };

  return {
    cancelForm,
    addPrice,
    activePriceType,
    loading,
    selectedPrice,
    prcingPanelOpen,
  };
};
