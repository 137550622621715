import { setListMessage, setErrorAlert, clearAlerts } from './../../../config/redux/alerts/alertsActions';
import { generateInitValues } from "../../common/actions/generateInitValues";
import {
  toggleVehiclePanel,
  setActiveVehicle,
} from "../../../config/redux/vehicles/vehiclesActions";
import { IResetForm } from "../../common/models/resetForm.type";
import { FormikValues } from "formik";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import {
  saveVehicle,
  edditVehicle,
} from "../../../connectors/vehicles/connectors/index";
import { addNewVehicle } from "../../../config/redux/vehicles/vehiclesActions";
import {
  parseCheckboxesValues,
  parseOrderTypes,
  generateFields,
  generateNamesArr,
} from "../actions/helpers";
import { useState } from "react";

export const useVehicleForm = () => {
  const [loading, setIsLoading] = useState(false);
  const { selectedVehicles } = useSelector(
    (state: RootStateOrAny) => state.vehicles
  );

  const { orderTypes } = useSelector(
    (state: RootStateOrAny) => state.orderTypes
  );
  const dispatch = useDispatch();

  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true);
    const orderTypeItems = parseCheckboxesValues(values);

    const body = {
      brand: values.brand,
      model: values.model,
      registerNumber: values.registerNumber,
      driverId: values.driver,
      orderTypeItems,
    };
    let res

    try {
      dispatch(clearAlerts())
      selectedVehicles[0]
        ? (res = await edditVehicle(body, selectedVehicles[0].id))
        : (res = await saveVehicle(body));
      setIsLoading(false);
      dispatch(addNewVehicle({ add: true }));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
      dispatch(toggleVehiclePanel({ open: false }));
      dispatch(setActiveVehicle({}));
      resetForm();
    } catch (err) {
      setIsLoading(false);
      dispatch(setErrorAlert("Pojazd o podanym numerze rejestracyjnym już istnieje"))
    }
  };

  const inputInitValues = {
    brand: "",
    model: "",
    registerNumber: "",
    driver: "",
  };
  

  const initValues = selectedVehicles[0]
    ? {
        brand: selectedVehicles[0].brand,
        model: selectedVehicles[0].model,
        registerNumber: selectedVehicles[0].registerNumber,
        driver: selectedVehicles[0].driverId,
        ...generateFields(parseOrderTypes(selectedVehicles[0].orderTypes)),
      }
    : {
        ...inputInitValues,
        ...generateInitValues(generateNamesArr(orderTypes), false),
      };

  return { initValues, handleSubmit, selectedVehicles, loading };
};
