import { useState } from "react";
import {
  setErrorAlert,
} from "./../../../config/redux/alerts/alertsActions";
import { reloadOrders } from "./../../../config/redux/orders/ordersActions";
import { useDispatch } from "react-redux";
import { useFormik, FormikValues } from "formik";

import { addOrder, getNetto, IGetNetto } from "../../../connectors/orders/connectors/index";
import { OrdersAlerts } from "../models/Alerts.enum";
import { toggleOrderPanel } from "../../../config/redux/orders/ordersPanel/orderPanelActions";
import { createNewOrder } from './../../../config/redux/orders/ordersPanel/orderPanelActions';
import { toggleFiltration } from "../../../config/redux/filters/filterActions";

export const useNewOrderFrom = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch();

  const handleSubmit = async (values: FormikValues, formikBag: any) => {
    setIsLoading(true)
    const { year,firstPKWiU, secondPKWiU, thirdPKWiU, fourthPKWiU, fifthPKWiU, sixthPKWiU, } = values;
    const data = {
      year,
      firstPKWiU,
      secondPKWiU,
      thirdPKWiU,
      fourthPKWiU,
      fifthPKWiU,
      sixthPKWiU,
    };

    try {
      await addOrder(data);
      dispatch(toggleFiltration({filter: true}));
      dispatch(reloadOrders(true));
      formikBag.resetForm();
      dispatch(toggleOrderPanel(false));
    } catch (err) {
      dispatch(setErrorAlert(OrdersAlerts.FILL_ALL_FILEDS));
    }
    setIsLoading(false)
  };

  const changePrice = async (receptionPlace?: number, orderTypeItemId?: number) => {
    if(receptionPlace) {
      localStorage.setItem("receptionPlace",receptionPlace.toString())
    }
    else{
      receptionPlace = localStorage.getItem("receptionPlace") ? Number(localStorage.getItem("receptionPlace")) : 1
    }
    if(orderTypeItemId) {
      localStorage.setItem("orderTypeItemId",orderTypeItemId.toString())
    }
    else{
      orderTypeItemId = localStorage.getItem("orderTypeItemId") ? Number(localStorage.getItem("orderTypeItemId")) : 1
    }
    if(!receptionPlace || !orderTypeItemId) 
      return 0;

    var data : IGetNetto = {
      itemId: orderTypeItemId,
      receptionPlaceId: receptionPlace
    };

    try {
      var res = await getNetto(data);
      return res.data.result.netPrice
      //setPrice(res.data.result.netPrice);
    } catch(err) {
      console.error(err);
    }
  }

  const formik = useFormik({
    initialValues: {
      year: 0,
      firstPKWiU: 0,
      secondPKWiU: 0,
      thirdPKWiU: 0,
      fourthPKWiU: 0,
      fifthPKWiU: 0,
      sixthPKWiU: 0,
    },
    onSubmit: handleSubmit,
  });

  const cancelForm = () => {
    dispatch(toggleOrderPanel(false));
    dispatch(createNewOrder(false))
    localStorage.removeItem("receptionPlace");
    localStorage.removeItem("orderTypeItemId");
  };

  return {
    cancelForm,
    values: formik.values,
    helpers: formik,
    handleSubmit,
    isLoading,
    changePrice
  };
};
