import { FormikValues } from "formik";
import { IPrice } from "../models/price.type";

export const parsePrices = (values: FormikValues) => {
  const prices: Array<IPrice> = [];

  Object.entries(values).forEach(([key, value]) => {
    if (key.includes("_-___")) {
      
      prices.push({ value: parseInt(value), postalCodePrefix: key });
    }
  });

  return prices;
};

export const getInitialValues = (data: any) => {
  let initValues = {}
  if(data){
    const values = data.data.data.prices.reduce((result:any, item: any) => {
      const [value, prefix] = Object.values(item);
      
      result[prefix] = value
      return result
    },{})

    initValues = {...values}
    return initValues
  }
  return initValues
}