import React, { useMemo } from "react";
import { mergeStyles } from "@fluentui/react/";
import { RootStateOrAny, useSelector } from "react-redux";

import FormDropDown from "../../../../../common/layout/form/FormDropDown";

const wrapper = mergeStyles({
  marginBottom: 20,
});

const DepartmentsDropDown: React.FC = () => {
  const {
    data: { departments },
  } = useSelector((state: RootStateOrAny) => state.register.company);

  const items = useMemo(() => {
    return departments.map((item: any) => ({
      key: item.id,
      text: `${item.city} ${item.street} ${item.postalCode}`,
    }));
  }, [departments]);

  return (
    <div className={wrapper}>
      <FormDropDown
        name="department"
        headerText="Wybierz oddział"
        options={items}
      />
    </div>
  );
};

export default DepartmentsDropDown;
