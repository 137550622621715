import { RootStateOrAny } from "react-redux";
import { useSelector } from "react-redux";
import { getPriceItem } from "./../../../connectors/pricing/connectors/index";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";

export const useSinglePrice = () => {
  const [values, setValues] = useState<any>();
  const { selectedPrice } = useSelector(
    (state: RootStateOrAny) => state.pricing
  );
  const { data } = useQuery(["priceItem", selectedPrice.id], getPriceItem, {
    enabled: selectedPrice.postalCodePrices,
  });

  useEffect(() => {
    if (selectedPrice.postalCodePrices) {
      getInitialValues();
    }
  }, [data]);


  const getInitialValues = () => {
    let initValues = {};
    if (data) {
      const values = data.data.data.prices.reduce((result: any, item: any) => {
        result[item.postalCodePrefix] = item.value;
        return result;
      }, {});

      initValues = { ...values };
      setValues(initValues);
    }
    
  };


  return {
      values,
  };
};
