export enum NavigationPath {
    HOME = "/",
    AUTH = "/authorization",
    NEW_PASS = "/setnewPassword",
    INACTIVE_ACCOUNT = "/inactive",
    UNAVAILABLE = "/unavailable",
    ACTIVATE_ACCOUNT = "/activate/:token",

    //client
    DASHBOARD = "/client/dashboard",
    REPORTS = "/client/reports",

    //orders
    REPORTS_ALL = "/admin/reports/all",
    REPORTS_APPROVED = "/admin/reports/approved",
    REPORTS_WAITING = "/admin/reports/waiting",
    REPORTS_REJECTED = "/admin/reports/rejected",
    REPORTS_UNAVAI = "/admin/reports/unavai",

    //clients
    UNVERIFIED_CLIENTS = "/admin/clients/unverified",
    // NEW_CLIENTS="/clients/new",
    ACTIVE_CLIENTS = "/clients/active",
    INACTIVE_CLIENTS = "/clients/inactive",
    UNACTIVE_USERS = "/users/inactive",
    UNACTIVE_PICKUP_POINTS = "/pickuppoints/inactive",

    //config
    ORDER_TYPES = "/config/orderTypes",
    VEHICLES = "/config/vehicles",
    DRIVERS = "/config/drivers",
    PRICING = '/config/pricing',
    OPERATORS = '/config/operators',
    CANCELLATION_REASONS = '/config/cancellationReasons',
    ADMINS = `/config/admins`,
    //temp
    TEMP = "/TEMP",
    //report
    REPORT_LIST = "/report/reportList",
    //warehouse
    STORE_LIST = "/store/list",
    INTERALSTORE_LIST = "/internalstore/list",
    STORE_DOCS_LIST = "/store/docs",
    STORE_MAP = "/store/map",

    //Settings
    YEARS_MANAGEMENT = "/settings/years",


}