import { IAdmin, IAdminsPanel, IReload, IAdd } from "./adminsActions.typse";
import {AdminsActions} from './adminsActions.enum'
import {ClientActions} from "../client/clientActions.enum";

export const setAdmin = (data: IAdmin) => {
    return{
        type: AdminsActions.SET_ADMIN,
        payload: data
    }
}

export const setSelectedAdmins = (data: Array<IAdmin>) => {
  return {
    type: AdminsActions.SET_SELECTED_ADMIN,
    payload: data
  }
}

export const toggleAdminsPanel = (data: IAdminsPanel) => {
  return {
    type: AdminsActions.TOGGLE_ADMINS_PANEL,
    payload: data
  }
}

export const reloadAdmins = (data: IReload) => {
  return {
    type: AdminsActions.RELOAD_ADMINS,
    payload: data
  }
}

export const clearAdmins = () => {
  return {
    type: AdminsActions.CLEAR,
  }
}

export const addNewAdmin = (data: IAdd) => {
  return {
    type: AdminsActions.ADD_ADMIN,
    payload: data
  }
}


export const toggleSelfEditPanel = (isOpen: boolean) => {
  return {
      type: ClientActions.TOGGLE_SELF_EDIT_PANEL,
      payload: isOpen
  }
}