import React from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react/";
import { toggleSyncOrderPanel } from "../../../config/redux/orders/ordersPanel/orderPanelActions";
import SyncOptimaForm from "./forms/SyncOptimaForm";
import { panelS } from "../../common/styles/panelStyles";

const OrderOptimaPanel: React.SFC = () => {
  const dispatch = useDispatch();
  const { syncOrderPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.orderPanel
  );
  const dissmisPanel = () => {
    dispatch(toggleSyncOrderPanel(false));
  };

  return (
    <Panel
      headerText="Synchronizuj zamówienie z systemem Optima"
      isOpen={syncOrderPanelOpen}
      closeButtonAriaLabel="Close"
      type={1}
      onDismiss={dissmisPanel}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      styles={panelS}
    >
      <SyncOptimaForm />
    </Panel>
  );
};

export default OrderOptimaPanel;