import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setShowContainerPoints, setShowReceptionPlacePoints, setShowInternalStorePoints } from "../../../config/redux/storeMap/StoreMapActions";

export const useStoreMapCommandBar = () => {

    const dispatch = useDispatch();
    const { showReceptionPlacePoints, showContainerPoints, showInternalStorePoints } = useSelector((state: RootStateOrAny) => state.storeMap);

    const unhideReceptionPlacePoints = () => {
        dispatch(setShowReceptionPlacePoints(true));
    }

    const hideReceptionPlacePoints = () => {
        dispatch(setShowReceptionPlacePoints(false));
    }

    const unhideContainerPoints = () => {
        dispatch(setShowContainerPoints(true));
    }

    const hideContainerPoints = () => {
        dispatch(setShowContainerPoints(false));
    }

    const unhideInternalStorePoints = () => {
        dispatch(setShowInternalStorePoints(true));
    }

    const hideInternalStorePoints = () => {
        dispatch(setShowInternalStorePoints(false));
    }

    const generateItems = () => {
        const receptionPlaceButton = showReceptionPlacePoints === false ? [{
            key: "unhideReceptionPlacePoints",
            text: "Pokaż punkty odbioru",
            iconProps: { iconName: "reportdocument" },
            name: "prepare",
            onClick: unhideReceptionPlacePoints,
        }] : [{
            key: "hideReceptionPlacePoints",
            text: "Ukryj punkty odbioru",
            iconProps: { iconName: "reportdocument" },
            name: "prepare",
            onClick: hideReceptionPlacePoints,
        }]

        const containerButton = showContainerPoints === false ? [{
            key: "unhideContainerPoints",
            text: "Pokaż kontenery",
            iconProps: { iconName: "reportdocument" },
            name: "prepare",
            onClick: unhideContainerPoints,
        }] : [{
            key: "hideContainerPoints",
            text: "Ukryj kontenery",
            iconProps: { iconName: "reportdocument" },
            name: "prepare",
            onClick: hideContainerPoints,
        }]

        const internalStoreButton = showInternalStorePoints === false ? [{
            key: "unhideInternalStorePoints",
            text: "Pokaż magazyny wewnętrzne",
            iconProps: { iconName: "reportdocument" },
            name: "prepare",
            onClick: unhideInternalStorePoints,
        }] : [{
            key: "hideInternalStorePoints",
            text: "Ukryj magazyny wewnętrzne",
            iconProps: { iconName: "reportdocument" },
            name: "prepare",
            onClick: hideInternalStorePoints,
        }]

        return [
            ...receptionPlaceButton,
            ...containerButton,
            ...internalStoreButton
        ]
    };

    const items = generateItems()

    return {
        items
    }
};