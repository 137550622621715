import React from "react";
import { mergeStyles } from "@fluentui/react/"
import OrderDetailTxt from "./forms/edditOrder/OrderDetailTxt";
import { RootStateOrAny, useSelector } from "react-redux";
import { Reason } from "../../common/enums/reason.enum";
import { getReasonsById } from "../../../connectors/cancellationReasons/connectors";
import { useQuery } from "react-query";
import {useOrderDetails} from "../hooks/useOrderDetails";

const wrapper = mergeStyles({
  padding: 20
})

const OrderDetails: React.FC = () => {
  const { orderDetails } = useSelector((state: RootStateOrAny) => state.orderPanel)
  const {selectedOrders} = useOrderDetails()

  return (
    <div className={wrapper}>
      {(selectedOrders && selectedOrders.length > 0) &&  (
        <div>
          <OrderDetailTxt
              title={"Klient"}
              value={selectedOrders[0].company.companyInfo.name}
          />
          <OrderDetailTxt
              title={"Raport z roku"}
              value={selectedOrders[0].year}
          />

          <OrderDetailTxt
              title={"NIP"}
              value={selectedOrders[0].creatorNip}
          />
          <OrderDetailTxt
              title={"BDO"}
              value={selectedOrders[0].bdo}
          />

          <OrderDetailTxt
            title={selectedOrders[0].fields[0].description + " (" + selectedOrders[0].fields[0].typeId + ")"}
            value={selectedOrders[0].fields[0].value}
          />
          <OrderDetailTxt
            title={selectedOrders[0].fields[1].description + " (" + selectedOrders[0].fields[1].typeId + ")"}
            value={selectedOrders[0].fields[1].value}
          />
          <OrderDetailTxt
            title={selectedOrders[0].fields[2].description + " (" + selectedOrders[0].fields[2].typeId + ")"}
            value={selectedOrders[0].fields[2].value}
          />
          <OrderDetailTxt
            title={selectedOrders[0].fields[3].description + " (" + selectedOrders[0].fields[3].typeId + ")"}
            value={selectedOrders[0].fields[3].value}
          />
          <OrderDetailTxt
            title={selectedOrders[0].fields[4].description + " (" + selectedOrders[0].fields[4].typeId + ")"}
            value={selectedOrders[0].fields[4].value}
          />
          <OrderDetailTxt
            title={selectedOrders[0].fields[5].description + " (" + selectedOrders[0].fields[5].typeId + ")"}
            value={selectedOrders[0].fields[5].value}
          />
        </div>
      )}
    </div>
  );
};

export default OrderDetails;