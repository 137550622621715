import {
  createNewOrder,
  setOrderEditing,
  toggleSyncOrderPanel,
  toggleYearlyReportPanel,
} from "./../../../../config/redux/orders/ordersPanel/orderPanelActions";
import { setVehicle } from "../../../../config/redux/orders/ordersActions";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  toggleCancelReason,
  reloadOrders,
  setRealizationDate,
  toggleOrderAccept,
} from "../../../../config/redux/orders/ordersActions";
import {
  setDialogError,
  setListMessage,
} from "./../../../../config/redux/alerts/alertsActions";
import { generateItems } from "../../actions/generateCommands/generateOrdersCommands";
import { checkQuantity } from "../../actions/checkQuantity";
import {
  setToRealization,
  acceptRaport,
  sendSMSToDrivers,
  cancelOrder,
  getRaportPDF,
  getRaportMultiPDF
} from "../../../../connectors/orders/connectors/index";
import {
  toggleOrderPanel,
  toggleSMSPanel,
} from "../../../../config/redux/orders/ordersPanel/orderPanelActions";
import { Quantity } from "../../../common/models/quantity.model";
import acceptedOrders from "../../../orders/pages/AcceptedOrders";

const message = "Operacja przebiegła pomyślnie";

export const useOrdersCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedOrders, realizationDate, vehicle } = useSelector(
    (state: RootStateOrAny) => state.orders
  );
  const location = useLocation();
  const quantity = checkQuantity(selectedOrders.length);

  const pushToRealization = async () => {
    try {
      const mapedIds = selectedOrders.map((item: any) => item.id);

      dispatch(setRealizationDate({ date: "" }));
      dispatch(setVehicle({ vehicle: "" }));
      dispatch(reloadOrders(true));
      dispatch(setListMessage(message));
    } catch (err) {
      dispatch(setDialogError("Wybierz datę i pojazd"));
    }
  };

  const setOrderAccepted = async () => {
    await acceptRaport(selectedOrders[0].id)
      .then((response: any) => {
        console.log(response);
        dispatch(toggleOrderAccept(true));
        dispatch(reloadOrders(true));
      })
      .catch((error: any) => {
        console.error(error);
        // alert("ERRROR 62");
      });
  };

  const setOrderCanceled = async () => {
    await cancelOrder(selectedOrders[0].id)
      .then((response: any) => {
        console.log(response);
        dispatch(toggleOrderAccept(true));
        dispatch(reloadOrders(true));
      })
      .catch((error: any) => {
        console.error(error);
        // alert("ERRROR 72");
      });
  };

  const download = async () => {
    await getRaportPDF(selectedOrders[0].id);
  };

  const downloadMany = async () => {
    let Ids: any[] = [];
    selectedOrders.map((item: any) => (
      Ids.push(item.id)
    ))

    const result = Ids.reduce((acc, x) => acc + `Ids=${x}&`, "").slice(0, -1);
    await getRaportMultiPDF(result)
  }

  const openPanel = () => {
    dispatch(toggleOrderPanel(true));
    dispatch(setOrderEditing(true));
  };

  const openPanelToSee = () => {
    dispatch(toggleOrderPanel(true));
  };

  const openYearlyPanelToSee = () => {
    dispatch(toggleYearlyReportPanel(true));
  };

  const openPanelSMS = () => {
    dispatch(toggleSMSPanel(true));
  };

  const openPanelSyncOrder = () => {
    dispatch(toggleSyncOrderPanel(true));
  };

  const addNewOrder = () => {
    dispatch(createNewOrder(true));
    dispatch(toggleOrderPanel(true));
  };

  const sendSMS = async () => {
    try {
      const mapedIds = selectedOrders.map((item: any) => item.id);
      const res = await sendSMSToDrivers(mapedIds);
      dispatch(setListMessage(message));
    } catch (err) {
      dispatch(setListMessage("Wystąpił błąd"));
      console.error(err);
    }
  };

  const actions = {
    openPanel,
    setOrderCanceled,
    setOrderAccepted,
    openPanelToSee,
    openYearlyPanelToSee,
    download,
    downloadMany
  };

  const items =
    quantity != Quantity.NONE
      ? generateItems(quantity, location.pathname, actions, selectedOrders[0])
      : generateItems(quantity, location.pathname, actions, selectedOrders[0]);

  return {
    items,
  };
};
