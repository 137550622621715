import React from "react";
import { useDispatch } from "react-redux";
import { SearchBox } from "@fluentui/react/";
import { useDelay } from "../../hooks/useDelay";
import { IReload } from "../../../../config/redux/clients/client-actions.types";
import { filter, toggleFiltration,
} from "../../../../config/redux/filters/filterActions";
import { searchBoxStyle } from "../../styles/searchBox.style";

interface ISearchBoxProps {
    reload: (data: IReload) => any;
}

const SearchBoxClients: React.FC<ISearchBoxProps> = ({ reload }) => {
    const dispatch = useDispatch();
    const { setSearchTerm, searchTerm } = useDelay(
        setfiltration
    );

    function setfiltration() {
        dispatch(filter({ searchPhrase: searchTerm }));
        dispatch(toggleFiltration({ filter: true }));
        dispatch(reload({ reload: true }));
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
      };

    return (
        <form onSubmit={handleSubmit}>
            <SearchBox
                styles={searchBoxStyle}
                placeholder="Szukaj klienta"
                name="searchPhrase"
                onChange={(event: any, newValue: string | undefined) => { setSearchTerm(!!newValue ? newValue : '') }}
                type="text"
            />
        </form>
    );
};

export default SearchBoxClients;