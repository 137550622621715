import * as React from "react";
import { Image, Stack, IImageStyles } from "@fluentui/react/";

const imgS: Partial<IImageStyles> = {
  image: {
    height: "45px",
  },
};

const Title: React.FC = () => {
  return (
    <Stack horizontal horizontalAlign="center">
      <Image src="logo.png" styles={imgS}/>
    </Stack>
  );
};

export default Title;