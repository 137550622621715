import React from "react";
import { Formik } from "formik";
import FormDropDown from "../../../common/layout/form/FormDropDown";
import NewFormField from "../../../common/layout/form/NewFormField";
import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import { validationSchemaPrice } from "../../actions/validationSchema";
import { dropDownS } from "../../styles/dropDown.styles";

export interface PriceFormShortProps {
  handleSubmit: any;
  orderTypesItems: Array<any>;
  initValues: any;
  cancelForm: any;
}

const PriceFormShort: React.FC<PriceFormShortProps> = ({
  handleSubmit,
  orderTypesItems,
  initValues,
  cancelForm,
}) => {
  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchemaPrice}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={dropDownS}>
            <FormDropDown
              headerText="Typ"
              name="orderTypeItemId"
              options={orderTypesItems}
            />
          </div>
          <div>
            <NewFormField name="price" label={`Cena`} />
          </div>

          <NewFormBtns
            cancelForm={cancelForm}
            submitBtnText={true ? "Dodaj" : "Edytuj"}
            positionAbsolute={true}
          />
        </form>
      )}
    </Formik>
  );
};

export default PriceFormShort;