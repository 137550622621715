import { IAdminsAction } from "./adminsActions.typse";
import { AdminsActions } from "./adminsActions.enum";

const initState = {
  selectedAdmins: [],
  activeAdmin: {},
  adminsPanelOpen: false,
  reloadAdminsList: false,
  addAdmin: false,
};

export const adminsReducer = (state = initState, action: IAdminsAction) => {
  const { type, payload } = action;

  switch (type) {
    case AdminsActions.SET_SELECTED_ADMIN:
      return { ...state, selectedAdmins: payload };
    case AdminsActions.TOGGLE_ADMINS_PANEL:
      return { ...state, adminsPanelOpen: payload.open };
    case AdminsActions.RELOAD_ADMINS:
      return { ...state, reloadAdminsList: payload.reload };
    case AdminsActions.SET_ADMIN:
      return { ...state, activeAdmin: payload };
    case AdminsActions.ADD_ADMIN:
      return { ...state, addAdmin: payload.add };
    case AdminsActions.CLEAR:
      return { ...initState };
    default:
      return state;
  }
};
