import { OrderPanelActions } from "./orderPanel.enum";

export const setOrderDetails = (data: any) => {
  return {
    type: OrderPanelActions.SET_ORDER_DETAILS,
    payload: data,
  };
};

export const toggleOrderPanel = (isOpen: boolean) => {
  return {
    type: OrderPanelActions.TOGGLE_ORDER_PANEL,
    payload: isOpen,
  };
};

export const toggleYearlyReportPanel = (isOpen: boolean) => {
  return {
    type: OrderPanelActions.TOGGLE_YEARLY_REPORT_PANEL,
    payload: isOpen,
  };
};

export const toggleSyncOrderPanel = (isOpen: boolean) => {
  return {
    type: OrderPanelActions.TOGGLE_SYNC_ORDER_PANEL,
    payload: isOpen,
  };
};

export const toggleSMSPanel = (isOpen: boolean) => {
  return {
    type: OrderPanelActions.TOGGLE_SMS_PANEL,
    payload: isOpen,
  };
};

export const setActiveOrderTab = (tabName: string) => {
    return{
        type: OrderPanelActions.SET_ACTIVE_TAB,
        payload: tabName
    }
}

export const setOrderComments = (data: any) => {
  return {
    type: OrderPanelActions.SET_ORDER_COMMENTS,
    payload: data,
  };
};

export const setOrderHistory = (data: any) => {
  return {
    type: OrderPanelActions.SET_ORDER_HISTORY,
    payload: data,
  };
};

export const addNewComment = (newComment: boolean) => {
  return {
    type: OrderPanelActions.ADD_NEW_COMMENT,
    payload: newComment
  }
}

export const setOrderEditing = (editing: boolean) => {
  return {
    type: OrderPanelActions.SET_ORDER_EDITING,
    payload: editing
  }
}


export const createNewOrder = (add: boolean) => {
  return{
    type: OrderPanelActions.ADD_NEW_ORDER,
    payload: add
  }
}