import React, {useState} from "react";
import {
  Callout,
  DefaultButton,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import {useHistory} from "react-router-dom";
import {btnS, btnsContainer, styles, subtitleContainer,} from "../../styles/layout/userIcon.styles";
import {useUserData} from "../../../features/common/hooks/useUserData";
import {useDispatch} from "react-redux";
import {toggleSelfEditPanel} from "../../../config/redux/client/clientActions";
import authentication from "react-azure-b2c";
import {myTheme} from "../../styles/layout/theme";

export interface UserIconProps { }

const UserIcon: React.FC<UserIconProps> = () => {
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const disptach = useDispatch()
  const { user } = useUserData();
  const history = useHistory();

  const handleCalloutVisibility = () => {
    setIsCalloutVisible(!isCalloutVisible);
  };

  const openSelfEditPanel = () => {
    disptach(toggleSelfEditPanel(true))
  };

  const logout = () => {
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('isActivated');
    authentication.signOut();
  };

  return (
    <div>
      <div className={styles.buttonArea}>
        <Persona
          size={PersonaSize.size32}
          text={user ? user.name : ""}
          hidePersonaDetails={true}
          onClick={handleCalloutVisibility}
          initialsColor={PersonaInitialsColor.coolGray}
        />
      </div>
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          role="alertdialog"
          gapSpace={0}
          target={`.${styles.buttonArea}`}
          onDismiss={handleCalloutVisibility}
          setInitialFocus
          theme={myTheme}
        >
          <>
            <div className={styles.header}>
              <Text className={styles.title}>
                {user.name}
              </Text>
            </div>

            <div className={styles.inner}>
              <div className={subtitleContainer}>
                <Text className={styles.subtitle}>
                  {user.emails[0]}
                </Text>
              </div>
              <div className={btnsContainer}>
                <PrimaryButton styles={btnS} onClick={logout}>
                  Wyloguj
                </PrimaryButton>
                <DefaultButton styles={btnS} onClick={openSelfEditPanel}>
                  Profil
                </DefaultButton>
              </div>
            </div>
          </>
        </Callout>
      )}
    </div>
  );
};

export default UserIcon;
