import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react";
import { panelS } from "../../../features/common/styles/panelStyles";
import {
  setSelectedListItem,
  toggleReceptionPlacePanel,
} from "../../../config/redux/client/clientActions";
import ReceptionPlaceForm from "../forms/ReportsForm";
import ReceptionDataDisplay from "../display/ReceptionDataDisplay";

export interface ReceptionPlacePanelProps {
  predefinedItemData?: any;
}

const ReceptionPlacePanel: React.FC<ReceptionPlacePanelProps> = ({
  predefinedItemData,
}) => {
  const dispatch = useDispatch();
  const { receptionPlacePanelOpen, formActive, selectedItem } = useSelector(
    (state: RootStateOrAny) => state.client
  );

  const dismissPanel = () => {
    dispatch(
      toggleReceptionPlacePanel({ isOpen: false, formActive: formActive })
    );
    dispatch(setSelectedListItem(""));
  };

  return (
    <Panel
      isOpen={receptionPlacePanelOpen}
      closeButtonAriaLabel="Close"
      headerText="Punkt Odbioru"
      onDismiss={dismissPanel}
      type={1}
      styles={panelS}
      isHiddenOnDismiss={false}
    >
      {formActive && <ReceptionPlaceForm predefinedInitValues={predefinedItemData} />}
      {!formActive && selectedItem && (
        <ReceptionDataDisplay selectedItem={!!selectedItem && Object.keys(selectedItem).length > 0 ? selectedItem : (!!predefinedItemData ? predefinedItemData : selectedItem)} />
      )}
    </Panel>
  );
};

export default ReceptionPlacePanel;
