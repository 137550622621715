import React, {useCallback, useEffect, useState} from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react//lib/Dropdown";
import { IMessageBarStyles, MessageBar, MessageBarType } from "@fluentui/react/";
import { useField } from "formik";
import { wrapper } from "../../styles/formDropDown.styles";
import { useEffectAfterMount } from "../../hooks/useEffectAfterMount";
import receptionPlaces from "../../../../client/Pages/ReceptionPlaces";
// import any = jasmine.any;

const errorMessageS: IMessageBarStyles = {
  root: {
    marginTop: "15px",
    width: "100%",
  },
};

export interface FormDropDownProps {
  name: string;
  headerText: string;
  options: Array<any>;
  placeholder?: string;
  onChange?: any
  onChangeEffect?: any,
  required?: boolean
  setReception?: any;
  orderTypeItemId: number;
  setPrice? : any;
  changePrice? :any;
  defaultSelectedKey?:any;
}

const FormDropDownReception: React.FC<FormDropDownProps> = ({
  options,
  headerText,
  placeholder,
  name,
  onChange,
  onChangeEffect,
  required = true,
  setReception,
  orderTypeItemId,
  setPrice,
  changePrice,
  defaultSelectedKey
}) => {
  const [field, { value, error }, { setValue }] = useField(name);

  const handleChange = useCallback(
    async (event: React.FormEvent<HTMLDivElement> | null, option?: IDropdownOption) => {
      if(option) {
        setValue(option.key);
        setReception(option.key)
        if(onChange){
          await onChange(option.key, orderTypeItemId);
        }
      }
    },
    [options]
  );

  useEffect(() => {
    if(!!defaultSelectedKey && !value && options.length === 1) {
      handleChange(null, options[0]);
    }
  }, [defaultSelectedKey, options, value]);

  useEffectAfterMount(() => {
    if(onChangeEffect){
     if(options) {
        const item = options.find((option: any) => {
          if (option.key === value) {
            return option
          }
        })
        onChangeEffect(item)
      }
    }
  },[value, options[0], orderTypeItemId])

  return (
    <div className={wrapper}>
      <Dropdown
        selectedKey={value ? value : ""}
        placeholder={placeholder}
        options={options}
        onChange={handleChange}
        label={headerText}
        required={required}
        defaultSelectedKey={defaultSelectedKey}
      />
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
          styles={errorMessageS}
        >
          {error}
        </MessageBar>
      )}
    </div>
  );
};

export default FormDropDownReception;