import { IQuantity } from "../../../../../common/models/quantity.model";
import { Quantity } from "../../../../../common/models/quantity.model";

export const generateFilesCommands = (
  quantity: IQuantity,
  actions: any
) => {
  if (quantity === Quantity.NONE) {
    return [
      {
        key: "add",
        text: "Dodaj",
        iconProps: { iconName: "add" },
        name: "add",
        onClick: actions.toggle,
      },
    ];
  } else {
    return [
      {
            key: "delete",
            text: "Usuń",
            iconProps: { iconName: "delete" },
            name: "delete",
            onClick: actions.deleteFileById,
        },
        {
            key: "download",
            text: "Pobierz",
            iconProps: { iconName: "download" },
            name: "download",
            onClick: actions.downloadFileById,
        },
    ];
  }
};
