import { IDropdownOption } from "@fluentui/react//lib/components/Dropdown";
import { useEffect, useState } from "react";
import { getYears } from "../../../connectors/drivers/connectors";

export const useDrivers = () => {
  const [options, setOptions] = useState<Array<IDropdownOption>>([]);


  useEffect(() => {
    setDrivers();
  }, []);

  const setDrivers = async () => {
    const res = await getYears();
    const drivers = res.data.result.map((driver: any) => {
      return {
        key: driver.userData.id,
        text: driver.userData.lastName,
      };
    });
    setOptions(drivers);
  };

  return options
};
