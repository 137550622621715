import { RegisterData } from './register-actions.types';
import {Actions} from "./register-actions.enum"

export const setCompanyData = (data: RegisterData) => {
  return {
    type: Actions.SET_COMPANY_DATA,
    payload: data,
  };
};

export const clearCompanyData = () => {
  return {
    type: Actions.CLEAR_COMPANY_DATA,
  };
};

export const setRegistrationComplete = () => {
  return {
    type: Actions.SET_REGISTRATION_COMPLETE,
  };
};

export const setRegisterErr = (error: string) => {
  return {
    type: Actions.SET_REGISTER_ERR,
    payload: error,
  };
};

export const clearErr = () => {
  return {
    type: Actions.CLEAR_ERR,
  };
};

export const togglePricing = (isOpen: boolean) => {
  return{
    type: Actions.TOGGLE_PRICING,
    payload: isOpen
  }
}

export const toggleRegulations = (isOpen: boolean) => {
  return{
    type: Actions.TOGGLE_REGULATIONS,
    payload: isOpen
  }
}
