import React, { useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import RegisterUser from "./user/RegisterUser";
import RegisterDepartment from "./department/RegisterDepartment";
import RegisterCompany from "./company/RegisterCompany";
import RegistrationChoiceGroup from "./RegistrationChoiceGroup";
import AlredyRegisteredHeader from "../AlredyRegisteredHeader";
import { ITextStyles, Text, mergeStyles } from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";

const headerContainer = mergeStyles({
  marginBottom: "20px",
});
export const headerS: ITextStyles = {
  root: {
    fontSize: FontSizes.size20,
  },
};

export enum Forms {
  USER = "USER",
  DEPARTMENT = "DEPARTMENT",
}

const FormsContainer: React.FC = () => {
  const {
    data: { alreadyRegistered, multiDepartment },
  } = useSelector((state: RootStateOrAny) => state.register.company);
  const [activeForm, setActiveForm] = useState<string>();

  const getForm = () => {
    if (activeForm === Forms.USER) {
      return <RegisterUser />;
    } else if (activeForm === Forms.DEPARTMENT) {
      return <RegisterDepartment />;
    }
  };

  return (
    <>
      {alreadyRegistered ? (
        <>
          <AlredyRegisteredHeader />
          {multiDepartment ? (
            <>
              <RegistrationChoiceGroup setActiveForm={setActiveForm} />
              {getForm()}
            </>
          ) : (
            <>
              <div className={headerContainer}>
                <Text styles={headerS}>Zaproś użytkownika</Text>
              </div>
              <RegisterUser />
            </>
          )}
        </>
      ) : (
        <RegisterCompany />
      )}
    </>
  );
};

export default FormsContainer;
