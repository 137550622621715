import {  mergeStyles, ITextStyles } from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";
import { FontWeights } from "@fluentui/react/";

export const container = mergeStyles({
  padding: 15,
  display: "flex",
  flexDirection: "column",
});

export const orderTypeContainer = mergeStyles({
  marginBottom: 15,
});

export const textS: ITextStyles = {
  root: {
    marginBottom: 20,
  },
};

export const headerS: ITextStyles = {
  root: {
    marginBottom: 15,
    marginTop: 15,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};
