import React, { useCallback } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react//lib/Dropdown";
import { IMessageBarStyles, MessageBar, MessageBarType } from "@fluentui/react/";
import { useField } from "formik";

import { wrapper } from "../../styles/formDropDown.styles";
import { useEffectAfterMount } from "../../hooks/useEffectAfterMount";

const errorMessageS: IMessageBarStyles = {
  root: {
    marginTop: "15px",
    width: "100%",
  },
};

export interface FormDropDownProps {
  name: string;
  headerText: string;
  options: Array<any>;
  placeholder?: string;
  onChange?: any
  onChangeEffect?: any,
  required?: boolean
  additionalInfo? :string
}

const FormDropDown: React.FC<FormDropDownProps> = ({
  options,
  headerText,
  placeholder,
  name,
  onChange,
  onChangeEffect,
  required = true,
  additionalInfo = undefined
}) => {
  const [field, { value, error }, { setValue }] = useField(name);

  const handleChange = useCallback(
    async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setValue(option.key);
        if(onChange){
          onChange(option)
        } 
      }
    },
    [options]
  );

  useEffectAfterMount(() => {
    if(onChangeEffect){
      if(options){
        const item = options.find((option: any) => {
          if(option.key === value){
            return option
          }
        })
  
        onChangeEffect(item)
      }
    }
  },[value, options[0]])

  return (
    <div className={wrapper}>
      <Dropdown
        selectedKey={value || value === 0 ? value : ""}
        placeholder={placeholder}
        options={options}
        onChange={handleChange}
        label={headerText}
        required={required}
      />
      {additionalInfo != undefined ? 
      <>{additionalInfo}</>
      : <></>}
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
          styles={errorMessageS}
        >
          {error}
        </MessageBar>
      )}
    </div>
  );
};

export default FormDropDown;