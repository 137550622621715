import { ITextFieldStyles } from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";
import { FontWeights } from "@fluentui/react/";

export const textfieldS: Partial<ITextFieldStyles> = {
  root: {
    marginBottom: "16px",
    width: "100%",
  },

  subComponentStyles: {
    label: {
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.semibold,
    },
  },
};
