import { useEffect, useMemo, useState } from "react";

import { useQuery } from "react-query";
import { Selection } from "@fluentui/react//lib/DetailsList";
import {  getReasons } from "../../../connectors/cancellationReasons/connectors";

export const useReasonsList = (onSelection?: any, onInvoke?: any) => {
  const {data} = useQuery("reasons", getReasons)
  const [groups, setGroups] = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const fetchedData = useMemo(() => {
    if (data) {
      return data
    } else return "";
  }, [data]);

  useEffect(() => {
    if(data){
      generateGroups(data.data.data);
      generateItems(data.data.data);
    }
  }, [JSON.stringify(fetchedData)]);


  const generateGroups = (items: Array<any>) => {
    let index = 0;
    const gr = items.map((item: any) => {
      const grItem = {
        name: item.name,
        count: item.values.length,
        key: item.name,
        startIndex: index,
      };
      index += item.values.length;
      return grItem;
    });

    setGroups(gr);
  };

  const generateItems = (items: Array<any>) => {
    const arr: Array<any> = [];
    items.forEach((item: any) => {
      const parsedItems = item.values.map((item: any) => {
        return{
          ...item,
          reason: item.value
        }
      })
      
      arr.push(...parsedItems);
    });

    setItems(arr);
  };
  

  const columns = useMemo(
    () => [
      {
        key: "reason",
        name: "Powód",
        fieldName: "reason",
        minWidth: 300,
        maxWidth: 500,
        isResizable: true,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          if (onSelection) {
            onSelection(selection.getSelection());
          }
        },
      }),
    []
  );

  const handleInvoke = (item: any) => {
    if (onInvoke) {
      onInvoke();
    }
  };

  return {
    groups,
    items,
    columns,
    selection,
    handleInvoke,
  };
};
