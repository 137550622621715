import * as React from "react";
import OrderPanel from "../components/panels/OrderPanel";
import OrdersListContainer from "../components/lists/orders/OrdersListContainer";
import OrdersReportPanel from "../components/panels/OrdersReportPanel";
import {mergeStyles} from "@fluentui/react";
import ListSuccessMessage from "../../features/common/layout/ListSuccessMessage";

export interface OrdersProps {}

const padding = mergeStyles({
  paddingTop: 15
})

const wrapper = mergeStyles({
  width: "95%",
  margin: "0px auto",
});

const Orders: React.FC<OrdersProps> = () => {
  return (
      <div className={wrapper}>
        <div className={padding}>
          <ListSuccessMessage />
        </div>
        <OrdersListContainer />
        <OrderPanel/>
        <OrdersReportPanel/>
      </div>
  );
};

export default Orders;
