import { PricingActions } from "./pricingActions.enum";
import { PricingAction } from "./pricingActions.types";

const initState = {
  prcingPanelOpen: false,
  selectedPrice: "",
  activePriceType: "",
  pricingEdit: false
};

export const pricingReducer = (state = initState, action: PricingAction) => {
  const { type, payload } = action;

  switch (type) {
    case PricingActions.TOGGLE_PRICING_PANEL:
      return { ...state, prcingPanelOpen: payload };
    case PricingActions.SET_SELECTED_PRICE:
      return { ...state, selectedPrice: payload };
    case PricingActions.SET_ACTIVE_PRICE_TYPE:
      return {...state, activePriceType: payload}  
    case PricingActions.TOGGLE_PRICING_EDIT:
      return {...state, pricingEdit: payload}  
    default:
      return state;
  }
};
