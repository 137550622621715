import { IStackStyles, mergeStyles, getTheme } from "@fluentui/react/";

import { Breakpoints } from "../../common/styles/utils/Breakpoints";

const theme = getTheme();

export const checbkoxContainerS: IStackStyles = {
  root: {
    marginBottom: 25,
  },
};

export const checbkoxClass = mergeStyles({
  marginBottom: 40,
});

export const margin = mergeStyles({
  marginTop: 30,
  marginBottom: 30,
});

export const containerS: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    selectors: {
      [`@media ${Breakpoints.LARGE}`]: {
        alignItems: "flex-start",
      },
    },
  },
};

export const regulationsLinkS = mergeStyles({
  color: theme.palette.themePrimary,
  selectors: {
    ":hover": {
      cursor: "pointer",
    },
  },
});
