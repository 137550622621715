export const storeDocsColumns = [
    {
        key: "documentStatusStr",
        name: "Status",
        fieldName: "documentStatusStr",
        minWidth: 80,
        maxWidth: 120,
        isResizable: true,
    },
    {
        key: "description",
        name: "Numer dokumentu",
        fieldName: "description",
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: "documentDate",
        name: "Data wystawienia",
        fieldName: "documentDate",
        minWidth: 80,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "documentTypeName",
        name: "Typ",
        fieldName: "documentTypeName",
        minWidth: 80,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "companyName",
        name: "Klient",
        fieldName: "companyName",
        minWidth: 250,
        maxWidth: 500,
        isResizable: true,
    },
    {
        key: "receptionPlaceName",
        name: "Punkt odbioru",
        fieldName: "receptionPlaceName",
        minWidth: 250,
        maxWidth: 500,
        isResizable: true,
    },
    {
        key: "internalStoreName",
        name: "Magazyn wewnętrzny",
        fieldName: "internalStoreName",
        minWidth: 200,
        maxWidth: 500,
        isResizable: true,
    },
    {
        key: "containersNumber",
        name: "Ilość",
        fieldName: "containersNumber",
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
    },
];