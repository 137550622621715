import React, {useCallback, useEffect, useState} from "react";
import { useField } from "formik";

import { Checkbox } from "@fluentui/react//lib/Checkbox";
import {
  MessageBar,
  MessageBarType,
  IMessageBarStyles,
} from "@fluentui/react/";
import {boolean} from "yup";

const errorMessageS: IMessageBarStyles = {
  root: {
    marginTop: "15px",
    width: "100%",
  },
};

export interface NewFormCheckboxProps {
  name: string;
  label: string;
  checked: boolean;
  disabled: boolean;
  value?: any;
  customHandleChange?: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
}

const NewFormCheckbox: React.FC<NewFormCheckboxProps> = ({ name, label, checked, disabled, customHandleChange}) => {
  const [field, {value, touched, error }, { setValue }] = useField(name);
  const [isChecked, setIsChecked] = useState(checked);
  const [isDisabled, setIsDisabled] = useState(disabled);

  const handleChange = useCallback(
    async (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
      setIsChecked(typeof checked === 'boolean' ? checked : false);
      setValue(typeof checked === 'boolean' ? checked : false);
    },
    []
  );

  // useEffect(() => {
  //   setIsChecked(isChecked);
  // }, [isChecked]);

  return (
    <>
      <Checkbox
        label={label}
        checked={isChecked}
        onChange={customHandleChange ? customHandleChange : handleChange}
        disabled={isDisabled}
      />
      {touched && error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
          styles={errorMessageS}
        >
          {error}
        </MessageBar>
      )}
    </>
  );
};

export default NewFormCheckbox;
