import React from "react";
import { Formik, Form } from "formik";
import { Stack } from "@fluentui/react/";

import PanelHeader from "../../../common/layout/PanelHeader";
import NewFormField from "../../../common/layout/form/NewFormField";
import ActivityIndicator from "../../../common/layout/ActivityIndicator";
import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import MessageContainer from "../../../auth/components/register/forms/MessageContainer";
import { useSelfEditForm } from "../../hooks/useSelfEditForm";

const SelfEditForm: React.FC = () => {
  const { cancelForm, handleSubmit } = useSelfEditForm();

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack>
            <PanelHeader text={"Edytuj profil"} />
            <NewFormField name="lastName" label="Nazwisko" />
            <NewFormField name="firstName" label="Imię" />
            <NewFormField name="phone" label="Telefon" />
            <NewFormField name="email" label="Email" />
            <NewFormBtns
              cancelForm={cancelForm}
              submitBtnText={"Zapisz"}
              disabled={false}
            />
          </Stack>
          {false ? <ActivityIndicator /> : <MessageContainer />}
        </Form>
      )}
    </Formik>
  );
};

export default SelfEditForm;
