import { IDetailsListStyles } from "@fluentui/react/";

export const listS: Partial<IDetailsListStyles> = {
  contentWrapper: {
    selectors: {
      "div[data-automationid=DetailsRowCell]": {
        cursor: "pointer",
      },
      "div[data-automationid=DetailsRowCheck]": {
        cursor: "pointer",
      },
    },
  },
};
