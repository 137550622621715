import { OrderTabsNames } from "../../../../orders/models/OrderTabs.enum";
import { NavigationPath } from "./../../../../../config/routing/NavigationPath";

export const generateItems = (
  state: any,
  pathname: string,
  actions: any,
  activeTab: string,
  editing: boolean
) => {
  const { addComment } = state;
  let items: Array<any> = [];

  if(editing){
    return []
  }else if (activeTab === OrderTabsNames.COMMENTS && !addComment) {
    items = [
      {
        key: "add",
        text: "Dodaj komentarz",
        iconProps: { iconName: "add" },
        name: "add",
        onClick: actions.addComment,
      },
    ];
  } else if (activeTab === OrderTabsNames.DETAILS) {
    if (pathname === NavigationPath.REPORTS_ALL ) {
      items = [
        {
          key: "activate",
          text: "Akceptuj raport",
          iconProps: { iconName: "followUser" },
          name: "activate",
          onClick: actions.setOrderAccepted,
        },
        {
          key: "delete",
          text: "Odrzuć raport",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.setOrderCanceled,
        },
      ];
    } else if (pathname === NavigationPath.REPORTS_APPROVED) {
      return []
    } else if (pathname === NavigationPath.REPORTS_WAITING) {
      items = [
        {
          key: "activate",
          text: "Akceptuj raport",
          iconProps: { iconName: "followUser" },
          name: "activate",
          onClick: actions.setOrderAccepted,
        },
        {
          key: "delete",
          text: "Odrzuć raport",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.setOrderCanceled,
        },
      ];
    }
  }

  return items;
};
