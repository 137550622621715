import {IClients, IActiveClient, IReload, IOpenPanel} from "./client-actions.types"
import {ClientsActions} from "./clients-actions.enum"

export const setClientOrderType = (id :any) => {
  return {
    type: ClientsActions.SET_CLIENT_ORDER_TYPE,
    payload: id,
  }
}

export const setClients = (data: IClients) => {
  return {
    type: ClientsActions.SET_CLIENTS,
    payload: data,
  };
};

export const clearListData = () => {
  return {
    type: ClientsActions.CLEAR_LIST_DATA,
  };
};


export const setActiveClient = (data: IActiveClient) => {
  return {
    type: ClientsActions.SET_ACTIVE_CLIENT,
    payload: data
  }
}


export const reloadData = (data: IReload) => {
  return {
    type: ClientsActions.RELOAD_DATA,
    payload: data
  }
}

export const toggleAddComapnyPanel = (data: IOpenPanel) => {
  return {
    type: ClientsActions.TOGGLE_PANEL,
    payload: data
  }
}

export const addNewClient = (add :boolean) => {
  return {
    type: ClientsActions.ADD_NEW_CLIENT,
    payload: add
  }
}

export const toggleDeactivationReason = (isOpen: boolean) => {
  return{
    type: ClientsActions.TOGGLE_DEACTIVATION_REASON,
    payload: isOpen
  }
}