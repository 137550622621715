import React from 'react';
import { wrapper } from '../../auth/styles/activityIndicatorContainer.styles';
import ListSuccessMessage from '../../common/layout/ListSuccessMessage';
import { padding } from '../../reports/styles/style';
import StorePanel from '../panel/StorePanel';
import StoreList from './StoreList';

export interface StoreProps { }

const Store: React.FC<StoreProps> = () => {
    return (
        <div className={wrapper}>
            <div className={padding}>
                <ListSuccessMessage />
            </div>
            <StoreList />
            <StorePanel />
        </div>
    );
}

export default Store;