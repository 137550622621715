import {clearAlerts, setListMessage, setErrorAlert} from './../../../config/redux/alerts/alertsActions';
import {useDispatch, RootStateOrAny, useSelector} from "react-redux";
import {FormikValues} from "formik";

import {
    setDriver,
    addNewDriver,
    toggleDriversPanel,
} from "../../../config/redux/drivers/driversActions";
import {addYear, editDriver} from "../../../connectors/drivers/connectors/index";
import {IDriver} from "../../../connectors/drivers/models/index"
import {IResetForm} from "../../common/models/resetForm.type";
import {useState} from "react";
import Guid from "guid";

export const useDriverForm = () => {
    const [loading, setIsLoading] = useState(false)
    const {selectedDrivers, activeDriver} = useSelector(
        (state: RootStateOrAny) => state.drivers
    );
    const dispatch = useDispatch();
    let initValues = {
        id: new Date().getFullYear(),
        isActive: false,
    };

    if (selectedDrivers[0]) {
        initValues = {
            id: selectedDrivers[0].id,
            isActive: selectedDrivers[0].isActive,
        };
    }

    const add = async (data: IDriver) => {
        const res = await addYear(data);
    };

    const edit = async (data: IDriver) => {
        editDriver(data, selectedDrivers[0].id);
    };

    const handleSubmit = async (
        values: any,
        {resetForm}: IResetForm
    ) => {
        setIsLoading(true)
        const body = {
            id: values.year,
            isActive: values.isActive,
        };

        try {
            dispatch(clearAlerts())
            selectedDrivers.length >= 1 ? await edit(body) : await add(body);
            setIsLoading(false)
            dispatch(setDriver({}));
            dispatch(addNewDriver({add: true}));
            resetForm();
            dispatch(toggleDriversPanel({open: false}));
            dispatch(setListMessage("Operacja przebiegła pomyślnie"))
        } catch (err) {
            setIsLoading(false)
            dispatch(setErrorAlert("Wystąpił błąd"))
        }
    };

    const cancelForm = () => {
        dispatch(toggleDriversPanel({open: false}));
        dispatch(clearAlerts())
    };

    return {
        handleSubmit,
        initValues,
        cancelForm,
        selectedDrivers,
        loading
    };
};
