import { clearAlerts } from './../../../config/redux/alerts/alertsActions';
import { useDispatch } from "react-redux"
import { clearData } from "../../../config/redux/clients/panel/clientPanelActions"
import { toggleAddStoreDocPanel } from '../../../config/redux/storeDocs/storeDocsActions';

export const useCancelFrom = () => {
    const dispatch = useDispatch();

    const cancel = () => {
        dispatch(clearData());
        dispatch(clearAlerts());
        dispatch(toggleAddStoreDocPanel({ isOpen: false, add: false }));
    };

    return cancel;
}