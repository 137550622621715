import * as React from "react";
import DisplayText from "./DisplayTxt";
import NewFormField from "../../../features/common/layout/form/NewFormField";
import ClientOrderPanelCommandBar from "../commandBar/ClientOrderPanelCommandBar";

export interface OrderDisplayProps {
  selectedItem: any;
}

const OrderDisplay: React.FC<OrderDisplayProps> = ({
   selectedItem,
}) => {

  return (
      <>
        <DisplayText title={"Raport z roku:"} value={selectedItem.year}/>
        <div>
          <DisplayText title={selectedItem.fields[0].description +" (" + selectedItem.fields[0].typeId + ")" } value={selectedItem.fields[0].value + " kg"} />
          <DisplayText title={selectedItem.fields[1].description +" (" + selectedItem.fields[1].typeId + ")" } value={selectedItem.fields[1].value + " kg"} />
          <DisplayText title={selectedItem.fields[2].description +" (" + selectedItem.fields[2].typeId + ")" } value={selectedItem.fields[2].value + " kg"} />
          <DisplayText title={selectedItem.fields[3].description +" (" + selectedItem.fields[3].typeId + ")" } value={selectedItem.fields[3].value + " kg"} />
          <DisplayText title={selectedItem.fields[4].description +" (" + selectedItem.fields[4].typeId + ")" } value={selectedItem.fields[4].value + " kg"} />
          <DisplayText title={selectedItem.fields[5].description +" (" + selectedItem.fields[5].typeId + ")" } value={selectedItem.fields[5].value + " kg"} />
        </div>
      </>

  );
};

export default OrderDisplay;
