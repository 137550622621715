import React from "react";

import MessageContainer from "../../../auth/components/register/forms/MessageContainer";
import ActivityIndicator from "../../../common/layout/ActivityIndicator";
import { useEditPrice } from "../../hooks/useEditPrice";
import { useSinglePrice } from "../../hooks/useSignlePrice";
import EditPriceForm from "./EditPriceForm";

const EditPrice: React.FC = () => {
  const {
    editPrice,
    loading,
    selectedPrice,
    cancelForm,
    initValues,
  } = useEditPrice();
  const { values } = useSinglePrice();

  
  return (
    <>
      <EditPriceForm
        handleSubmit={editPrice}
        selectedPrice={selectedPrice}
        cancelForm={cancelForm}
        initValues={selectedPrice.postalCodePrices ? values : initValues}
      />
      {loading ? <ActivityIndicator /> : <MessageContainer />}
    </>
  );
};

export default EditPrice;
