import { IQuantity, Quantity } from "../../../../common/models/quantity.model";

export const generateCancellation = (quantity: IQuantity, actions: any) => {
    const realizationBtn = {
        key: "check out",
        text: "Realizuj",
        iconProps: { iconName: "BusSolid" },
        onClick: actions.pushToRealization,
    };

    switch (quantity) {
        case Quantity.NONE:
            return []
        case Quantity.ONE:
            return [
                {
                    key: "add",
                    text: "Zobacz",
                    iconProps: { iconName: "textDocument" },
                    onClick: actions.openPanelToSee,
                },
                {
                    key: "download",
                    text: "Pobierz",
                    iconProps: { iconName: "ReportDocument" },
                    onClick: actions.download,
                },
            ];
    }
};
