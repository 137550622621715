import axios from "axios";
import { useDispatch } from "react-redux";
import api from "../../../config/axios/configAxios";
import { setErrorAlert } from "../../../config/redux/alerts/alertsActions";
import {
  IOrderParams,
  IRealizationDate,
  IAddOrder,
  IOrdersReportParams,
} from "../../../connectors/orders/models/index";

export const getOrders = async (params?: IOrderParams) =>
  api.get(`/api/services/app/Report/GetAll`, { params });

export const getOrderHistory = async (orderId: string) =>
  api.get(`/api/Admin/Orders/${orderId}/history`);

export const getPickUpPoints = async (departmentId: string) =>
  api.get(`/api/Admin/Departments/${departmentId}/reception-places`);

export const getOrderDetails = async (orderId: string) =>
  api.get(`/api/services/app/Report/GetAll?reportId=${orderId}`);

export const getValidYears = async () =>
  api.get(`/api/services/app/Year/GetValidYears`);

export const getOrderComments = async (orderId: string) =>
  api.get(`/api/Admin/Orders/${orderId}/comments`);

export const addComment = async (orderId: string, data: any) =>
  api.post(`/api/Admin/Orders/${orderId}/add-comment`, data);

export const setToRealization = async (id: number) =>
  api.post(`/api/services/app/Report/SetPending`, { id: id });

export const acceptRaport = async (id: number) =>
  api.post(`/api/services/app/Report/SetApproved`, { id: id });

export const cancelOrder = async (id: number) =>
  api.post(`/api/services/app/Report/SetRejected`, { id: id });

export const addOrder = async (data: IAddOrder) =>
  api.post(`/api/services/app/Report/Set`, data);

export const editOrder = async (data: any) =>
  api.post(`/api/services/app/Report/Set`, data);

export const completeOrder = async (orderId: string) =>
  api.put(`/api/Admin/Orders/${orderId}/set-realized`);

export interface IGetNetto {
  receptionPlaceId: number;
  itemId: number;
}

export const getNetto = async (data: IGetNetto) =>
  api.get(
    `/api/Admin/OrderTypeItems/${data.itemId}?receptionPlaceId=${data.receptionPlaceId}`
  );

export const getRaportPDF = async (id: number) => {
  return api
    .get(`/api/services/app/Report/GetPdf?Id=${id}`, { responseType: "blob" })
    .then(
      (response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Raport.pdf`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      },
      (error) => {
        console.error(error);
      }
    );
};

export const getRaportMultiPDF = async (ids: number) => {
  return api
    .get(`/api/services/app/Report/GetMultiPdf?${ids}`, { responseType: "blob" })
    .then(
      (response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Raport.pdf`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      },
      (error) => {
        console.error(error);
      }
    );
};

export const getAnnualRaportPDF = async (id: number) => {
  return api
    .get(`/api/services/app/Year/GetPdf?Id=${id}`, { responseType: "blob" })
    .then(
      (response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Raport.pdf`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      },
      (error) => {
        console.error(error);
      }
    );
};

export const sendSMSToDrivers = async (orders: any) =>
  api.post(`/api/Admin/Orders/notify-driver`, { orders: orders, channel: 0 });
