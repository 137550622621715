import { NavigationPath } from "../../../config/routing/NavigationPath";
import {LinksNames} from "./LinkNames.enum"

export const clientsPaths = [
   // {id: NavigationPath.UNVERIFIED_CLIENTS, name: LinksNames.UNVERIFIED_CLIENTS},
   {id: NavigationPath.ACTIVE_CLIENTS, name: LinksNames.ACTIVE_CLIENTS},
   {id: NavigationPath.INACTIVE_CLIENTS, name: LinksNames.INACTIVE_CLIENTS},
];

export const reportsPaths = [
   {id: NavigationPath.REPORTS_WAITING, name: LinksNames.REPORTS_WAITING},
   {id: NavigationPath.REPORTS_APPROVED, name: LinksNames.REPORTS_APPROVED},
   {id:NavigationPath.REPORTS_REJECTED, name: LinksNames.REPORTS_REJECTED},
   {id: NavigationPath.REPORTS_ALL, name: LinksNames.REPORTS_ALL},
]

export const configPaths = [
   {id: NavigationPath.ORDER_TYPES, name: LinksNames.ORDER_TYPES},
   {id: NavigationPath.VEHICLES, name: LinksNames.VEHICLES},
   {id: NavigationPath.DRIVERS, name: LinksNames.DRIVERS},
   {id: NavigationPath.PRICING, name: LinksNames.PRICING}
]