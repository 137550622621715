import React from "react";
import {
  Dropdown,
  IDropdownOption,
} from "@fluentui/react//lib/Dropdown";
import {mergeStyles} from "@fluentui/react/"
import { Text, ITextStyles } from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";
import { FontWeights } from "@fluentui/react/";

import {MethodsOfPayment} from "../../../models/MethodsOfPayment.enum"

const wrapper = mergeStyles({
  paddingTop: 20,
  paddingBottom: 15
})


const headerS: ITextStyles = {
  root: {
    display: "block",
    marginBottom: 15,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};

const options = [
  {
    key: MethodsOfPayment.CASH,
    text: `Gotówka`,
  },
  {
    key: MethodsOfPayment.TRANSFER,
    text: "Przelew",
  },
];


export interface PaymentMethodProps {
    setFieldValue: any,
    paymentType: any
  }
  
  const PaymentMethod: React.FC<PaymentMethodProps> = ({
    setFieldValue, paymentType
  }) => {
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
  
    const handleChange = (
      event: React.FormEvent<HTMLDivElement>,
      option: IDropdownOption | undefined
    ): void => {
      if(option){
          setSelectedItem(option);
          setFieldValue('paymentType', option.key)
      }
    };
  
    return (
      <div className={wrapper}>
        <Text styles={headerS}>Metoda Płatności</Text>
        <Dropdown
          selectedKey={selectedItem ? selectedItem.key : paymentType}
          options={options}
          onChange={handleChange}
        />
      </div>
    );
  };
  
  export default PaymentMethod;