import { IQuantity, Quantity } from "../../../common/models/quantity.model";
import { OrderTypes } from "../../../common/enums/orderTypes.enum";

interface IActions {
    edit: () => void;
    verifyPickuPoint: (e:any) => Promise<void>;
}

export const generateItems = (
    quantity: IQuantity,
    actions: IActions,
    selectedUnactiveUser: any,
    activatedUsers: any,
    orderTypes: any
) => {

    const itemsArr = orderTypes.map(({ key, text }: any) => {
        let icon = { iconProps: {} };
    
        if (text === OrderTypes.VEHICLE) {
          icon = { iconProps: { iconName: "Car" } };
        } else if (text === OrderTypes.CONTAINER) {
          icon = { iconProps: { iconName: "Inbox" } };
        } else if (text === OrderTypes.WEIGHT) {
          icon = { iconProps: { iconName: "Weights" } };
        }
         
        return {
          key: key,
          id: key,
          text: text,
          iconProps: icon.iconProps,
          onClick: actions.verifyPickuPoint,
        };
      })
    switch (quantity) {
        case Quantity.NONE:
            return [

            ];
        case Quantity.ONE:
            if (selectedUnactiveUser && activatedUsers[selectedUnactiveUser.id]) {
                return [

                ];
            } else {

                return [
                    {
                        key: "verify",
                        text: "Zweryfikuj",
                        iconProps: { iconName: "send" },
                        name: "verify",
                        subMenuProps: {
                            items: [...itemsArr],
                        },
                    },
                    {
                        key: "edit",
                        text: "Edytuj",
                        iconProps: { iconName: "edit" },
                        name: "edit",
                        onClick: actions.edit
                    }
                ];
            }

        default:
            return [];
    }
};
