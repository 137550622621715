import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "@fluentui/react/";

import {
  setSelectedOperator,
  toggleOperatorsPanel,
} from "../../../../config/redux/operators/operatorsActions";
import { panelS } from "../../../common/styles/panelStyles";
import EditOperator from "../forms/EditOperator";
import AddNewOperator from "../forms/AddNewOperator";
import { clearAlerts } from "../../../../config/redux/alerts/alertsActions";

const OperatorPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { operatorsPanelOpen, selectedOperator } = useSelector(
    (state: RootStateOrAny) => state.operators
  );

  const dismissPanel = () => {
    dispatch(toggleOperatorsPanel(false));
    dispatch(clearAlerts())
    dispatch(setSelectedOperator(""));
  };

  

  return (
    <Panel
      isOpen={operatorsPanelOpen}
      closeButtonAriaLabel="Close"
      onDismiss={dismissPanel}
      headerText="Operator"
      type={1}
      styles={panelS}
      isHiddenOnDismiss={false}
    >
      {selectedOperator ? (
        <EditOperator
          cancelForm={dismissPanel}
          operatorId={selectedOperator ? selectedOperator.id : ''}
        />
      ) : (
        <AddNewOperator cancelForm={dismissPanel} />
      )}
    </Panel>
  );
};

export default OperatorPanel;
