import { NavigationPath } from "./../../../config/routing/NavigationPath";
import { OrderStatus } from "../models/OrderStatus.enum";

export const getParams = (pathname: string) => {
  let params = {};
  switch (pathname) {
    case NavigationPath.REPORTS_ALL:
      return (params = {});
    case NavigationPath.REPORTS_APPROVED:
      return (params = { Status: OrderStatus.ACCEPTED });
    case NavigationPath.REPORTS_WAITING:
      return (params = { Status: OrderStatus.IN_PROGRESS });
    case NavigationPath.REPORTS_REJECTED:
      return (params = { Status: OrderStatus.CANCELED });
  }

  return params;
};
