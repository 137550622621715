import { mergeStyleSets, TooltipHost } from "@fluentui/react";
import { IColumn, Icon } from "@fluentui/react/";

const classNames = mergeStyleSets({
  active: {
    fontSize: "18px",
    color: "green",
  },
  inActive: {
    fontSize: "18px",
    color: "red",
  },
});

export const ClientsColumns: IColumn = [
  {
    name: "Imię i nazwisko",
    fieldName: "fullName",
    key: "fullName",
    minWidth: 50,
    maxWidth: 150,
  },
  {
    name: "E-mail",
    fieldName: "emailAddress",
    key: "emailAddress",
    minWidth: 100,
    maxWidth: 200,
  },
  {
    name: "Nazwa firmy",
    fieldName: "company.companyinfo.name",
    key: "company",
    minWidth: 50,
    maxWidth: 380,
    onRender: (item: any) => {
      return item.company ? item.company.companyInfo.name : "Brak";
    },
  },
  {
    name: "NIP",
    fieldName: "nip",
    key: "nip",
    minWidth: 100,
    maxWidth: 200,
    onRender: (item: any) => {
      return item.nip ? item.nip : "Brak";
    },
  },
  {
    name: "BDO",
    fieldName: "bdo",
    key: "bdo",
    minWidth: 100,
    maxWidth: 200,
    onRender: (item: any) => {
      return item.data.bdo ? item.data.bdo : "Brak";
    },
  },
  // {
  //     name: 'Aktywowany',
  //     fieldName: 'isActive',
  //     minWidth: 50,
  //     maxWidth: 80,
  //     onRender: (item: any) => {
  //         if (item.isActive) {
  //             return (
  //                 <TooltipHost content="Aktywny">
  //                     <Icon iconName={'CheckMark'} className={classNames.active}/>
  //                 </TooltipHost>
  //             )
  //         } else {
  //             return (
  //                 <TooltipHost content="Nieaktywny">
  //                     <Icon iconName={'Cancel'} className={classNames.inActive}/>
  //                 </TooltipHost>
  //             )
  //         }
  //     }
  // }
];
