export const pricesColumns = [
    {
      key: "Nazwa",
      name: "Nazwa",
      fieldName: "name",
      minWidth: 300,
      maxWidth: 600,
      isResizable: true,
    },
    {
      key: "Cena",
      name: "Cena",
      fieldName: "priceValue",
      minWidth:100,
      maxWidth: 200,
      isResizable: true,
    },
];