import { IButtonStyles } from "@fluentui/react//lib/Button";
import { SharedColors } from "@fluentui/react/";

export const errorButtonS: IButtonStyles = {
  root: {
    marginTop: 20,
    backgroundColor: SharedColors.red20,

    selectors: {
      [":hover"]: {
        backgroundColor: SharedColors.pinkRed10,
      },
    },
  },

  rootHovered: {
    backgroundColor: SharedColors.pinkRed10,
  },

  rootPressed: {
    backgroundColor: SharedColors.red20,
  },
};
