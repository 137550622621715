import * as yup from "yup";

export const validationSchemaDrivers = yup.object().shape({
  id: yup.string().required("Żeby dodać nowy rok podaj jego wartość").max(4, "Rok musi składać się z 4 cyfr"),
  // email: yup
  //   .string()
  //   .email("Podaj poprawny email")
  //   .required("Żeby dodać kierowce podaj email")
  //   .max(50),
});
