import {IInActiveActions} from "../../../models/action.types";
import {Quantity, IQuantity} from "../../../../common/models/quantity.model";

export const generateInActivecommands = (
    quantity: IQuantity,
    actions: IInActiveActions
) => {

    const items = [
        {
            key: "activate",
            text: "Aktywuj",
            iconProps: {iconName: "CheckMark"},
            name: "activate",
            onClick: actions.activate,
        },
        // {
        //     key: "deactivate",
        //     text: "Deaktywuj",
        //     iconProps: {iconName: "Cancel"},
        //     name: "deactivate",
        //     // onClick: actions.deactivate,
        // }
    ]

    if (quantity === Quantity.NONE) {
        return [];
    } else if (quantity === Quantity.ONE) {
        return [
            {
                key: "edit",
                text: "Edytuj dostępne lata",
                iconProps: {iconName: "edit"},
                name: "edit",
                onClick: actions.edit,
            },
            ...items,
        ];

    } else {
        return [

        ];
    }
};