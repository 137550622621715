import {OrderTypesActions} from "./orderTypesActions.enum"
import {
  IAdd,
  IOrderType,
  IEditing,
  IReload,
  IOrderTypeItem,
  IOrderTypes,
} from "./orderTypesAction.types";

export const addOrdersType = (data: IAdd) => {
  return {
    type: OrderTypesActions.ADD,
    payload: data,
  };
};

export const setSelectedOrderTypes = (data: any) => {
  return {
    type: OrderTypesActions.SET_SELECTED_ORDER_TYPES,
    payload: data,
  };
};

export const setActiveOrderType = (data: IOrderType) => {
  return {
    type: OrderTypesActions.SET_ORDER_TYPE,
    payload: data,
  };
};

export const setEditing = (data: IEditing) => {
  return {
    type: OrderTypesActions.SET_EDITING,
    payload: data,
  };
};

export const clear = () => {
  return {
    type: OrderTypesActions.CLEAR_ORDER_TYPES_DATA,
  };
};

export const reload = (data: IReload) => {
  return {
    type: OrderTypesActions.RELOAD,
    payload: data,
  };
};

export const setActiveorderTypeItem = (data: IOrderTypeItem) => {
  return {
    type: OrderTypesActions.SET_ACTIVE_ORDERTYPE_ITEM,
    payload: data,
  };
};
export const setOrderTypes = (data: IOrderTypes) => {
  return {
    type: OrderTypesActions.SET_ORDER_TYPES,
    payload: data,
  };
};
