import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import CommentInput from "../../../features/orders/components/forms/CommentInput";
import OrderParams from "../../../features/orders/components/forms/newOrder/OrderParams";
import { useNewOrderFrom } from "../../../features/orders/hooks/useNewOrderForm";
import { useOrderForm } from "../../hooks/useOrderForm";

//mmimport { containerS } from "../.. styles/containerStyles";
import OrderBasicInfo from "../../../features/orders/components/forms/edditOrder/OrderBasicInfo";
import PaymentMethod from "../../../features/orders/components/forms/edditOrder/PaymentMethod";
import MessageContainer from "../../../features/auth/components/register/forms/MessageContainer";
import Prices from "../../../features/orders/components/forms/Prices";
import { containerS } from "../../../features/orders/styles/containerStyles";
import FormDropDownReception from "../../../features/common/layout/form/FormDropDownReception";
import FormDropDown from "../../../features/common/layout/form/FormDropDown";
import NewFormField from "../../../features/common/layout/form/NewFormField";
import FormHeader from "../../../features/clients/components/edditionPanel/FormHeader";

export interface EditOrderClientFormProps {
  selectedItem: any;
}

const EditOrderClientForm: React.FC<EditOrderClientFormProps>  = ({
}) =>{
  var companyId  = sessionStorage.getItem("companyId");
  var departmentId = sessionStorage.getItem("departmentId");
  
  const { 
    handleSubmit, 
    cancelForm, 
    initValues,
    isLoading,

  } = useOrderForm(companyId ? companyId : "", departmentId ? departmentId : "", []);

  return (
    <div className={containerS}>
      {/*<OrderBasicInfo activeOrder={orderDetails} />*/}
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <FormHeader text={"Edycja raportu"} />
            <NewFormField type={"number"} name={"year"} label={"Rok"} value={"0"} disabled={true}/>
            <NewFormField type={"number"} name={"firstPKWiU"} label={"22.11.11.0 [kg]"} placeholder={"0"} value={"0"}/>
            <NewFormField type={"number"} name={"secondPKWiU"} label={"22.11.12.0 [kg]"} placeholder={"0"} value={"0"}/>
            <NewFormField type={"number"} name={"thirdPKWiU"} label={"22.11.13.0 [kg]"} placeholder={"0"} value={"0"}/>
            <NewFormField type={"number"} name={"fourthPKWiU"} label={"22.11.14.0 [kg]"} placeholder={"0"} value={"0"}/>
            <NewFormField type={"number"} name={"fifthPKWiU"} label={"22.11.20.0 [kg]"} placeholder={"0"} value={"0"}/>
            <NewFormField type={"number"} name={"sixthPKWiU"} label={"22.11.53.0 [kg]"} placeholder={"0"} value={"0"}/>

            <NewFormBtns positionAbsolute={true} submitBtnText="Zapisz" cancelForm={cancelForm} disabled={isLoading}/>
            {isLoading ? <ActivityIndicator/> : <MessageContainer/>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditOrderClientForm;