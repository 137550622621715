import React from "react";
import {
  mergeStyles,
  Text,
  ITextStyles,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { Depths, NeutralColors } from "@fluentui/react";
import { Breakpoints } from "../features/common/styles/utils/Breakpoints";
import ResetPassForm from "../features/auth/components/resetPass/ResetPassForm";
import Title from "../features/auth/components/login/Title";
import { useResetPass } from "../features/auth/hooks/useResetPass";
import ResetMessage from "../features/auth/components/resetPass/ResetMessage";

const wrapperS = mergeStyles({
  width: "100%",
  height: "100vh",
  backgroundColor: NeutralColors.gray10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const formContainer = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  selectors: {
    [`@media ${Breakpoints.LARGE}`]: {
      height: "400px",
      width: "450px",
      padding: 50,
      boxShadow: Depths.depth64,
    },
  },
});

const textS: ITextStyles = {
  root: {
    fontSize: 24,
    fontWeight: 600,
    marginTop: 15,
    textAlign: "center",
  },
};

const loadingContent = mergeStyles({
  width: "100%",
  height: 70,
  marginTop: 20,
  display: "flex",
  justifyContent: "center",
});

const NewPass: React.FC = () => {
  const { loading, handleReset, message, cancelForm } = useResetPass();

  return (
    <div className={wrapperS}>
      <div className={formContainer}>
        <Title />
        <Text styles={textS}>Odzyskaj hasło</Text>
        <ResetPassForm handleReset={handleReset} loading={loading} cancelForm={cancelForm}/>
        <div className={loadingContent}>
          {loading ? (
            <Spinner size={SpinnerSize.large} />
          ) : (
            <ResetMessage message={message} />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewPass;
