import {
  IEditing,
  IActiveDepartment,
  IActivePickUpPoint,
  IActiveUser,
  IActiveFile,
  ISelectedIds,
  IReload,
  ISuccessMessage
} from "./clientPanelActions.types";
import {ClientPanelActions} from "./clientPanelActions.enum"

export const toggleEditing = (data: IEditing) => {
  return {
    type: ClientPanelActions.TOGGLE_EDITING,
    payload: data,
  };
};

export const setActiveTab = (data: string) => {
  return {
    type: ClientPanelActions.SET_ACTIVE_TAB,
    payload: data,
  };
};

export const setActiveUser = (data: IActiveUser) => {
  return {
    type: ClientPanelActions.SET_ACTIVE_USER,
    payload: data,
  };
};

export const setActiveDepartment = (data: IActiveDepartment) => {
  return {
    type: ClientPanelActions.SET_ACTIVE_DEPARTMENT,
    payload: data,
  };
};

export const setActivePickUpPoint = (data: IActivePickUpPoint) => {
  return {
    type: ClientPanelActions.SET_ACTIVE_PICKUPPOINT,
    payload: data,
  };
};

export const setActiveFiles = (data: IActiveFile) => {
  return {
    type: ClientPanelActions.SET_ACTIVE_FILES,
    payload: data,
  };
};

export const clearData = () => {
  return {
    type: ClientPanelActions.CLEAR,
  };
};

export const setSelectedIds = (data: ISelectedIds) => {
  return {
    type: ClientPanelActions.SET_SELECTED_IDS,
    payload: data,
  };
};

export const reload = (data: IReload) => {
  return {
    type: ClientPanelActions.RELOAD,
    payload: data,
  };
};

export const refetchClientPanelData = (refetch: boolean) => {
  return {
    type: ClientPanelActions.REFETCH,
    payload: refetch
  }
} 

export const setSuccessMessage = (data: ISuccessMessage) => {
  return {
    type: ClientPanelActions.SET_SUCCESS_MESSAGE,
    payload: data,
  };
};

export const setBasicInfo = (data: any) => {
  return{
    type: ClientPanelActions.SET_BASIC_INFO,
    payload: data
  }
}

export const toggleClientsPanel = (isOpen: boolean) => {
  return {
    type: ClientPanelActions.TOGGLE_CLIENTS_PANEL,
    payload: isOpen,
  }
}