import * as React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { Stack, IStackStyles } from "@fluentui/react";

const spinnerS: IStackStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //marginTop: 20,
    height: '80vh',
    width: "100%",
  },
};

interface ActivityIndicatorProps {
  text?: string | undefined
}

const ActivityIndicator: React.FC<ActivityIndicatorProps> = ({ text }) => {
  return (
    <Stack styles={spinnerS}>
      <Spinner size={SpinnerSize.large} />
      {text != undefined ? <p style={{ paddingTop: 20 }}>{text}</p> : <></>}
    </Stack>
  );
};

export default ActivityIndicator;