import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleOrdersReportPanel } from './../../config/redux/client/clientActions';
import { FormikValues } from 'formik';
import { IResetForm } from '../../features/common/models/resetForm.type';
import { getRaportPDF } from '../../connectors/orders/connectors';
import { setErrorAlert, setListMessage } from '../../config/redux/alerts/alertsActions';
import { useDownloadFile } from '../../features/common/hooks/useDownloadFile';

export const useOrdersReportForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const preDownloading = () => {
    setIsLoading(true);
  };
  const postDownloading = () => {
    setIsLoading(false);
    dispatch(toggleOrdersReportPanel({ isOpen: false }));
    dispatch(setListMessage("Operacja przebiegła pomyślnie"));
  };

  const onErrorDownloadFile = () => {
    setIsLoading(false);
    dispatch(setErrorAlert("Wystąpił błąd"));
  };

  const getFileName = () => {
    return "Zamówienia.xlsx";
  };

  const { ref, url, download, name } = useDownloadFile({
    apiDefinition: getRaportPDF,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });


  const handleSubmit = async (values: FormikValues,
                              { resetForm }: IResetForm) => {
    const data = {
      createdFrom: values.createdFrom,
      createdTo: values.createdTo,
      currentStatus: values.currentStatus,
      orderBy: values.orderBy,
      desc: (values.desc === 'true'),
    }
    download(data)
  }

  const cancelForm = () => {
    dispatch(toggleOrdersReportPanel({ isOpen: false }))
  }

  return {
    handleSubmit,
    cancelForm,
    isLoading,
  }
}