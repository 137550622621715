import jwtDecode from "jwt-decode";
import authentication from "react-azure-b2c";

type IDecoded = {
  role: string;
  exp: number
};

export enum Roles {
  ADMIN = "Manager",
  CLIENT = "CLIENT",
  CLIENT_ADMIN = "CLIENT_ADMIN",
  DRIVER = "DRIVER",
  MANAGER = "Manager"
}

export const authorize = () => {
  const token = authentication.getAccessToken().accessToken;
  const isActivated = sessionStorage.getItem("isActivated") === "true";
  let role: string | null = "";
  let expired: boolean = false
  if(token) {
    const decoded: IDecoded = jwtDecode(token);
    if (sessionStorage.getItem('role') !== "") {
      role = sessionStorage.getItem('role');
    }      
    const dateNow = new Date()
    if(decoded.exp * 1000 < dateNow.getTime()){
      expired = true
      authentication.signOut();
    }
  }

  return {
    token,
    role,
    expired,
    isActivated,
  };
};
