import {mergeStyles} from "@fluentui/react";
import React from "react";
import SelfEditPanel from "./SelfEditPanel";
import ClientNavPanel from "./ClientNavPanel";
import Header from "./Header";
import ClientNav from "./ClientNav";

const wrapper = mergeStyles({
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
});

export interface ClientLayoutProps {
}

const ClientLayout: React.FC<ClientLayoutProps> = () => {
    return (
        <div className={wrapper}>
            <Header/>
            <ClientNavPanel/>
                <ClientNav/>
            <SelfEditPanel/>
        </div>
    );
};

export default ClientLayout;