import { canceledOrdersColumns } from './../components/columns/canceled-orders-columns';

import { NavigationPath } from './../../../config/routing/NavigationPath';
import { acceptedOrdersColumns } from '../components/columns/accepted-orders-columns';
import { inProgressOrdersColumns } from '../components/columns/inProgress-orders-columns';
import {allOrdersColumns} from "../components/columns/all-orders-columns";

export const generateColumns = (pathname: string) => {
    if(pathname === NavigationPath.REPORTS_APPROVED){
      return acceptedOrdersColumns
    }if(pathname === NavigationPath.REPORTS_ALL){
      return allOrdersColumns
    }if(pathname === NavigationPath.REPORTS_WAITING){
      return inProgressOrdersColumns
    }
    if(pathname === NavigationPath.REPORTS_REJECTED){
      return canceledOrdersColumns
    }else{
      return allOrdersColumns
    }
  };