import React, { useEffect } from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  ConstrainMode,
} from "@fluentui/react//lib/DetailsList";

import { listS } from "../../../common/styles/list.styles";
import { reasonsColumns } from "./columns";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useReasonsList } from "../../hooks/useReasonsList";
import {
  setSelectedReason,
  toggleReasonsPanel,
} from "../../../../config/redux/cancellationReasons/cancellationReasonsActions";

const ReasonsList: React.FC = () => {
  const dispatch = useDispatch();
  const {selectedReason} = useSelector((state:RootStateOrAny) => state.cancellationReasons)
  const onInvoke = () => {
    dispatch(toggleReasonsPanel(true));
  };

  const onSelect = (data: any) => {
    dispatch(setSelectedReason(data));
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedReason(""));
    };
  }, []);

  const { items, groups, columns, selection, handleInvoke } = useReasonsList(
    onSelect,
    onInvoke
  );

  useEffect(() => {
    if(!selectedReason){
      selection.selectToKey("", true);
    }
  },[selectedReason])

  return (
    <DetailsList
      items={items}
      selectionMode={SelectionMode.single}
      selection={selection}
      groupProps={{
        headerProps: {
          onGroupHeaderClick: () => {},
        },
      }}
      layoutMode={DetailsListLayoutMode.justified}
      onItemInvoked={handleInvoke}
      groups={groups}
      columns={columns}
      styles={listS}
      constrainMode={ConstrainMode.unconstrained}
      onColumnHeaderClick={() => {}}
    />
  );
};

export default ReasonsList;
