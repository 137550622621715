import {OrderTypes} from "../../orders/models/OrderTypes.enum";

export const generateItems = (data: any) => {
    const items: any[] = [];

    data.items.forEach((client: any) => {
        items.push({
            id: client.id,
            key: client.id,
            fullName: client.fullName,
            emailAddress: client.emailAddress,
            isActive: client.isActive,
            nip: client.nip,
            company: client.company,
            data: client,
        });
    });
    return [...items];
};

const generateReceptionPlacesOrderTypes = (data: any[]) => {
    var stringData: any[] = [];
    data.forEach((type: any) => {
        stringData.push(getTextFromOrderType(type));
    });
    return stringData.join(', ');
}

export const getTextFromOrderType = (orderType: number) => {
    switch (orderType) {
        case 1:
            return "Pojazd";
        case 2:
            return "Tonaż";
        case 3:
            return "Kontener";
    }
};