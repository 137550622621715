import React from "react";
import { Formik } from "formik";

import FormDropDown from "../../../common/layout/form/FormDropDown";
import NewFormField from "../../../common/layout/form/NewFormField";
import { postalCodes } from "../../data/postalCodes";
import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import { validationSchemaPrice } from "../../actions/validationSchema";
import { dropDownS } from "../../styles/dropDown.styles";

export interface PriceFormProps {
  handleSubmit: any;
  orderTypesItems: Array<any>;
  initValues: any;
  cancelForm: any;
}

const PriceForm: React.FC<PriceFormProps> = ({
  handleSubmit,
  orderTypesItems,
  initValues,
  cancelForm,
}) => {
  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchemaPrice}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div className={dropDownS}>
            <FormDropDown
              headerText="Typ"
              name="orderTypeItemId"
              options={orderTypesItems}
            />
          </div>
          <div>
          {postalCodes.map((code: string) => (
            <NewFormField
              key={code}
              name={code}
              label={`Cena dla kodów ${code}`}
              required={false}
            />
          ))}
          </div>

          <NewFormBtns
            submitBtnText={!Object.keys(initValues).length ? "Dodaj" : "Edytuj"}
            cancelForm={cancelForm}
            positionAbsolute={true}
          />
        </form>
      )}
    </Formik>
  );
};

export default PriceForm;
