import { ReportsActions } from "./reportsAction.enum";
import { IPrepare, IReload, IReport, IReportsPanel } from "./reportsAction.types";

export const setReport = (data: IReport) => {
    return {
        type: ReportsActions.SET_REPORT,
        payload: data
    }
}

export const setSelectedReports = (data: Array<IReport>) => {
    return {
        type: ReportsActions.SET_SELECTED_REPORTS,
        payload: data
    }
}

export const setReportDetails = (data: any) => {
    return {
        type: ReportsActions.SET_REPORT_DETAILS,
        payload:data
    }
}
export const toggleReportsPanel = (data: IReportsPanel) => {
    return {
        type: ReportsActions.TOGGLE_REPORTS_PANEL,
        payload: data
    }
}

export const prepareNewReport = (data: IPrepare) => {
    return {
        type: ReportsActions.PREPARE_REPORT,
        payload: data
    }
}

export const reloadReports = (data: IReload) => {
    return {
        type: ReportsActions.RELOAD_REPORTS,
        payload: data
    }
}

export const clearReports = () => {
    return {
        type: ReportsActions.CLEAR
    }
}