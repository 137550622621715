import {
  getOrderTypeItems,
  getOrderTypes,
} from "./../../../connectors/orderTypes/connectors/index";
import { useEffect, useState } from "react";
import { getUser } from "../../../connectors/clients/connectors/index";
import { getPickUpPoints } from "../../../connectors/orders/connectors/index";

export const useCompanyData = (companyData: any, department: any, receptionPlace: any) => {
  const [departments, setDepartments] = useState([]);
  const [receptionPlaces, setReceptionPlaces] = useState<any[]>([]);
  const [orderTypeItems, setOrderTypeItems] = useState([]);
  const [orderType, setOrderType] = useState({name: "", id: ""});

  useEffect(() => {
    if (companyData.key) {
      setCompany();
    }
  }, [companyData]);

  useEffect(() => {
    if (department.key) {
      getReceptionPlaces();
    } else {
      setReceptionPlaces([]);
    }
  }, [department]);

  useEffect(() => {
    if(receptionPlace){
      setOrderTypes(receptionPlace);
    }
  }, [receptionPlace])

  const setOrderTypes = async (receptionPlaceKey: number) => {
    if(receptionPlaceKey !== undefined && receptionPlaces !== undefined){

      const resid = receptionPlaces.find((item:any) => 
        item.key === receptionPlaceKey
      )

      const res = await getOrderTypeItems(resid.orderTypeId);      
      const data = await getOrderTypes();
  
      const arr = res.data.result.map((item: any) => {
        return { key: item.id, text: item.name, data: item.value, orderTypeId: item.orderTypeId };
      });
      
      const result = data.data.data.find(
        (item: any) => item.id === receptionPlaceKey
      );
  
      setOrderTypeItems(arr);
      if(result !== undefined){
        setOrderType(result);
      }
      }
  };

  const setCompany = async () => {
    const res = await getUser(companyData.key);
    const company = res.data.result;

    setDepartments(
      company.departments.map((department: any) => {
        return {
          key: department.id,
          text: `${department.city} ${department.street}`,
        };
      })
    );
  };

  const getReceptionPlaces = async () => {
    const res = await getPickUpPoints(department.key);
    const options = res.data.result.map((option: any) => {
      let msg = !option.isActive ? '(Nieaktywny)' : ''
      return {
        key: option.id,
        text: `${option.city} ${option.street} ${option.postalCode} ${msg}`,
        orderTypeId: option.orderTypeId,
        disabled: !option.isActive,
      };
    });

    setReceptionPlaces(options);
  };

  return {
    departments,
    receptionPlaces,
    orderTypeItems,
    orderType,
  };
};
