import { IStackStyles, mergeStyles } from "@fluentui/react/";

export const containerS: IStackStyles = {
    root: {
      padding: "40px 10px 20px 10px",
      height: "100%",
    },
  };

export const radioContainer = mergeStyles({
    marginTop: "35px",
    marginBottom: "35px",
})  