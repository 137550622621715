import { IOrderPanelAction } from "./orderPanelActions.types";
import { OrderPanelActions } from "./orderPanel.enum";
import { OrderTabsNames } from "../../../../features/orders/models/OrderTabs.enum";

const initState = {
  orderDetails: "",
  orderPanelOpen: false,
  yearlyReportPanelOpen: false,
  SMSPanelOpen: false,
  syncOrderPanelOpen: false,
  activeTab: OrderTabsNames.DETAILS,
  orderHistory: "",
  orderComments: "",
  newComment: false,
  editing: false,
  addNewOrder: false,
};

export const orderPanelReducer = (
  state = initState,
  action: IOrderPanelAction
) => {
  const { type, payload } = action;
  switch (type) {
    case OrderPanelActions.SET_ORDER_DETAILS:
      return { ...state, orderDetails: payload };
    case OrderPanelActions.TOGGLE_ORDER_PANEL:
      return { ...state, orderPanelOpen: payload };
    case OrderPanelActions.TOGGLE_YEARLY_REPORT_PANEL:
      return { ...state, yearlyReportPanelOpen: payload };
    case OrderPanelActions.TOGGLE_SMS_PANEL:
      return { ...state, orderPanelOpen: payload };
    case OrderPanelActions.TOGGLE_SYNC_ORDER_PANEL:
      return { ...state, syncOrderPanelOpen: payload };
    case OrderPanelActions.SET_ACTIVE_TAB:
      return { ...state, activeTab: payload };
    case OrderPanelActions.SET_ORDER_COMMENTS:
      return { ...state, orderComments: payload };
    case OrderPanelActions.SET_ORDER_HISTORY:
      return { ...state, orderHistory: payload };
    case OrderPanelActions.ADD_NEW_COMMENT:
      return { ...state, newComment: payload };
    case OrderPanelActions.SET_ORDER_EDITING:
      return { ...state, editing: payload };
    case OrderPanelActions.ADD_NEW_ORDER:
      return { ...state, addNewOrder: payload };
    default:
      return state;
  }
};
