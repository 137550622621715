import  React from "react";
import { TextField, ITextFieldStyles } from "@fluentui/react/";
import { FontSizes } from "@fluentui/react/";
import { FontWeights } from "@fluentui/react/";
import { useField } from "formik";
const textfieldS: Partial<ITextFieldStyles> = {
  root: {
    marginBottom: "16px",
    width: "250px",
  },

  subComponentStyles: {
    label: {
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.semibold,
    },
  },
};

export interface IPassFieldProps {
  name: any,
  label: string,
  disabled?: boolean
  value1: string
  setPrice?: any
}

const PriceInput: React.FC<IPassFieldProps> = ({
  name,
  label,
  disabled,
  value1,
  setPrice
}) => { 

  const [field, { value, error }, { setValue }] = useField(name);
  
  const onchange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) =>{
    setValue(newValue);
    setPrice(newValue);
  }

  return (
    <TextField
      type={"number"}
      label={label}
      disabled={disabled}
      styles={textfieldS}
      onChange={onchange}
      value={value1}
      min={0}   
    />
  );
};

export default PriceInput;