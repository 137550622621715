import * as React from "react";
import {
  CommandBar,
} from "@fluentui/react/";
import {useYearlyReportCommandBar} from "../../hooks/settings/useYearlyReportCommandBar"

const commandBarS = {
  root: {
    width: 500,
  }
}
 
const YearlyReportCommandBar: React.FC = () => {
    const {items} = useYearlyReportCommandBar()
  
    return <CommandBar items={items} styles={commandBarS} />;
}
 
export default YearlyReportCommandBar;