import api from "../../../config/axios/configAxios";
import { IOperator, IEditOperator } from "../models/index";

export const getOperators = async () => api.get(`/api/Admin/RecycleOperators`);

export const editOperator = async (data: IEditOperator) =>
  api.put(`/api/Admin/RecycleOperators/${data.operatorId}`, data.operator);

export const addOperator = async (operator: IOperator) =>
  api.post(`/api/Admin/RecycleOperators`, operator);

export const deleteOperatorById = async (operatorId: string) =>
  api.delete(`/api/Admin/RecycleOperators/${operatorId}`);
