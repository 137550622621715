import { Icon, TooltipHost } from "@fluentui/react/";
import React from "react";

export const allOrdersColumns = [
  {
    name: "Rok",
    fieldName: "year",
    key: "year",
    minWidth: 50,
    maxWidth: 80,
  },
  {
    name: "Nazwa firmy",
    fieldName: "company.companyInfo.name",
    key: "company.companyInfo.name",
    minWidth: 50,
    maxWidth: 380,
    onRender: (item: any) => {
      return <p>{item.company ? item.company.companyInfo.name : "Brak"} </p>;
    },
  },
  {
    name: "Adres firmy",
    fieldName: "company.companyInfo.companyAddress.normalized",
    key: "company.companyInfo.companyAddress.normalized",
    minWidth: 50,
    maxWidth: 380,
    onRender: (item: any) => {
      return item.company ? (
        <p>
          {" "}
          {item.company.companyInfo.companyAddress.city},
          {item.company.companyInfo.companyAddress.street},
          {item.company.companyInfo.companyAddress.postalCode}
        </p>
      ) : (
        "Brak"
      );
    },
  },
  {
    name: "NIP",
    fieldName: "creatoruser.nip",
    key: "creatorNip",
    minWidth: 100,
    maxWidth: 200,
    onRender: (item: any) => {
      return item.creatorNip ? <p>{item.creatorNip} </p> : "Brak";
    },
  },
  {
    name: "BDO",
    fieldName: "creatoruser.bdo",
    key: "bdo",
    minWidth: 100,
    maxWidth: 200,
    onRender: (item: any) => {
      return item.bdo ? <p>{item.bdo} </p> : "Brak";
    },
  },
  {
    name: "Autor",
    fieldName: "creatorUser",
    key: "creatorUser",
    minWidth: 100,
    maxWidth: 200,
    onRender: (item: any) => {
      return item.creatorUser ? item.creatorUser.userName : "Brak";
    },
  },
  {
    name: "Status Raportu",
    fieldName: "status",
    minWidth: 25,
    maxWidth: 110,
    onRender: (item: any) => {
      let message: string = "";
      let iconName: string = "";
      let className: string = "";
      if (item.status == "1") {
        message = "Oczekuję na akceptację";
        iconName = "HourGlass";
        className = "pending";
      } else if (item.status == "2") {
        message = "Zatwierdzono";
        iconName = "CheckMark";
        className = "approved";
      } else if (item.status == "3") {
        message = "Odrzucone";
        iconName = "Cancel";
        className = "decline";
      } else {
        message = "Możliwe do edycji";
        iconName = "EditNote";
        className = "editing";
      }
      return (
        <TooltipHost content={message}>
          <Icon iconName={iconName} className={`tableIcon ${className}`} />
        </TooltipHost>
      );
    },
  },
  {
    name: "Data Utworzenia",
    fieldName: "creationTime",
    key: "creationTime",
    minWidth: 60,
    maxWidth: 120,
    onRender: (item: any) => {
      return new Date(Date.parse(item.creationTime))
        .toLocaleDateString("pl-PL", {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
        .toUpperCase();
    },
  },
];
