import { OrderTypesActions } from "./orderTypesActions.enum";
import {OrderTypesAction} from "./orderTypesAction.types"

const initState = {
  selectedOrderTypes: "",
  activeOrderType: "",
  activeOrderTypeItem: "",
  editing: false,
  change: false,
  add: false,
  orderTypes: {},
};

export const orderTypesReducer = (
  state = initState,
  action: OrderTypesAction
) => {
  const { type, payload } = action;

  switch (type) {
    case OrderTypesActions.SET_SELECTED_ORDER_TYPES:
      return { ...state, selectedOrderTypes: payload };
    case OrderTypesActions.SET_ORDER_TYPE:
      return { ...state, activeOrderType: payload.activeOrderType };
    case OrderTypesActions.SET_ACTIVE_ORDERTYPE_ITEM:
      return { ...state, activeOrderTypeItem: payload };
    case OrderTypesActions.SET_EDITING:
      return { ...state, editing: payload.editing };
    case OrderTypesActions.RELOAD:
      return { ...state, change: payload.change };
    case OrderTypesActions.CLEAR_ORDER_TYPES_DATA:
      return {
        ...state,
        activeOrderType: "",
        editing: false,
        add: false,
        activeOrderTypeItem: "",
      };
    case OrderTypesActions.SET_ORDER_TYPES:
      return { ...state, orderTypes: payload };
    case OrderTypesActions.ADD:
      return { ...state, add: payload.add };
    default:
      return state;
  }
};
