import * as React from 'react';
import {getTheme} from "@fluentui/react/";
// @ts-ignore
import {IRenderFunction} from '@fluentui/react/lib/Utilities'
import {Stack, IStackStyles} from '@fluentui/react/lib/Stack'
import {IPanelProps} from '@fluentui/react/lib/Panel';
import ClientOrderPanelCommandBar from "./ClientOrderPanelCommandBar";

const theme = getTheme()

const navS: IStackStyles = {
  root: {
    width: "100%",
    borderBottom: `2px solid ${theme.palette.neutralLighter}`,
  },
};

export const RenderNavigation: IRenderFunction<IPanelProps> = ((props: any, defaultRender: any) => (
        <Stack horizontal styles={navS} horizontalAlign="space-between">
          <ClientOrderPanelCommandBar/>
          {defaultRender!(props)}
        </Stack>
    )
);