import React from "react";
import { Formik, Form } from "formik";
import { Stack } from "@fluentui/react/";
import { useDriverForm } from "../hooks/useDriverForm";
import { validationSchemaDrivers } from "../actions/validationSchemas";
import NewFormField from "../../common/layout/form/NewFormField";
import NewFormBtns from "../../common/layout/form/NewFormBtns";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import MessageContainer from "../../auth/components/register/forms/MessageContainer";
import {Dropdown} from "@fluentui/react//lib/Dropdown";
import NewFormCheckbox from "../../common/layout/form/NewFormCheckbox";

const DriverForm: React.FC = () => {
  const {
    handleSubmit,
    initValues,
    cancelForm,
    selectedDrivers,
    loading
  } = useDriverForm();

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchemaDrivers}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack style={{marginTop: '30px'}}>
            <NewFormField name={'year'} label={'Rok'} required={true} />
            <NewFormCheckbox name={'isActive'} label={'Aktywny'} />
            <NewFormBtns
              cancelForm={cancelForm}
              submitBtnText={selectedDrivers[0] ? "Zapisz" : "Dodaj"}
              disabled={loading}
              positionAbsolute={true}
            />
          </Stack>
          {loading ? <ActivityIndicator/> : <MessageContainer/>}
        </Form>
      )}
    </Formik>
  );
};

export default DriverForm;
