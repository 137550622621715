import * as React from 'react';

import SideNav from "./SideNav"
import {clientsPaths} from "../models/PivotNavPaths"
 
const ClientsNav: React.SFC = () => {
    return (  
        <SideNav items={clientsPaths}/>
    );
}
 
export default ClientsNav;