import { dateFormat } from "../../../utils/utils";
import { DocumentStatusEnum } from "../models/DocumentStatusEnums";

export const generateItems = (data: any) => {
    const items: any[] = [];

    data.forEach((data: any) => {
        items.push({
            id: data.id,
            key: data.key,
            description: data.description,
            documentDate: dateFormat(data.documentDate, "DD.MM.YYYY"),
            documentTypeName: data.documentTypeName,
            companyName: data.companyName,
            receptionPlaceName: data.receptionPlaceName,
            containersNumber: data.containersNumber,
            documentStatusStr: getDocumentStatusText(data.documentStatus),
            documentStatus: data.documentStatus,
            internalStoreName: data.internalStoreName,
            fileExists: data.fileExists,
            blobFileName: data.blobFileName
        });
    });

    items.sort((a, b) => {
        return new Date(b.documentDate).getTime() - new Date(a.documentDate).getTime();
    });

    return [...items];
};

const getDocumentStatusText = (status: number): string => {
    let text: string = "";

    switch (status) {
        case DocumentStatusEnum.BUFFOR:
            text = "W buforze";
            break;
        case DocumentStatusEnum.APPROVED:
            text = "Zatwierdzony";
            break;
        case DocumentStatusEnum.CANCELED:
            text = "Anulowany";
            break;
        case DocumentStatusEnum.CORRECTION:
            text = "Korekta";
            break;
    }

    return text;
};