import { ITextStyles } from "@fluentui/react//lib/Text";
import { FontSizes } from "@fluentui/react/";
import { FontWeights, mergeStyles } from "@fluentui/react/";

export const titleS: ITextStyles = {
    root: {
        fontSize: FontSizes.size16,
        marginBottom: 10,
        marginTop: 10,
        fontWeight: FontWeights.semibold
    }
}

export const btnS = mergeStyles({
    marginTop: 15
})