import { setListMessage, clearListMessages } from './../../../../config/redux/alerts/alertsActions';
import { setActivePriceType, togglePriceEdit, setSelectedPrice } from './../../../../config/redux/pricing/pricingActions';
import { useState, useEffect } from "react";
import { Quantity } from "./../../../common/models/quantity.model";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { togglePricesPanel } from "../../../../config/redux/pricing/pricingActions";

import { generateItems } from "../../actions/generateCommands/generatePricesCommands";
import { getOrderTypes } from "../../../../connectors/orderTypes/connectors";
import { useMutation, useQueryCache } from 'react-query';
import { deletePriceById } from '../../../../connectors/pricing/connectors';

export const usePricingCommandBar = () => {
  const [orderTypes, setOrderTypes] = useState([]);
  useEffect(() => {
    setOrderTypesData();
  }, []);

  const setOrderTypesData = async () => {
    const res = await getOrderTypes();
    setOrderTypes(res.data.result);
  };

  const cache = useQueryCache();
  const [mutate] = useMutation(deletePriceById, {
    onSuccess: () => {
      cache.invalidateQueries("prices");
      dispatch(setListMessage("operacja przebiegła pomyślnie"))
      dispatch(setSelectedPrice(""))
    },
    onError: (err) => {
      throw new Error();
    },
  });


  const dispatch = useDispatch();
  const { selectedPrice } = useSelector(
    (state: RootStateOrAny) => state.pricing
  );

  const editPrice = () => {
    dispatch(togglePricesPanel(true));
    dispatch(togglePriceEdit(true));
  };

  const addPrice = (e: any) => {
    dispatch(setActivePriceType({id: e.currentTarget.id, name: e.currentTarget.name}))
    dispatch(togglePricesPanel(true));
  }

  const deletePrice = async () => {
    dispatch(clearListMessages())
    try{
      await mutate(selectedPrice.id)
    }catch(err){

    }
  };

  const actions = {
    deletePrice,
    editPrice,
    addPrice
  };

  const quantity = selectedPrice ? Quantity.ONE : Quantity.NONE;
  const items = generateItems(quantity, actions, orderTypes);

  return {
    items,
  };
};
