import { FontSizes } from "@fluentui/react/";
import { FontWeights } from "@fluentui/react/";
import {
    getTheme,
    ITextStyles,
  } from "@fluentui/react/";


const theme = getTheme();

export const h1: ITextStyles = {
    root: {
        fontSize: FontSizes.size32,
        fontWeight: FontWeights.semibold,
    }
}

export const p: ITextStyles = {
    root: {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.semibold,
    }
}

export const hLight: ITextStyles = {
  root: {
    fontSize: FontSizes.size32,
    fontWeight: FontWeights.semibold,
    color: theme.palette.white,
  },
};

export const hDark: ITextStyles = {
  root: {
    fontSize: FontSizes.size18,
    color: theme.palette.black,
    fontWeight: 600,
  },
};

export const pLight: ITextStyles = {
  root: {
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
    color: theme.palette.white,
    margin: "4px 0",
  },
};

export const pDark: ITextStyles = {
  root: {
    fontSize: FontSizes.size14,
    color: theme.palette.black,
    margin: "4px 0",
  },
};